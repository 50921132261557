import recImg from '../../Assets/images/recruiter.png';
import revImg from '../../Assets/images/reviewer.png';
import ChooseClientImage from 'Assets/images/ChooseClient.svg';
import ChooseConsultancyImage from 'Assets/images/ChooseConsultancy.svg';
import Button from 'Components/CommonComponents/Button/Button';
import { useEffect, useState } from 'react';
import { isEmpty, isEqual, uniqBy } from 'lodash';
import DialogLayout from 'Components/CommonComponents/DialogLayout';
import AddRecruiter from './AddRecruiter';
import AddReviewer from './AddReviewer';
import GridViewIcon from '@mui/icons-material/GridView';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaRegTrashAlt } from 'react-icons/fa';
import 'Styles/commonStyles.scss';

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Menu, Typography, message, Modal } from 'antd';
import { useToggle } from 'react-use';
import { useParams } from 'react-router-dom';
import jobService from 'Services/JobService';
import EditJobStore from 'Store/EditJobStore';
import Progress from 'Components/CommonComponents/Progress';
import { JOB_ACTIVITY_LOGGER_TYPES } from 'Utilities/constants';
import createActivityLoggerId from 'Utilities/Jobs/createActivityLoggerId';
import ConsultancyFormModal from './ConsultancyFormModal';
import ClientFormModal from './ClientFormModal';
import {
  useCreateActivityLoggerId,
  useGetHiringTeamDetails,
} from 'ServiceHooks/jobHooks';
import EditJobHiringStore from 'Store/EditJobHiringStore';
import { mandatoryFieldsMessage } from 'Utilities/utils';

const detectUpdatedFields = (initial, updated) => {
  const initialClients = initial?.clients?.map((item) => item.id);
  const initialConsultants = initial?.consultants?.map((item) => item.id);

  const fields = [];
  const { recruiter, reviewer } = updated;
  if (!isEqual(initial?.recruiter, recruiter)) {
    fields.push('recruiter');
  }
  if (!isEqual(initial?.reviewer, reviewer)) {
    fields.push('reviewer');
  }
  if (!isEqual(initialClients, updated?.clients)) {
    fields.push('clients');
  }
  if (!isEqual(initialConsultants, updated?.consultants)) {
    fields.push('consultants');
  }
  return fields;
};

const HiringTeamNew = (props) => {
  const [isAddRecruiter, setAddRecruiter] = useState(false);
  const [isAddReviewer, setAddReviewer] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [consultancyList, setConsultancyList] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [cardId, setCardId] = useState([]);
  const oEditJobResponse = EditJobStore((state) => state.EditJobtResponse);
  const [isNoteEdit, setNoteEdit] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { setEditJobHiringResponse } = EditJobHiringStore();

  const [isConsultancyModalOpen, toggleConsultancyModal] =
    useToggle(false);
  const [isClientModalOpen, toggleClientModal] = useToggle(false);

  const [isProcessing, setProcessing] = useState();

  const { mutate: sendActivityLog } = useCreateActivityLoggerId({});

  const param = useParams();

  const { data: initialHiringTeamDetails } = useGetHiringTeamDetails({
    params: {
      jobId: param?.jobId,
    },
    enabled: !!param?.jobId,
  });

  useEffect(() => {
    editJobHiringStage();
  }, []);

  const editJobHiringStage = async () => {
    setProcessing(true);
    await jobService
      .getHiringTeam(param?.jobId)
      .then((oResponse) => {
        console.log('setEditJobHiringTeamData', oResponse.data.data);

        const clients = oResponse?.data?.data?.clients;
        const consultants = oResponse?.data?.data.consultants;

        const existingClients = clients.map((item) => ({
          key: item.id,
          value: item.id,
          label: item.client,
        }));
        const existingConsultants = consultants.map((item) => ({
          key: item.id,
          value: item.id,
          label: item.client,
        }));

        setSelectedRecruiter(oResponse.data.data.recruiter);
        setSelectedReviewer(oResponse.data.data.reviewer);
        setClientList(existingClients);
        setConsultancyList(existingConsultants);

        setEditNote(
          oResponse.data.data.recruiter,
          oResponse.data.data.reviewer,
        );
        props.process(false);
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        props.process(false);
        setProcessing(false);
      });
  };

  const setEditNote = (recruiter, reviewer) => {
    var newArr = [];
    recruiter.map((oData) => {
      if (oData.recruiter_note !== '') {
        return newArr.push(oData.id);
      }
    });
    reviewer.map((oData) => {
      if (oData.reviewer_note !== '') {
        return newArr.push(oData.id);
      }
    });

    setCardId(newArr);
  };

  const getRecuiterData = () => {
    const oRecruiter = [];
    selectedRecruiter.map((data) => {
      const oArrRecruiter = {
        job: oEditJobResponse && oEditJobResponse.job_id,
        hire_manage_user:
          data.hire_manage_user !== undefined
            ? data.hire_manage_user
            : data.id,
        recruiter_note:
          data.recruiter_note !== undefined ? data.recruiter_note : '',
      };
      oRecruiter.push(oArrRecruiter);
    });
    return oRecruiter;
  };

  const getReviewerData = () => {
    const oReviewer = [];
    selectedReviewer.map((data) => {
      const oArrReviewer = {
        job: oEditJobResponse && oEditJobResponse.job_id,
        hire_manage_user:
          data.hire_manage_user !== undefined
            ? data.hire_manage_user
            : data.id,
        reviewer_note:
          data.reviewer_note !== undefined ? data.reviewer_note : '',
      };
      oReviewer.push(oArrReviewer);
    });
    return oReviewer;
  };

  const saveHiringTeamData = async () => {
    setProcessing(true);
    const logger_id = createActivityLoggerId();
    const reviewerData = getReviewerData();
    const recruiterData = getRecuiterData();

    const clients = clientList.map((item) => item.value);
    const consultants = consultancyList.map((item) => item.value);

    const data = {
      clients,
      consultants,
      reviewer: reviewerData,
      recruiter: recruiterData,
      job: param?.jobId ?? oEditJobResponse?.job_id,
    };

    const updated = {
      clients,
      consultants,
      reviewer: selectedReviewer,
      recruiter: selectedRecruiter,
    };

    const updated_fields = detectUpdatedFields(
      initialHiringTeamDetails,
      updated,
    );

    try {
      if (!oEditJobResponse?.job_id) {
        throw new Error('Need Job Fields for this form.');
      }
      const response = await jobService.hiringTeam(data, { logger_id });
      setEditJobHiringResponse(response?.data?.data)
      const logForEditJob =
        !isEmpty(param?.jobId) && !isEmpty(updated_fields);
      const log = {
        logger_id,
        job: oEditJobResponse?.job_id,
        activity: isEmpty(param?.jobId)
          ? JOB_ACTIVITY_LOGGER_TYPES.JOB_HIRING_TEAM_ADDED
          : JOB_ACTIVITY_LOGGER_TYPES.JOB_HIRING_TEAM_UPDATED,
        ...(logForEditJob && { updated_fields }),
      };
      sendActivityLog(log);

      props.setAccordionActive(props.currentActive + 1);
      setProcessing(false);
    } catch (error) {
      console.log(
        'Hiring Team:',
        error?.response?.data?.error_info,
      );
      let content = mandatoryFieldsMessage(error?.response?.data?.error_info)
      
      messageApi.open({
        type: 'error',
        content: content,
      });
      setProcessing(false);
    }
  };

  const addRecruiter = () => {
    setAddRecruiter(true);
  };

  const addReviewer = () => {
    setAddReviewer(true);
  };

  const selectRecruiter = (oRecruiter) => {
    console.log('selectedRecruiter_oRecruiter', oRecruiter);
    // setSelectedRecruiter([...selectedRecruiter, oRecruiter]);
    setSelectedRecruiter((prevState) => {
      const newState = [...prevState];
      if (newState.length > 0) {
        oRecruiter.map((new_arr) => {
          var data = newState.find(function (ele) {
            if (ele.id === new_arr.id) {
              return true;
            } else {
              return false;
            }
          });
          if (data) {
            //alert("The Selected Recruiter is already added !");
            messageApi.open({
              type: 'error',
              content: 'The Selected Recruiter is already added !',
            });
          } else {
            newState.push(new_arr);
          }
          console.log(data);
        });
        console.log('newState_if', newState);
        return newState;
      } else {
        oRecruiter.map((new_arr) => {
          newState.push(new_arr);
        });
        console.log('newState_else', newState);
        return newState;
      }
    });

    console.log('updated_sate', selectedRecruiter);
  };

  const selectReviewer = (oReviewer) => {
    console.log('selectedReviewer_oReviewer', oReviewer);
    // setSelectedReviewer([...selectedReviewer, oReviewer]);
    setSelectedReviewer((prevState) => {
      const newState = [...prevState];
      if (newState.length > 0) {
        oReviewer.map((new_arr) => {
          var data = newState.find(function (ele) {
            if (ele.id === new_arr.id) {
              return true;
            } else {
              return false;
            }
          });
          if (data) {
            // alert("The Selected Reviewer is already added !");
            messageApi.open({
              type: 'error',
              content: 'The Selected Reviewer is already added !',
            });
          } else {
            newState.push(new_arr);
          }
          console.log(data);
        });
        console.log('newState_if', newState);
        return newState;
      } else {
        oReviewer.map((new_arr) => {
          newState.push(new_arr);
        });
        console.log('newState_else', newState);
        return newState;
      }
    });

    console.log('updated_sate', selectedReviewer);
  };

  const addNote = (id, e) => {
    console.log('click', id);
    setCardId([...cardId, id]);
  };

  const deleteList = (id, strCardname) => {
    console.log(id, strCardname, selectedRecruiter);
    if (strCardname === 'Recruiter') {
      var newRec = [];
      selectedRecruiter.map((oData) => {
        if (oData.id !== id) {
          return newRec.push(oData);
        }
      });
      setSelectedRecruiter(newRec);
      console.log(id, strCardname, newRec);
    } else if (strCardname === 'Reviewer') {
      var newRev = [];
      selectedReviewer.map((oData) => {
        if (oData.id !== id) {
          return newRev.push(oData);
        }
      });
      setSelectedReviewer(newRev);
      console.log(id, strCardname, newRev);
    }
  };

  const handelNoteChange = (e, id, strCardname) => {
    if (strCardname === 'Recruiter') {
      selectedRecruiter.map((oData) => {
        if (oData.id === id) {
          oData.recruiter_note = e.target.value;
        }
      });
    } else if (strCardname === 'Reviewer') {
      selectedReviewer.map((oData) => {
        if (oData.id === id) {
          oData.reviewer_note = e.target.value;
        }
      });
    }
    console.log('note', selectedRecruiter, selectedReviewer);
  };

  const deleteNote = (nId, strCardname) => {
    const filteredState = cardId.filter((item) => item !== nId);
    setCardId([...filteredState]);
    if (strCardname === 'Recruiter') {
      selectedRecruiter.map((oData) => {
        if (oData.id === nId) {
          oData.recruiter_note = '';
        }
      });
    } else if (strCardname === 'Reviewer') {
      selectedReviewer.map((oData) => {
        if (oData.id === nId) {
          oData.reviewer_note = '';
        }
      });
    }
  };

  const editNote = (nId) => {
    setNoteEdit([...isNoteEdit, nId]);
    document.getElementById(nId).focus();
    document.getElementById(nId).style.border = '1px solid';
    setTimeout(() => {
      document.getElementById(nId).style.border = '0';
    }, 2000);
  };

  const cancel = () => {
    props.setAccordionActive(props.currentActive - 1);
  };

  const buildNoteLayout = (nId, strCardname, data) => {
    var test = cardId.includes(nId);
    console.log(test, nId);
    if (test) {
      return (
        <ListItem
          color="#0A7F8F"
          className="rounded-sm border-2 border-dashed border-gray-300 bg-sky-100"
          alignItems="flex-start"
          sx={{
            p: 1,
            m: 0,
          }}
        >
          {' '}
          <ListItemText
            primary={
              <>
                <h6>
                  Note{' '}
                  <span style={{ float: 'right' }}>
                    {!param.jobId ? (
                      ''
                    ) : (
                      <EditIcon
                        className="note_icons"
                        onClick={() => editNote(nId, strCardname)}
                      />
                    )}

                    <DeleteIcon
                      className="note_icons"
                      onClick={() => deleteNote(nId, strCardname)}
                    />
                  </span>
                </h6>
              </>
            }
            secondary={
              <>
                <textarea
                  id={nId}
                  disabled={
                    !param.jobId
                      ? false
                      : isNoteEdit.includes(nId)
                        ? false
                        : data.recruiter_note === '' ||
                            data.reviewer_note === ''
                          ? false
                          : true
                  }
                  defaultValue={
                    strCardname === 'Recruiter'
                      ? data.recruiter_note && data.recruiter_note
                      : data.reviewer_note && data.reviewer_note
                  }
                  className="note_input"
                  onChange={(e) => handelNoteChange(e, nId, strCardname)}
                ></textarea>
              </>
            }
          />
        </ListItem>
      );
    }
  };

  const HiringTeamCard = (data, strCardname) => {
    return (
      <div className="rounded-sm p-3 bg-slate-100 mt-6" key={data.id}>
        <ListItem
          alignItems="flex-start"
          sx={{
            p: 0,
            m: 0,
            borderBottom: '1px solid lightgray',
          }}
        >
          <ListItemAvatar>
            <Avatar
              alt={data.first_name}
              src="/static/images/avatar/1.jpg"
            />
          </ListItemAvatar>
          <ListItemText
            primary={data.first_name}
            secondary={
              <>
                <h6>{data.email}</h6>
                <h6>{data.mobile_number}</h6>
                <h6>{data.designation}</h6>
              </>
            }
            sx={{
              fontSize: '12px',
            }}
          />
          <ListItemAvatar>
            <Menu
              mode="horizontal"
              style={{
                backgroundColor: 'transparent',
                borderBottom: 0,
              }}
            >
              <Menu.SubMenu
                key="SubMenu"
                title={
                  <GridViewIcon
                    style={{
                      // backgroundColor: "lightgray",
                      borderRadius: '4px',
                      cursor: 'pointer',
                      color: 'coral',
                    }}
                  />
                }
              >
                <Menu.Item onClick={() => addNote(data.id, strCardname)}>
                  create note
                </Menu.Item>
                <Menu.Item
                  onClick={() => deleteList(data.id, strCardname)}
                >
                  delete
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </ListItemAvatar>
        </ListItem>
        <ListItem
          alignItems="flex-start"
          sx={{
            p: 0,
            m: 0,
            pt: 1,
          }}
        >
          <ListItemText
            primary="Other Assigned Jobs"
            secondary={
              <>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                ></Typography>
                {data.assigned_jobs !== undefined ? (
                  data.assigned_jobs?.map((oData) => {
                    return oData + ',';
                  })
                ) : (
                  <h6 style={{ color: 'red' }}>No Job Assigned</h6>
                )}
              </>
            }
          />
        </ListItem>
        {buildNoteLayout(data.id, strCardname, data)}
      </div>
    );
  };

  const removeChosenConsultancy = (id) => {
    const filteredList = consultancyList.filter((item) => item.key !== id);
    setConsultancyList(filteredList);
  };

  const removeChosenClient = (id) => {
    const filteredList = clientList.filter((item) => item.key !== id);
    setClientList(filteredList);
  };

  return (
    <>
      {contextHolder}
      <div className="hiringTeam">
        {props.fields &&
          props.fields.map((odata, index) => {
            if (odata.show === true) {
              if (odata.field_heading_code === 'RECRUITER') {
                return odata.fields.map((oFileds) => {
                  if (
                    (oFileds.field_code === 'recruiter' &&
                      oFileds.is_mandatory &&
                      oFileds.show) ||
                    (oFileds.field_code === 'recruiter' &&
                      !oFileds.is_mandatory &&
                      oFileds.show &&
                      props.isViewMoreField === true)
                  ) {
                    return (
                      <div
                        key={`hiring-team-rec-form-${index}`}
                        className="hiringTeamLeftDiv w-full border border-[#f3f3f3] rounded-md p-4"
                      >
                        <h3>
                          Recruiter{' '}
                          {selectedRecruiter.length > 0 && (
                            <Button
                              type={'primary'}
                              style={{ float: 'right' }}
                              onClick={() => addRecruiter()}
                            >
                              Choose Recruiter
                            </Button>
                          )}
                        </h3>

                        {!selectedRecruiter.length > 0 && (
                          <div className="align-middle text-center justify-center pb-12 mt-5">
                            <img
                              alt=""
                              src={recImg}
                              className="m-auto object-contain h-52"
                            />
                            <h2 className="pb-2 font-serif font-gray">
                              Not any recruiter chosen yet!
                            </h2>
                            <Button
                              type={'primary'}
                              onClick={() => addRecruiter()}
                            >
                              Choose Recruiter
                            </Button>
                          </div>
                        )}

                        {selectedRecruiter &&
                          selectedRecruiter?.map((oData) => {
                            return HiringTeamCard(oData, 'Recruiter');
                          })}
                      </div>
                    );
                  }
                });
              } else if (odata.field_heading_code === 'REVIEWER') {
                return odata.fields.map((oFiled) => {
                  if (
                    (oFiled.field_code === 'reviewer' &&
                      oFiled.is_mandatory &&
                      oFiled.show) ||
                    (oFiled.field_code === 'reviewer' &&
                      !oFiled.is_mandatory &&
                      oFiled.show &&
                      props.isViewMoreField === true)
                  ) {
                    return (
                      <div
                        key={`hiring-team-rev-form-${index}`}
                        className="hiringTeamRightDiv w-full border border-[#f3f3f3] rounded-md p-4"
                      >
                        <h3>
                          Reviewer
                          {selectedReviewer.length > 0 && (
                            <Button
                              type={'primary'}
                              style={{ float: 'right' }}
                              onClick={() => addReviewer()}
                            >
                              Choose Reviewer
                            </Button>
                          )}
                        </h3>
                        {!selectedReviewer.length > 0 && (
                          <div className="align-middle text-center justify-center pb-12 mt-5">
                            <img
                              alt=""
                              src={revImg}
                              className="m-auto object-contain h-52"
                            />
                            <h2 className="pb-2 font-serif font-gray">
                              Not any reviewer chosen yet!
                            </h2>
                            <Button
                              type={'primary'}
                              onClick={() => addReviewer()}
                            >
                              Choose Reviewer
                            </Button>
                          </div>
                        )}

                        {selectedReviewer &&
                          selectedReviewer?.map((oData) => {
                            return HiringTeamCard(oData, 'Reviewer');
                          })}
                      </div>
                    );
                  }
                });
              } else if (odata.field_heading_code === 'CONSULTANCIES') {
                const config = odata.fields.find(
                  (item) => item.field_code === 'consultancies',
                );
                if (
                  (config.show && config.is_mandatory) ||
                  (config.show &&
                    !config.is_mandatory &&
                    props.isViewMoreField === true)
                ) {
                  return (
                    <div
                      key={`hiring-team-cons-form-${index}`}
                      className="hiringTeamLeftDiv w-full border border-[#f3f3f3] rounded-md p-4"
                    >
                      <h3 className="flex justify-between items-center">
                        Consultancy{' '}
                        {!isEmpty(consultancyList) && (
                          <Button
                            type={'primary'}
                            onClick={() => toggleConsultancyModal()}
                          >
                            Choose Consultancy
                          </Button>
                        )}
                      </h3>

                      {isEmpty(consultancyList) ? (
                        <div className="items-center text-center justify-center pb-12 mt-5">
                          <img
                            alt=""
                            src={ChooseConsultancyImage}
                            className="m-auto object-contain h-52"
                          />
                          <h2 className="pb-2 font-serif font-gray">
                            Not any Consultancy chosen yet!
                          </h2>
                          <Button
                            type={'primary'}
                            onClick={() => toggleConsultancyModal()}
                          >
                            Choose Consultancy
                          </Button>
                        </div>
                      ) : (
                        <div className="mt-12 mb-4 px-2 max-h-80 overflow-x-auto customScrollBar">
                          {consultancyList?.map((item, index) => {
                            return (
                              <div
                                key={`cons-list-${index}`}
                                className="mb-4 pb-4 flex justify-between items-center border-b border-gray-200"
                              >
                                <div className="text-black text-base font-medium">
                                  {item?.label}
                                </div>

                                <Button
                                  type={'text'}
                                  onClick={() =>
                                    removeChosenConsultancy(item?.key)
                                  }
                                >
                                  <FaRegTrashAlt className="text-zinc-500" />
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }
              } else if (odata.field_heading_code === 'CLIENTS') {
                const config = odata.fields.find(
                  (item) => item.field_code === 'clients',
                );
                if (
                  (config.show && config.is_mandatory) ||
                  (config.show &&
                    !config.is_mandatory &&
                    props.isViewMoreField === true)
                ) {
                  return (
                    <div
                      key={`hiring-team-clie-form-${index}`}
                      className="hiringTeamRightDiv w-full border border-[#f3f3f3] rounded-md p-4"
                    >
                      <h3 className="flex justify-between items-center">
                        Client
                        {!isEmpty(clientList) && (
                          <Button
                            type={'primary'}
                            onClick={() => toggleClientModal()}
                          >
                            Choose Client
                          </Button>
                        )}
                      </h3>

                      {isEmpty(clientList) ? (
                        <div className="items-center text-center justify-center pb-12 mt-5">
                          <img
                            alt=""
                            src={ChooseClientImage}
                            className="m-auto object-contain h-52"
                          />
                          <h2 className="pb-2 font-serif font-gray">
                            Not any client chosen yet!
                          </h2>
                          <Button
                            type={'primary'}
                            onClick={() => toggleClientModal()}
                          >
                            Choose Client
                          </Button>
                        </div>
                      ) : (
                        <div className="mt-12 mb-4 px-2 max-h-80 overflow-x-auto customScrollBar">
                          {clientList.map((item, index) => {
                            return (
                              <div
                                key={`clie-list-${index}`}
                                className="mb-4 pb-4 flex justify-between items-center border-b border-gray-200"
                              >
                                <div className="text-black text-base font-medium">
                                  {item.label}
                                </div>

                                <Button
                                  type={'text'}
                                  onClick={() =>
                                    removeChosenClient(item.key)
                                  }
                                >
                                  <FaRegTrashAlt className="text-zinc-500" />
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }
              }
            }
          })}

        {/* popup's */}

        {isAddRecruiter && (
          <DialogLayout
            isOpen={isAddRecruiter}
            isClose={() => setAddRecruiter(false)}
            strHeaderName={'Recruiter'}
            titleColor="black"
            titleLayoutColor="white"
          >
            <AddRecruiter
              strHeaderName={'Recruiter'}
              isClose={() => setAddRecruiter(false)}
              onSelectedValue={selectRecruiter}
              jobId={oEditJobResponse && oEditJobResponse.job_id}
            />
          </DialogLayout>
        )}

        {isAddReviewer && (
          <DialogLayout
            isOpen={isAddReviewer}
            isClose={() => setAddReviewer(false)}
            strHeaderName={'Reviewer'}
            titleColor="black"
            titleLayoutColor="white"
          >
            <AddReviewer
              onSelectedValue={selectReviewer}
              strHeaderName={'Reviewer'}
              isClose={() => setAddReviewer(false)}
              jobId={oEditJobResponse && oEditJobResponse.job_id}
            />
          </DialogLayout>
        )}
      </div>

      <div style={{ textAlign: 'end', marginTop: '4px', padding: '10px' }}>
        <Button
          className="cancel"
          style={{ marginRight: '5px' }}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        <Button
          className="save"
          type="default"
          onClick={() => saveHiringTeamData()}
        >
          {!param.jobId ? 'Save and Next' : 'Update and Next'}
        </Button>
      </div>

      {isProcessing && <Progress />}

      <Modal
        className="addConsultancyModal"
        width={700}
        open={isConsultancyModalOpen}
        title={'Consultancy'}
        onCancel={toggleConsultancyModal}
        footer={null}
        destroyOnClose
      >
        <ConsultancyFormModal
          close={toggleConsultancyModal}
          addConsultancies={(newList) => {
            const updatedList = consultancyList.concat(newList);
            const filteredList = uniqBy(updatedList, 'key');
            setConsultancyList(filteredList);
          }}
        />
      </Modal>
      <Modal
        className="addClientModal"
        width={700}
        open={isClientModalOpen}
        title={'Client'}
        onCancel={toggleClientModal}
        footer={null}
        destroyOnClose
      >
        <ClientFormModal
          close={toggleClientModal}
          addClients={(newList) => {
            const updatedList = clientList.concat(newList);
            const filteredList = uniqBy(updatedList, 'key');
            setClientList(filteredList);
          }}
        />
      </Modal>
    </>
  );
};

export default HiringTeamNew;
