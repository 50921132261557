import LoginDialogHeader from '../../Components/CommonComponents/LoginDialogHeader';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import authService from '../../Services/authService';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-hot-toast';
import Progress from '../../Components/CommonComponents/Progress';
import { Alert } from 'antd';
import routes from 'Routes';

const OTPForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isCountryLayout, setCountryLayout] = useState(false);
  const [phoneCode, setPhonecode] = useState();
  const [isprogress, setProgress] = useState(false);
  const [isFormError, setFormError] = useState(false);
  const [strFormType, setFormType] = useState();
  const [strMessage, setMessage] = useState();

  const onSubmit = async (data) => {
    data['email_or_mobile_number'] =
      phoneCode + data.email_or_mobile_number;
    data['company_domain'] = 'http://ndz.yvi.com/';
    setProgress(true);
    await authService
      .loginWithOtp(data)
      .then(
        (oResponse) => {
          localStorage.setItem('user_id', oResponse.data.data.user);
          navigate('/VerifyOTP');
          setProgress(false);
          toast.success(oResponse.data.data.message);
        },
        (error) => {
          const errorMessage =
            error.response.data.error_info.non_field_errors;
          Object.keys(errorMessage).forEach((field) => {
            const messages = errorMessage[field];
            console.log(messages);
            setMessage(messages);
          });
          console.log(errorMessage);
          setProgress(false);
          setFormError(true);
          setFormType('error');
        },
      )
      .catch((err) => {
        console.log(err);
        toast.error('Not Connected');
        setProgress(false);
      });
  };

  const handelInputChange = (e) => {
    if (e.target.value !== '') {
      var test = Number(e.target.value);
      var test1 = Number.isInteger(test);
      if (test1) {
        setCountryLayout(true);
        setPhonecode('+91');
      } else {
        setCountryLayout(false);
        setPhonecode('');
      }
    } else {
      setCountryLayout(false);
      setPhonecode('');
    }
  };

  const handelChangePhone = (e) => {
    var value = `+${e}`;
    setPhonecode(value);
  };

  return (
    <LoginDialogHeader>
      {isFormError && (
        <Alert
          message="Whoops !"
          type={strFormType}
          description={strMessage}
          style={{ width: '98%', margin: 'auto' }}
        />
      )}
      <div className="login">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex' }}>
            {isCountryLayout && (
              <span
                style={{
                  width: '10%',
                  paddingLeft: '5px',
                  paddingTop: '5px',
                }}
              >
                <PhoneInput
                  country="In"
                  value="91"
                  inputStyle={{
                    height: '45px',
                    width: '2%',
                    paddingLeft: '0px',
                  }}
                  onChange={(e) => handelChangePhone(e)}
                />
              </span>
            )}
            <input
              placeholder="Email Or Phone Number"
              {...register('email_or_mobile_number', {
                required: 'Please Enter Your Email Or Phone Number',
              })}
              //type={!isCountryLayout && "email"}
              defaultValue={phoneCode}
              onChange={handelInputChange}
            ></input>
          </div>
          {errors && errors.email_or_mobile_number && (
            <span className="required">
              {errors.email_or_mobile_number.message}
            </span>
          )}

          <button type="submit">Send OTP</button>
        </form>
        <button
          className="sm:whitespace-nowrap"
          onClick={() => navigate(routes.LOGIN)}
        >
          LOGIN WITH USERNAME AND PASSWORD
        </button>
      </div>
      {isprogress && <Progress />}
    </LoginDialogHeader>
  );
};
export default OTPForm;
