import create from 'zustand';

const evaluationParameter = create((set) => ({
    evaluationParameterResponse: "",
    setEvaluationParameter: (response) => {
    set(() => ({
        evaluationParameterResponse: response
    }));
  }
}));

export default evaluationParameter;