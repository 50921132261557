import { useEffect, useState } from 'react';
import { EditOutlined, EditTwoTone } from '@ant-design/icons';

import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import companyRegistration from '../../Services/companyRegistration';
import DataGridLayout, {
  useDataGridLayout,
} from 'Components/CommonComponents/DataGridLayout';
import CreateRegistration from './CreateRegistration';
import RegistartionDetails from './RegistartionDetails';
import { useAuthentication } from 'ServiceHooks/authHooks';
import { PERMISSION_TYPES } from 'Utilities/constants';

const tableColumn = [
  {
    fieldID: 'CompanyName',
    fieldLable: ' Company Name',
    fieldShow: false,
  },
  {
    fieldID: 'CreatedDate',
    fieldLable: 'Created Date',
    fieldShow: true,
  },
];
export default function RegistrationManagementComp() {
  const [listView, setListView] = useState('true');
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [compnyId, setCompnyId] = useState(false);
  // Pagination
  const [companyData, setCompanyData] = useState();
  const [companyListResponse, setCompanyListResponse] = useState();

  const { fullPermissionInfo } = useAuthentication();

  const isAddPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.ADD_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.ADD_COMPANY_REGISTRATION] === true;

  const isEditPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.EDIT_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.EDIT_COMPANY_REGISTRATION] ===
      true;

  //Unimplemented feature
  const isDeletePermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.DELETE_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.DELETE_COMPANY_REGISTRATION] ===
      true;

  const isViewPermissionAvailable =
    Object.hasOwn(
      fullPermissionInfo,
      PERMISSION_TYPES.VIEW_COMPANY_REGISTRATION,
    ) &&
    fullPermissionInfo[PERMISSION_TYPES.VIEW_COMPANY_REGISTRATION] ===
      true;

  const {
    apiRef: apiRef2,
    paginationModel,
    setPaginationModel,
    handlePaginationModalChange,
    handleRowSelectionModelChange,
    rowSelectionModel,
    calculatePaginationData,
  } = useDataGridLayout();

  const getData = async () => {
    await companyRegistration
      .getCompanydata(paginationModel.pageSize, paginationModel.offset)
      .then((response) => {
        setCompanyListResponse(response.data.data);
        setCompanyData(response.data);
      })
      .catch((error) => error);
  };

  const handleEdit = (id) => {
    setCompnyId(id);
    setIsEdit(true);
  };
  const handleAdd = () => {
    setIsAdd(true);
    setCompnyId('');
  };
  useEffect(() => {
    getData(paginationModel.pageSize, paginationModel.offset);
  }, [isAdd, isEdit, paginationModel]);
  const getRowdata = (arrRowData) => {
    var row = [];
    arrRowData?.map((oData) => {
      return row.push({
        id: oData.id,
        CompanyName: oData.name,
        CreatedDate: oData.created,
      });
    });
    return row;
  };
  const getCandidatesTableRowClassName = (params) => {
    if (params) {
      return params.indexRelativeToCurrentPage % 2 === 0
        ? 'Mui-even-row'
        : 'Mui-odd-row';
    }
  };
  const getColumn = () => {
    var setTableColumns = [];
    tableColumn?.map((oData) => {
      setTableColumns.push({
        field: oData.fieldID,
        // headerAlign: "left",
        textAlign: 'left',
        paddingLeft: '25px',
        marginLeft: '25px',

        renderHeader: (params) => <strong>{oData.fieldLable}</strong>,
        renderCell: (params) => {
          return (
            <div
              style={{
                fontSize: 14,
                fontFamily: 'sans-serif',
                fontWeight: 'normal',
                width: '100%',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {params.value}
            </div>
          );
        },
      });
    });
    return setTableColumns;
  };
  const tableList = (arrRowData) => {
    var arrColumn = getColumn();
    if (isEditPermissionAvailable) {
      arrColumn.push({
        field: 'action',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeader: (params) => (
          <strong className="flex items-center text-sm font-semibold text-black">
            Action
          </strong>
        ),
        renderCell: (params) => {
          return (
            <div className="flex justify-center items-center space-x-4 w-full text-center text-lg text-cyan-700 font-bold">
              <EditTwoTone
                title="Edit"
                className="cursor-pointer"
                onClick={() => handleEdit(params.row.id)}
              />
            </div>
          );
        },
      });
    }
    var arrRow = getRowdata(arrRowData?.data?.results);

    return (
      <div className="scheduleList w-full max-h-[calc(90vh-6rem)] overflow-y-auto scrollbar-none">
        <DataGridLayout
          column={arrColumn}
          row={arrRow}
          apiRef={apiRef2}
          showCustomPagination
          paginationModel={paginationModel}
          rowSelectionModel={rowSelectionModel}
          onPaginationModelChange={(model, details) =>
            handlePaginationModalChange(model, details)
          }
          onRowSelectionModelChange={(newRowSelectionModel) => {
            handleRowSelectionModelChange(
              newRowSelectionModel,
              arrRowData?.data,
              newRowSelectionModel,
            );
          }}
          getRowClassName={(params) =>
            getCandidatesTableRowClassName(params)
          }
          // loading={getSettingsDepartmentsData()}
          results={arrRowData?.data}
          // checkboxSelection
          useNewPagination
          // getRowClassName={getRowClassName}
          // defaultColumn={{
          //   DepartmentName: false,
          //   CreateDate: false,
          // }}
          // GridSearch={getGridSearchValue}
        />
      </div>
    );
  };
  const truncateStrHandler = (str) => {
    return str.length > 45 ? str.substring(0, 35) + '...' : str;
  };

  const registeredCompanyCardView = (data) => {
    return (
      <div>
        <div className="p-4 m-3 w-36 lg:w-72 overflow-hidden border-[0.1px] border-gray-300 hover:shadow-[0px_2px_2px_2px_#00000024] rounded-[10px] justify-center flex-col md:flex-row lg:flex-row cursor-pointer transition ease-in-out delay-90 hover:-translate-y-1 hover:scale-80 duration-300">
          <div className="flex flex-row flex-wrap justify-center pl-2 border-b-gray-200 border-b">
            <div>
              <p className="text-xs sm:text-sm font-semibold mb-1">
                {truncateStrHandler(data.name)}
              </p>
            </div>
          </div>
          <div class="px-6 pt-4 pb-2 text-center">
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Edit"
            >
              <EditOutlined
                onClick={() => handleEdit(data.id)}
                disabled={!isEditPermissionAvailable}
                // onClick={() => handleEdit(data.id)}
              />
            </span>
            {/* <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Delete"
            >
              <DeleteOutlined onClick={() => handleDelete(data.id)} />
            </span> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {isAddPermissionAvailable && !isAdd && !isEdit && (
          <div className="flex flex-wrap justify-end items-center pl-1 mb-6 w-full h-fit">
            <Button
              type="primary"
              className="flex flex-row items-center justify-center gap-2"
              onClick={() => handleAdd()}
            >
              Add
            </Button>
          </div>
        )}
        <div className="flex flex-wrap">
          {isAdd === true ? (
            <CreateRegistration
              close={() => setIsAdd(false)}
              isAdd={isAdd}
              editClose={() => setIsEdit(false)}
              isEdit={isEdit}
              title={'Edit Company Registration'}
            />
          ) : isEdit === true ? (
            <RegistartionDetails
              compnyId={compnyId}
              close={() => setIsEdit(false)}
            />
          ) : listView === 'true' ? (
            <>
              {isViewPermissionAvailable ? (
                companyData && tableList(companyData)
              ) : (
                <div className="w-full m-auto">
                  <Empty isError description={'Unauthorized'} />
                </div>
              )}
            </>
          ) : (
            companyListResponse?.results?.map((items) => {
              return registeredCompanyCardView(items);
            })
          )}
        </div>
      </div>
    </>
  );
}
