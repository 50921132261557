import { isEmpty } from 'lodash';

export const tableColumnData = [
  {
    fieldID: 'communication_type',
    headerName: 'Communication Type',
  },
  {
    fieldID: 'reciever',
    headerName: 'Recipient',
  },
  {
    fieldID: 'status',
    headerName: 'Status',
    cellClassName: (params) => {
      const status = params.row.status;

      const yellowRows = ['Unknown', 'Complaint', 'Undelivered'];
      const redRows = [
        'Failed',
        'Unsubscribe Confirmation',
        'Expired',
        'Bounce',
        'Rejected',
      ];
      const greenRows = [
        'Completed',
        'Sent',
        'Replied',
        'Delivered',
        'Subscription Confirmation',
        'Delivery',
        'Read',
        'Send',
      ];

      if (redRows.includes(status)) {
        return 'red-row';
      } else if (yellowRows.includes(status)) {
        return 'yellow-row';
      } else {
        return 'green-row';
      }
    },
  },
  {
    fieldID: 'send_date',
    headerName: 'Created',
  },
];

export const tableColumnDataSourcing = [
  {
    fieldID: 'first_name',
    headerName: 'Candidate Name',
  },
  {
    fieldID: 'email',
    headerName: 'Email',
  },
  {
    fieldID: 'phone_number',
    headerName: 'Phone Number',
  },
  {
    fieldID: 'title',
    headerName: 'Source',
  },
  {
    fieldID: 'sourced_date',
    headerName: 'Created',
  },
];

export const tableColumnDataScreening = [
  {
    fieldID: 'first_name',
    headerName: 'Candidate Name',
  },
  {
    fieldID: 'email',
    headerName: 'Email',
  },
  {
    fieldID: 'phone_number',
    headerName: 'Phone Number',
  },
  {
    fieldID: 'status_display',
    headerName: 'Screening',
    cellClassName: (params) => {
      const status = params.row.status;
      const yellowRows = ['SOURCED', 'HOLD', 'INTERVIEW_PROGRESS','COMPANY_ON_HOLD','COMPANY_EXTENSION'];
      const redRows = [
        'COMPANY_RESCENTED',
        'REJECTED',
        'OFFER_REJECTED',
        'CANDIDATE_DECLINED',
        'CANDIDATE_GHOSTING',
      ];
      const greenRows = [
        'APPLIED',
        'INTERVIEW_COMPLETED',
        'NEXT_LEVEL',
        'OFFERED',
        'CANDIDATE_ACCEPTED',
        'HIRED',
      ];

      if (redRows.includes(status)) {
        return 'red-row';
      } else if (yellowRows.includes(status)) {
        return 'yellow-row';
      } else {
        return 'green-row';
      }
    },
  },
  {
    fieldID: 'total_experience',
    headerName: 'Total Experience',
  },
  {
    fieldID: 'current_company',
    headerName: 'Current Company',
  },
  {
    fieldID: 'current_ctc',
    headerName: 'Current CTC',
  },
  {
    fieldID: 'expected_ctc',
    headerName: 'Expected CTC',
  },
  {
    fieldID: 'notice_period',
    headerName: 'Notice Period',
  },
  {
    fieldID: 'location',
    headerName: 'Location',
  },
];
export const EMAIL_COLORS = [
  {
    base: '#9471FF',
    legend: 'bg-[#9471FF]',
    text: 'text-[#9471FF]',
  },
  {
    base: '#819AFF',
    legend: 'bg-[#819AFF]',
    text: 'text-[#819AFF]',
  },
  {
    base: '#FDBA60',
    legend: 'bg-[#FDBA60]',
    text: 'text-[#FDBA60]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const WHATSAPP_COLORS = [
  {
    base: '#0492BE',
    legend: 'bg-[#0492BE]',
    text: 'text-[#0492BE]',
  },
  {
    base: '#07D4FD',
    legend: 'bg-[#07D4FD]',
    text: 'text-[#07D4FD]',
  },
  {
    base: '#58E0FA',
    legend: 'bg-[#58E0FA]',
    text: 'text-[#58E0FA]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const SMS_COLORS = [
  {
    base: '#43488A',
    legend: 'bg-[#43488A]',
    text: 'text-[#43488A]',
  },
  {
    base: '#5BA688',
    legend: 'bg-[#5BA688]',
    text: 'text-[#5BA688]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const VOICE_BOT_COLORS = [
  {
    base: '#827799',
    legend: 'bg-[#827799]',
    text: 'text-[#827799]',
  },
  {
    base: '#47A5DB',
    legend: 'bg-[#47A5DB]',
    text: 'text-[#47A5DB]',
  },
  {
    base: '#49637A',
    legend: 'bg-[#49637A]',
    text: 'text-[#49637A]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const INTERVAL_TYPES = {
  MONTHLY: 'month',
  WEEKLY: 'week',
  CUSTOM: 'day', // Custom Ranges
};
export const Dot = ({ color }) => (
  <div className={`w-2 h-7 rounded-full ${color}`}></div>
);

export const Date = ({ start, end, intervalType, dateRange }) => {
  let date = start?.format('MMMM YYYY');
  if (intervalType === INTERVAL_TYPES.WEEKLY) {
    date = `${start?.format('DD MMMM YYYY')} - ${end?.format('DD MMMM YYYY')}`;
  } else if (
    intervalType === INTERVAL_TYPES.CUSTOM &&
    !isEmpty(dateRange)
  ) {
    const [rangeStart, rangeEnd] = dateRange;
    date = `${rangeStart?.format('DD MMMM YYYY')} - ${rangeEnd?.format('DD MMMM YYYY')}`;
  }
  return (
    <h3 className="text-[#4A4A4A] font-semibold text-base">{date}</h3>
  );
};
export const CustomTooltip = ({ active, payload }) => {
  if (active && !isEmpty(payload)) {
    const markupColor = `text-[${payload[0].payload.fill}]`;
    return (
      <div className=" backdrop-blur-3xl rounded-lg py-1 px-2 bg-white">
        <p
          className={`text-xs font-normal ${markupColor}`}
        >{`${payload[0].value} ${payload[0].name}`}</p>
      </div>
    );
  }
};
