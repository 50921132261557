import { DatePicker, Input } from 'antd';
import { memo, useEffect, useState } from 'react';
import PreviewTemplate from './PreviewTemplate';
import { extractPlaceholders, extractVariableFromString, getSamplePreviewData, replacePlaceholders } from 'Utilities/utils';
import dayjs from 'dayjs';

const TemplatePreview = ({ data, description, subjectName }) => {
  const [values, setValues] = useState({});
  const [updatedHtml, setUpdatedHtml] = useState(description);
  const [subject, setSubject] = useState(subjectName)
  const [fields, setFields] = useState([])

  useEffect(()=> {
    const subjectFields = extractPlaceholders(subjectName)
    const description_fields = extractPlaceholders(description);
    let fields = [...subjectFields,...description_fields]
    if (fields?.length) {
      fields = [...new Set(fields)]; //remove duplications
    }
    setFields(fields)
  },[])

  useEffect(()=> {
    if(fields?.length){
      fields?.map((field)=> {
        const sampleData = getSamplePreviewData(field)
        setValues((prevValue) => {
          return {
            ...prevValue,
            [field]: sampleData,
          };
        });
      })
    }
  },[fields])

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      // href="${values?.interview_link}"
      description = description.replace(
        '{interview_link}',
        `<a target="_blank" rel="noopener noreferrer" class="link">{interview_link}</a>`
      );
      description = description.replace(
        '{prescreen_link}',
        `<a target="_blank" rel="noopener noreferrer" class="link">{prescreen_link}</a>`
      );
      description = description.replace(
        '{candidate_link}',
        `<a target="_blank" rel="noopener noreferrer" class="link">{candidate_link}</a>`
      );
      description = description.replace(
        '{slot_link}',
        `<a target="_blank" rel="noopener noreferrer" class="link">{slot_link}</a>`
      );
      description = description.replace(
        '{login_link}',
        `<a target="_blank" rel="noopener noreferrer" class="link">{login_link}</a>`
      );
      const updated = replacePlaceholders(description, values);
      const updated_subject = replacePlaceholders(subjectName, values);
      setUpdatedHtml(updated);
      setSubject(updated_subject)
    }
  }, [values]);

  const handleValue = (e) => {
    const { name, value } = e.target;
    setValues((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleDateChange = (date, dateString, field) => {
    // let dateValue = `{${field}}`;
    let dateValue = getSamplePreviewData(field);
    if (date) {
      dateValue = dayjs(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
    }
    console.log(dateValue, 'dateValue');
    setValues((prevValue) => {
      return {
        ...prevValue,
        [field]: dateValue,
      };
    });
  };
  console.log(fields,"values");
  return (
    <div className="w-full md:flex block p-4">
      <div className="md:w-[40%] w-full border-r border-[#edeff2] p-5">
        {fields?.map((field) =>
          field === 'start_date' || field === 'end_date' || field === 'date' ? (
            <DatePicker
              placeholder={`enter ${field}`}
              className="mb-4 w-full"
              size="large"
              value={values[field] && !values[field].includes("{") ? dayjs(values[field],"DD/MM/YYYY") : ""}
              format={'DD/MM/YYYY'}
              onChange={(date, dateString) =>
                handleDateChange(date, dateString, field)
              }
              disabledDate={(current) => {
                if(field === "end_date")
                {
                  return current && current < dayjs(new Date()).startOf('day');
                }
                return false;
              }}
            />
          ) : (
            <Input
              name={field}
              onChange={(e) => handleValue(e)}
              size="large"
              placeholder={`enter ${field}`}
              className="mb-4"
              value={values[field]}s
              // onBlur={()=> handleFocus}
            />
          ),
        )}
      </div>
      <div className="md:w-[60%] w-full p-5">
        <PreviewTemplate htmlContent={updatedHtml} subject={subject} />
      </div>
    </div>
  );
};

export default memo(TemplatePreview);
