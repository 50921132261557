import "Styles/InterviewScreen.scss";
import LOGO from "../../Assets/images/interview/yviLogo.png";
import womenStudy from "../../Assets/images/interview/Woman_studying_online.png";
import { useState } from "react";
import { useGetInterviewInformation } from "ServiceHooks/interviewHooks";
import { useParams } from "react-router-dom";
import GuidlinesScreen from "Components/VideoInterview/GuidlinesScreen";


const InterviewDetails = () => {

  const [isGuidlineScreen, setGuildlineScreen] = useState(false);

  var params = useParams();

  const {
    data: interviewInfo,
    isFetching: isInterviewInfoFetching,
    status: interviewInfoStatus,
    error: interviewInfoError,
  } = useGetInterviewInformation({ paramsData: params.token });


  console.log("interviewInfo", interviewInfo);


  const goToGuidlineScreen = () => {
     setGuildlineScreen(true)
  };

  const buildDetailScreen = () => {
    return (
      <div className="interviewDetailsMainDiv">
        <div className="interviewDetailsSubDivOne relative">
          <div>
            <img className="online_test_logo" src={LOGO} alt="logo" />
            <h1 className="helloText mt-4">Hey, Hello</h1>
            <h1 className="welcomeText mb-20">Welcome to AI interview</h1>
          </div>
          <div className="absolute bottom-[-85px] rounded-2xl border border-[#E8E8E8] shadow-custom bg-[#FFF] p-5 px-10 flex items-center justify-between lg:w-[55%] 2xl:w-[35%] w-[90%]">
            <div className="text-left">
              <h1 className="text-[#000] sm:text-[24px] ms:text-[18px] text-[16px]">{interviewInfo?.title}</h1>
              <ul className="mt-3">
                <li className="text-[#000] leading-[30px] sm:text-[18px] ms:text-[14px] text-[13px]">
                  Job Code: {interviewInfo?.job_reference_number}
                </li>
                <li className="text-[#000] leading-[30px] sm:text-[18px] ms:text-[14px] text-[13px]">
                Duration: {interviewInfo?.total_hours}
                </li>
              </ul>
            </div>
            <div className="ms:w-[155px] w-[94px] ms:h-[142px] h-[86px]">
              <img src={womenStudy} alt="women" className="w-full h-full" />
            </div>
        </div>
        </div>
        
        
        {/* <div className="centerDiv">
          <div className="flex pt-10 pb-0 px-10">
            <div className="flex-1">
              <h1>{interviewInfo?.title}</h1>
              <ul className="mt-3">
                 <li className="text-[#000] text-[18px] leading-[30px]">
                  Job Code: {interviewInfo?.job_reference_number}
                 </li>
                 <li className="text-[#000] text-[18px] leading-[30px]">
                 Duration: {interviewInfo?.total_hours}
                 </li>
              </ul>
            </div>
            <div className=" w-[155px] h-[142px]">
              <img src={womenStudy} alt="women" className="w-full h-full" />
            </div>
          </div>
        </div> */}
        <div className="interviewDetailsSubDivTwo">
          <div className="firstPage_btn_div">
            <button className="goToInterview mt-20" onClick={goToGuidlineScreen}>
              Go to Interview
            </button>
          </div>
        </div>
      </div>
    );
  };




  return (
    <div className="interviewMainDiv">
      {
        isGuidlineScreen ? <GuidlinesScreen interviewInfoData={interviewInfo}/> : buildDetailScreen()
      }
    </div>
  );
};

export default InterviewDetails;
