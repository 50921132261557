import { useState, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import TextEdit from '../CommonComponents/TextEdit';
import { ContentCopy } from '@mui/icons-material';
import { Modal, Switch, message } from 'antd';
import { isEmpty } from 'lodash';

import 'Styles/commonStyles.scss';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import CommunicationService from '../../Services/communicationService';
import { useGetCommunicationTransactions } from 'ServiceHooks/communicationHooks';
import TemplatePreview from './TemplatePreview';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';

var updatedText;
const Email = () => {
  const [getTransactionId, setGetTransactionId] = useState('');
  const [activeForm, setActiveForm] = useState(false);
  const [details, setDetails] = useState();
  const [description, setJobDescription] = useState();
  const [error, setError] = useState(false);
  const [isValidateError, setIsisValidateError] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email_subject: '',
    },
  });

  const subjectField = useWatch({ control, name: 'email_subject' });

  const emptyBracesRegex = /{\s*}/g;
  const keywordMatchRegex = /{([^{}]+)}/g;

  const onChange = (e) => {
    setJobDescription(e);
  };

  const getTranscationDetails = async (id) => {
    await CommunicationService.getTranscationDataById(id).then(
      (response) => {
        setDetails(response.data.data);
        setValue('email_subject', response?.data?.data?.subject);
        setJobDescription(response?.data?.data?.html_content);
        setRefreshKey((prevKey) => prevKey + 1);
      },
    );
  };

  const handleNameCopy = (item) => {
    updatedText = `{${item}}`;
    navigator.clipboard.writeText(updatedText);
    message.success('Copied');
  };

  const handelReset = async () => {
    await CommunicationService.getRestTransactionById(getTransactionId)
      .then((response) => {
        setDetails(response.data.data);
        setValue('email_subject', response?.data?.data?.subject);
        setJobDescription(response?.data?.data?.html_content);
      })
      .catch((err) => {});
  };

  const { data: transactions } = useGetCommunicationTransactions({});

  const transactionOptions = useMemo(() => {
    if (isEmpty(transactions?.results)) return [];
    const options = transactions?.results?.map((item) => {
      return {
        label: item.value,
        value: item.key,
      };
    });
    return options;
  }, [transactions]);

  const selectTransactionHandler = ({ key }) => {
    setGetTransactionId(key);

    // Form Activate
    if (key === '') {
      setActiveForm(false);
    } else {
      setActiveForm(true);
    }
    getTranscationDetails(key);
  };
  const handleSwtich = async (e) => {
    setDetails((prev)=> {
      return {
        ...prev,
        is_active: e
      }
    })
    await CommunicationService.activeEmailTransaction(getTransactionId, e)
      .then((res) => {})
      .catch((err) => {});
  };

  const onSubmit = async (data, e) => {
    const array = details?.parameters;
    const matches = description.match(keywordMatchRegex);

    // Test if the input matches the regex pattern
    const emptybracesvalidate = emptyBracesRegex.test(description);
    const isValidSentence = matches.some((match) => {
      // Remove curly braces from the match
      const element = match.slice(1, -1).trim();
      return !array?.includes(element);
    });

    if (emptybracesvalidate) {
      setError(true);
    } else if (isValidSentence === true) {
      setIsisValidateError(true);
    } else if (details?.id == null) {
      try {
        setError(false);
        setIsisValidateError(false);
        await CommunicationService.addEmailContent(
          data,
          getTransactionId,
          subjectField,
          description,
        );

        await messageApi.open({
          type: 'success',
          content: 'Created Successfully',
        });
      } catch (error) {
        await messageApi.open({
          type: 'error',
          content: DEFAULT_ERROR_MESSAGE,
        });
      }
    } else if (details?.id) {
      try {
        setError(false);
        setIsisValidateError(false);
        await CommunicationService.editEmailContent(
          data,
          getTransactionId,
          details?.id,
          subjectField,
          description,
        );

        await messageApi.open({
          type: 'success',
          content: 'Edited Successfully',
        });
      } catch (error) {
        await messageApi.open({
          type: 'error',
          content: DEFAULT_ERROR_MESSAGE,
        });
      }
    }
  };
  return (
    <div className="w-full">
      <div className="bg-secondaryBgMain rounded-lg mt-4 pb-8">
        <div className="ml-8">
          <form
            className="overflow-y-auto customScrollBar max-h-[65vh]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="md:flex md:flex-row flex-col flex-wrap">
              <div className="md:w-1/2 px-6 mt-10 rounded-md border border-gray-300">
                <div className="w-full mt-8">
                  <div className="mt-4 w-full">
                    <Select
                      label={'Transaction'}
                      name={'transOption'}
                      placeholder="Select an Option"
                      options={transactionOptions}
                      control={control}
                      onChange={(e) => selectTransactionHandler(e)}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    <Input
                      label={'Subject'}
                      name={'email_subject'}
                      control={control}
                      placeholder={`Add Mail Subject Here`}
                      rules={{
                        validate: {
                          emptyBraces: (value) =>
                            !emptyBracesRegex.test(value) ||
                            'Empty tags are not allowed',
                          invalidBraceContent: (value) => {
                            const matches =
                              value?.match(keywordMatchRegex);
                            const array = details?.parameters;

                            const incorrectTagsDetected = matches?.some(
                              (match) => {
                                // Remove curly braces from the match
                                const element = match.slice(1, -1).trim();

                                return !array?.includes(element);
                              },
                            );

                            if (isEmpty(array) && !isEmpty(matches)) {
                              return 'need keywords to proceed';
                            } else if (incorrectTagsDetected) {
                              return 'some keywords are invalid';
                            } else {
                              return true;
                            }
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="w-full mt-8 pb-10">
                    <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                      Content
                    </label>
                    <TextEdit
                      key={refreshKey}
                      id="description"
                      required
                      onChange={onChange}
                      initialValue={
                        details?.html_content ? details?.html_content : ''
                      }
                    />
                    {error && (
                      <p className="text-red-600 text-xs font-semibold text-start">
                        Curly braces cannot be empty
                      </p>
                    )}
                    {isValidateError && (
                      <p className="text-red-600 text-xs font-semibold text-start">
                        Invalid data inside curly braces
                      </p>
                    )}
                  </div>
                  {details && (
                    <div className="flex justify-end mb-4">
                      <Switch
                        checked={details?.is_active}
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        onChange={(e) => handleSwtich(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="md:w-1/2 px-8">
                {activeForm ? (
                  <div className="w-full h-fit p-2 mt-10 rounded-md border-dashed border-gray-300 border-2">
                    <div className="pl-4 w-full flex-col flex mt-6 ">
                      {details &&
                        details.parameters.map((item, index) => (
                          <div>
                            {/* 
                          {index === 0 && (
                            <div className="flex justify-end mb-4">

                              <Switch
                                defaultChecked={item.is_active}
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                                onChange={(e) => handleSwtich(e)}
                             
                              />
                            </div>
                          )} */}
                            <div className="flex">
                              <div
                                key={`trans-name-${index}`}
                                className="text-sm w-full px-4 py-2 mb-8 flex bg-white focus:outline-none rounded-md border border-blue-500"
                              >
                                {item}
                              </div>
                              <button
                                type="button"
                                className="text-indigo-300 flex flex-wrap"
                                title="copy"
                                onClick={() => handleNameCopy(item)}
                              >
                                <span className="mt-[6px] ml-[5px]">
                                  <ContentCopy />
                                </span>
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <Empty
                      description={<>{<p> No Selected Transaction</p>}</>}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex text-white text-sm items-center mt-4 font-semibold mr-10 mb-6 ${details?.html_content ? 'justify-between' : 'justify-end'}`}
            >
              {details?.html_content && (
                <Button
                  isSecondary
                  onClick={() => setPreviewOpen(true)}
                  htmlType="button"
                >
                  Preview
                </Button>
              )}
              <div className="flex items-center justify-between">
                {contextHolder}
                <Button
                  isSecondary
                  onClick={handelReset}
                  htmlType="button"
                >
                  Reset
                </Button>
                <Button type="primary" className="ml-4" htmlType="submit">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {previewOpen && (
        <Modal
          title="Preview"
          open={previewOpen}
          onCancel={() => setPreviewOpen(false)}
          width={1200}
          footer={null}
        >
          <TemplatePreview
            data={details}
            description={description}
            subjectName={subjectField}
          />
        </Modal>
      )}
    </div>
  );
};

export default Email;
