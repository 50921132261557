import axios from 'axios';
import Config from '../Services/Config';
import authHeader from './authHeader';

const userLogin = (data) => {
  return axios.post(`${Config.API_URL}api/v1/accounts/auth/login/`, data);
};

const logout = (token) => {
  return axios.post(
    Config.API_URL + 'api/v1/accounts/auth/logout/',
    token,
  );
};

const loginWithOtp = (data) => {
  console.log('api_service+_data', data);
  var formData = data;
  return axios.post(
    Config.API_URL + 'api/v1/accounts/auth/generate-login-otp/',
    formData,
  );
};

const verifyOtp = (ndata) => {
  var nId = localStorage.getItem('user_id');

  var oData = {
    otp: ndata.otp,
    user: nId,
  };
  return axios.post(
    Config.API_URL + 'api/v1/accounts/auth/validate-otp-and-login/',
    oData,
  );
};

const forgotPasswordUrl = (data) => {
  var formData = JSON.stringify(data);
  var config = {
    method: 'post',
    url: Config.API_URL + 'api/v1/accounts/forgot-password/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: formData,
  };
  return axios(config);
};

const confirmPassword = (data, UID, token) => {
  var formData = data;
  return axios.post(
    Config.API_URL +
      `api/v1/accounts/user_password_reset/${UID}/${token}/`,
    formData,
  );
};

const changePassword = (data) => {
  return axios.post(
    Config.API_URL + `api/v1/accounts/change-password/`,
    { old_password: data.old_password, new_password: data.new_password },
    {
      headers: authHeader(),
    },
  );
};

const fetchCompanyDetailsWithoutLogin = () => {
  return axios.get(
    `${Config.API_URL}api/v1/settings/company-basic-info-without-login/`,
  );
};

// interview service.

const interviewDetails = (token) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/ai-interview-details/${token}/`,
  );
};

const sendLiveSnap = (file, nInterviewId) => {
  var oFormData = new FormData();
  oFormData.append('candidate_image', file, 'profile.jpeg');

  var config = {
    method: 'PUT',
    url:
      Config.API_URL +
      `api/v1/interview/ai-interview-candidate-image/${nInterviewId}/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: oFormData,
  };
  return axios(config);
};

const updateUserPreference = (userData) => {
  const config = {
    headers: authHeader(),
  };
  return axios.put(
    Config.API_URL + 'api/v1/settings/custom-table-settings/',
    userData,
    config,
  );
};

const getUserPreferenceData = () => {
  const config = {
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/settings/user-table-settings/`,
    config,
  );
};

const getCompanyList = (params) => {
  const config = {
    params,
    headers: authHeader(),
  };
  return axios.get(
    `${Config.API_URL}api/v1/company/list-companies/`,
    config,
  );
};

const authService = {
  userLogin,
  logout,
  loginWithOtp,
  verifyOtp,
  forgotPasswordUrl,
  confirmPassword,
  fetchCompanyDetailsWithoutLogin,
  interviewDetails,
  sendLiveSnap,
  changePassword,
  updateUserPreference,
  getUserPreferenceData,
  getCompanyList,
};
export default authService;
