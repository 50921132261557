import React, { useState, useRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import TextEdit from '../CommonComponents/TextEdit';
import { Switch } from 'antd';
import { ContentCopy } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';

import CommunicationService from '../../Services/communicationService';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Button from 'Components/CommonComponents/Button/Button';
import { useGetCommunicationTransactions } from 'ServiceHooks/communicationHooks';

var updatedText;
const VoiceBot = () => {
  const [getTransactionId, setGetTransactionId] = useState('');
  const [activeForm, setActiveForm] = useState(false);
  const [details, setDetails] = useState();
  const [description, setJobDescription] = useState();
  const [error, setError] = useState(false);
  const [isValidateError, setIsisValidateError] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const inputRef = useRef();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onChange = (e) => {
    setJobDescription(e);
  };

  const getTranscationDetails = async (selectedValue) => {
    await CommunicationService.getTranscationVoiceById(selectedValue).then(
      (response) => {
        setDetails(response.data.data);
        setRefreshKey((prevKey) => prevKey + 1);
        setJobDescription(response.data.data?.audio_content);
      },
    );
  };
  const handelReset = async () => {
    await CommunicationService.activeVoiceTranscation(getTransactionId)
      .then((response) => {
        setDetails(response.data.data);
        toast.success('Successfully Updated');
      })
      .catch((err) => {});
  };

  const handleNameCopy = (item) => {
    updatedText = `{${item}}`;
    navigator.clipboard.writeText(updatedText);
    toast.success('Copied');
  };

  function removeTags(str) {
    if (str === null || str === '') return false;
    else str = str?.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str?.replace(/(<([^>]+)>)/gi, '');
  }

  const { data: transactions } = useGetCommunicationTransactions({});

  const transactionOptions = useMemo(() => {
    if (isEmpty(transactions?.results)) return [];
    const options = transactions?.results?.map((item) => {
      return {
        label: item.value,
        value: item.key,
      };
    });
    return options;
  }, [transactions]);

  const selectTransactionHandler = ({ key }) => {
    setGetTransactionId(key);

    // Form Activate
    if (key === '') {
      setActiveForm(false);
    } else {
      setActiveForm(true);
    }
    getTranscationDetails(key);
  };
  const handleSwtich = async (e) => {
    await CommunicationService.activeNotificationTranscation(
      getTransactionId,
      e,
    )
      .then((res) => {
        toast.success('Successfully updated');
      })
      .catch((err) => {});
  };
  const onSubmit = async (data, e) => {
    setIsisValidateError(false);
    var content = removeTags(description)?.replace(/&nbsp;/g, '');
    const array = details?.parameters;
    const emptyBracesRegex = /{\s*}/g;
    const regex = /{([^{}]+)}/g;
    const matches = description?.match(regex);

    // Test if the input matches the regex pattern
    const emptybracesvalidate = emptyBracesRegex.test(description);
    const isValidSentence = matches.some((match) => {
      // Remove curly braces from the match
      const element = match?.slice(1, -1).trim();
      return !array?.includes(element);
    });

    if (emptybracesvalidate) {
      setError(true);
    } else if (isValidSentence === true) {
      setIsisValidateError(true);
    } else if (details?.id == null) {
      setError(false);
      setIsisValidateError(false);
      await CommunicationService.addVoiceContent(
        data,
        getTransactionId,
        content,
      )
        .then(
          (res) => {
            toast.success('Successfully Created');
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            toast.error('Something went wrong..!!');
          },
        )
        .catch((err) => {});
    } else if (details?.id) {
      setError(false);
      setIsisValidateError(false);
      await CommunicationService.editVoiceContent(
        data,
        getTransactionId,
        details?.id,
        content,
      )
        .then(
          (res) => {
            toast.success('Successfully Created');
            e.target.reset();
            inputRef.current.resetSelectedValues();
          },
          (error) => {
            toast.error('Something went wrong..!!');
          },
        )
        .catch((err) => {});
    }
  };
  return (
    <>
      <div className="w-full">
        <div className="bg-secondaryBgMain rounded-lg mt-4 pb-4">
          <div className="ml-8">
            <form
              className="overflow-y-auto max-h-[65vh]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="md:flex md:flex-row flex-col flex-wrap">
                <div className="w-1/2 px-6 mt-10 rounded-md border border-gray-300">
                  <div className="w-full mt-8">
                    <div className="mt-4 w-full">
                      <Select
                        label={'Transaction'}
                        name={'transOption'}
                        placeholder="Select an Option"
                        options={transactionOptions}
                        control={control}
                        onChange={(e) => selectTransactionHandler(e)}
                      />
                    </div>
                    <div className="w-full mt-8 pb-10">
                      <label className="text-black text-sm font-normal pr-2 pb-2 flex">
                        Content
                      </label>
                      <TextEdit
                        key={refreshKey}
                        id="description"
                        required
                        onChange={onChange}
                        initialValue={
                          details?.audio_content
                            ? details?.audio_content
                            : ''
                        }
                      />
                      {error && (
                        <p className="text-red-600 text-xs font-semibold text-start">
                          Curly braces cannot be empty
                        </p>
                      )}
                      {isValidateError && (
                        <p className="text-red-600 text-xs font-semibold text-start">
                          Invalid data inside curly braces
                        </p>
                      )}
                    </div>
                    {details && (
                      <div className="flex justify-end mb-4">
                        <Switch
                          defaultChecked={details?.is_active}
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                          onChange={(e) => handleSwtich(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/2 px-8">
                  {activeForm ? (
                    <div className="w-full h-fit p-2 mt-10 rounded-md border-dashed border-gray-300 border-2">
                      <div className="pl-4 w-full flex-col flex ">
                        {details &&
                          details.parameters.map((item, index) => (
                            <div>
                              <div className="flex">
                                <div
                                  className="text-sm w-full px-4 py-2 mb-8 flex bg-white focus:outline-none rounded-md border border-blue-500"
                                  key={`trans-name-${index}`}
                                >
                                  {item}
                                </div>
                                <button
                                  type="button"
                                  className="text-indigo-300 flex flex-wrap"
                                  title="copy"
                                  onClick={() => handleNameCopy(item)}
                                >
                                  <span className="mt-[6px] ml-[5px]">
                                    <ContentCopy />
                                  </span>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <Empty
                        description={
                          <>{<p> No Selected Transaction</p>}</>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex text-white text-sm justify-end items-center mt-4 font-semibold mr-10 mb-10">
                <div className="flex items-center justify-between">
                  <Button
                    isCancel
                    className="ml-4"
                    onClick={handelReset}
                    htmlType="button"
                  >
                    Reset
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="ml-4"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoiceBot;
