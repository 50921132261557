import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import jobService, {
  getAwaitingQuestionnaireJobListings,
} from '../Services/JobService';

/* 
  Used for fetching job details
*/
const useJobDetails = (config) => {
  const {
    paramsData: { jobId },
    ...queryConfigs
  } = config;
  const query = useQuery({
    queryKey: ['jobDetails'],
    queryFn: async () => {
      const details = await jobService.getViewMoreData(jobId);
      return details;
    },
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Used for fetching interview levels
*/
export const GET_INTERVIEW_LEVEL_JOB = 'GET_INTERVIEW_LEVEL_JOB';
const useGetInterviewLevels = (config) => {
  const {
    paramsData: { jobId, candidateIdArray, is_reschedule },
    enabled = true,
    queryKeyName = 'interviewLevels',
    ...queryConfig
  } = config;
  const query = useQuery({
    queryKey: [queryKeyName, jobId, candidateIdArray, is_reschedule],
    queryFn: async () => {
      const params = {
        job: jobId,
        candidates: JSON.stringify(candidateIdArray),
        is_reschedule: is_reschedule,
      };
      const details = await jobService.getJobInterviewLevels(params);
      const options = details.data.data.results.map((item) => {
        return {
          value: item.interview_level,
          label: item.interview_level_readable,
          ...item,
        };
      });
      return options;
    },
    enabled: enabled,
    refetchOnWindowFocus: false,
    ...queryConfig,
  });
  return query;
};
const useGetCheckInterviewLevels = (config) => {
  const {
    paramsData: {
      jobId,
      candidateIdArray,
      is_reschedule,
      isCheckInterviewLevel,
    },
    enabled = true,
    queryKeyName = 'checkinterviewLevels',
    ...queryConfig
  } = config;
  const query = useQuery({
    queryKey: [
      queryKeyName,
      jobId,
      candidateIdArray,
      is_reschedule,
      isCheckInterviewLevel,
    ],
    queryFn: async () => {
      const params = {
        job: jobId,
        candidates: JSON.stringify(candidateIdArray),
        is_reschedule: is_reschedule,
        level: isCheckInterviewLevel,
      };
      const details = await jobService.getCheckInterviewLevels(params);
      return details?.data?.data;
    },
    enabled: enabled,
    refetchOnWindowFocus: false,
    ...queryConfig,
  });
  return query;
};

const useGetJobScheduleList = ({ paramsData }) => {
  console.log('useGetJobScheduleList', paramsData);
  const pageNumber = paramsData?.page;
  const limit = paramsData?.pageSize;
  const offset = paramsData?.offset;
  const interviewId = paramsData?.interviewType?.value;
  const hasInterviewTypeId = !!paramsData?.interviewType?.value;
  const filedData = paramsData?.searchInputFinal;
  const queryKey = [
    'schedules',
    interviewId,
    pageNumber,
    paramsData?.searchInputFinal,
    paramsData?.searchInput,
    paramsData?.fromDate,
    paramsData?.endDate,
  ];
  const query = useQuery({
    queryKey,
    queryFn: async (data) => {
      const params = {
        limit: limit,
        offset: offset,
        page: pageNumber,
        job: paramsData?.jobId,
        interview_level: interviewId,
        search: paramsData?.searchInput,
        start_date: paramsData?.fromDate,
        end_date: paramsData?.endDate,
      };
      console.log('params', params);
      const details = await jobService.getJobSchedules(params, filedData);
      return details?.data?.data;
    },
    onError: (error) => {
      console.log('error', error);
    },
    enabled: hasInterviewTypeId,
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  return query;
};

const useScheduleJobInterview = (config) => {
  const { handleOnSuccess, onError = () => null } = config;
  const mutation = useMutation({
    mutationFn: async ({ data, params = {} }) => {
      const details = await jobService.scheduleJobInterview(data, params);
      return details?.data;
    },
    onSuccess: handleOnSuccess,
    onError,
  });
  return mutation;
};

const useUpdateConsultancyInterviewSchedule = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params, id }) => {
      const details = await jobService.updateConsultancyInterviewSchedule(
        body,
        params,
        id,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const useBuildResumeForCandidate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params = {} }) => {
      const details = await jobService.buildResumeForCandidate(
        body,
        params,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const useGetWebSocketInformationForInterviewScheduling = (config) => {
  const { params, onError, queryKey, ...queryConfig } = config;
  const query = useQuery({
    queryKey: [params, 'scheduleInterviewWebSocketInformation'],
    queryFn: async (data) => {
      const details = await jobService.getWebsocketInformation(params);
      return details?.data?.data;
    },
    onError,
    refetchOnWindowFocus: false,
    ...queryConfig,
  });
  return query;
};
export const FETCH_JOB_DETAILS_CANDIDATES_LIST =
  'FETCH_JOB_DETAILS_CANDIDATES_LIST';
const useGetJobCandidatesList = (config) => {
  const { paramsData, ...queryConfig } = config;
  const queryKey = [
    FETCH_JOB_DETAILS_CANDIDATES_LIST,
    paramsData?.searchInput,
    paramsData.page,
    paramsData?.jobId,
    paramsData.pageSize,
    paramsData?.status,
    paramsData?.search,
    paramsData?.searchInput,
    paramsData?.consultancy_only,
  ];
  const query = useQuery({
    queryKey,
    queryFn: async (data) => {
      const params = {
        job_id: paramsData?.jobId,
        limit: paramsData.pageSize,
        offset: paramsData.offset,
        page: paramsData.page,
        status: paramsData?.status,
        from_job: true,
        ...((!!paramsData?.searchInput || !!paramsData?.search) && {
          search: paramsData?.search || '',
        }),
        ...(!!paramsData?.consultancy_only && {
          consultancy_only: paramsData?.consultancy_only,
        }),
      };
      const details = await jobService.getJobCandidatesList(
        params,
        paramsData?.searchInput,
      );
      return details?.data?.data;
    },
    onError: (error) => {
      console.log('error', error);
    },
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...queryConfig,
  });
  return query;
};

const usePreScreenScoreCardListing = (config) => {
  const { params, ...queryConfig } = config;
  // const queryKey = [params?.job, params?.search];
  const filter = params?.filter_value;
  console.log('usePreScreenScoreCardListing', params);
  const queryKey = [
    params?.interview_level,
    params?.search,
    params?.filter_value,
    params?.pageSize,
    params?.page,
    params?.offset,
  ];
  const query = useQuery({
    queryKey,
    queryFn: async (data) => {
      const paramsData = {
        job: params?.job,
        interview_level: params?.interview_level,
        page: params?.page,
        offset: params?.offset,
        limit: params?.pageSize,
        search: params?.search,
      };
      const details = await jobService.getPreScreenScoreCardListing(
        paramsData,
        filter,
      );
      return details?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...queryConfig,
  });
  return query;
};

const useGetPreScreenAnswers = (config) => {
  const { params, ...queryConfig } = config;
  const queryKey = [params?.candidates];
  const query = useQuery({
    queryKey,
    queryFn: async (data) => {
      const details =
        await jobService.getPreScreenScoreCardAnswers(params);
      return details?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...queryConfig,
  });
  return query;
};

const useInterviewLevelScoreCardListing = (config) => {
  const { params, ...queryConfig } = config;
  let filter;
  if (params?.search) {
    filter = '';
  } else {
    filter = params?.filter_value;
  }
  const queryKey = [
    params?.interview_level,
    params?.search,
    params?.filter_value,
    params?.pageSize,
    params?.page,
    params?.offset,
  ];
  const query = useQuery({
    queryKey,
    queryFn: async (data) => {
      let paramsData;
      if (params?.search) {
        paramsData = {
          job: params?.job,
          interview_level: params?.interview_level,
          page: params?.page,
          offset: params?.offset,
          limit: params?.pageSize,
          search: params?.search,
        };
      } else {
        paramsData = {
          job: params?.job,
          page: params?.page,
          offset: params?.offset,
          limit: params?.pageSize,
          interview_level: params?.interview_level,
        };
      }
      console.log('params', params);
      const details = await jobService.getInterviewLevelScoreCardList(
        paramsData,
        filter,
      );
      return details?.data?.data;
    },
    onError: (error) => {
      console.log('error', error);
    },
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...queryConfig,
  });
  return query;
};

/* 
  Api for fetching job listings
*/
export const GET_JOB_LISTINGS = 'GET_JOB_LISTINGS';
const useGetJobListing = (config) => {
  const query = useQuery({
    queryKey: [GET_JOB_LISTINGS],
    queryFn: async () => {
      const details = await jobService.getJobListings(config?.params);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Jobs Page. Used to fetch most tabs' datagrid content.
*/
export const GET_JOBS_LIST = 'GET_JOBS_LIST';
export const useGetJobsList = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      GET_JOBS_LIST,
      params?.limit,
      params?.offset,
      params?.status,
      params?.search,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        status: params?.status,
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
      };
      const response = await jobService.getJobListings(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Jobs Page. Used to fetch the 'Awaiting Questionnaire' tab's datagrid content.
*/
export const GET_AWAITING_QUESTIONNAIRE_JOBS_LIST =
  'GET_AWAITING_QUESTIONNAIRE_JOBS_LIST';
export const useGetAwaitingQuestionnaireJobsList = ({
  params = {},
  filter = '',
  ...rest
}) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      GET_AWAITING_QUESTIONNAIRE_JOBS_LIST,
      params?.limit,
      params?.offset,
      params?.search,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
      };
      const response =
        await getAwaitingQuestionnaireJobListings(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Jobs Page. Fetches the page's tab metadata.
*/
export const GET_JOBS_LIST_COUNT = 'GET_JOBS_LIST_COUNT';
export const useGetJobsListCount = (config) => {
  const query = useQuery({
    queryKey: [GET_JOBS_LIST_COUNT],
    queryFn: async () => {
      const response = await jobService.getJobStatusList();
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Api for viewing the publishing status and states
*/
export const GET_CURRENT_JOB_PUBLISHING_STATUS =
  'GET_CURRENT_JOB_PUBLISHING_STATUS';
const useGetJobPublishingStatus = (config) => {
  const { statusId, ...rest } = config;
  const query = useQuery({
    queryKey: [GET_CURRENT_JOB_PUBLISHING_STATUS, statusId],
    queryFn: async () => {
      const details = await jobService.getPublish(statusId);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useJobSearch = (config) => {
  const { paramsData, onError = () => null, ...queryConfigs } = config;
  const queryKey = [paramsData?.status, paramsData?.search];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const params = {
        status: paramsData?.status,
        search: paramsData?.search,
        from_candidates: paramsData?.from_candidates,
      };
      const details = await jobService.getJobsList(params);
      return details?.data?.data;
    },
    onError,
    retry: false,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

const useGetJobActivities = (config) => {
  const { paramsData, onError = () => null, ...queryConfigs } = config;
  const queryKey = [paramsData?.candidateDetails?.id, paramsData?.job_id];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const params = {
        candidate: paramsData?.candidateDetails?.id,
        job_id: paramsData?.jobId,
      };
      const details = await jobService.getJobActivities(params);
      return details?.data?.data;
    },
    onError,
    retry: false,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

const GET_ALL_OFFER_LETTERS = 'GET_ALL_OFFER_LETTERS';
const useGetOfferLetterTemplates = (config) => {
  const query = useQuery({
    queryKey: [GET_ALL_OFFER_LETTERS],
    queryFn: async () => {
      const response = await jobService.getOfferLetterTemplates();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

const useSendCandidateOfferLetter = (config) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details = await jobService.sendCandidateOfferLetter(params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

export const useUpdateJobStatus = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, id }) => {
      const details = await jobService.updatePublishJobs(body, id);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const GET_REJECT_REASONS = 'GET_REJECT_REASONS';
const useGetRejectReasonTypes = (config) => {
  const query = useQuery({
    queryKey: GET_REJECT_REASONS,
    queryFn: async () => {
      const response = await jobService.getRejectReasonTypes();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const GET_HIRING_TEAM_DETAILS = 'GET_HIRING_TEAM_DETAILS';
const useGetHiringTeamDetails = (config) => {
  const jobId = config?.params?.jobId;
  const query = useQuery({
    queryKey: [GET_HIRING_TEAM_DETAILS, jobId],
    queryFn: async () => {
      const response = await jobService.getHiringTeam(jobId);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const GET_HIRING_STAGES_DETAILS = 'GET_HIRING_STAGES_DETAILS';
const useGetHiringStagesDetails = (config) => {
  const jobId = config?.params?.jobId;
  const query = useQuery({
    queryKey: [GET_HIRING_STAGES_DETAILS, jobId],
    queryFn: async () => {
      const response = await jobService.getHiringStage(jobId);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const GET_CLIENTS = 'GET_CLIENTS';
const useGetClients = (config) => {
  const { params = {} } = config;
  const query = useQuery({
    queryKey: [GET_CLIENTS, params?.search],
    queryFn: async () => {
      const response = await jobService.fetchClients(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const GET_CONSULTANCIES = 'GET_CONSULTANCIES';
const useGetConsultancies = (config) => {
  const { params = {} } = config;
  const query = useQuery({
    queryKey: [GET_CONSULTANCIES, params?.search],
    queryFn: async () => {
      const response = await jobService.fetchConsultancies(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const GET_SCHEDULE_REQUESTS_LIST = 'GET_SCHEDULE_REQUESTS_LIST';
const useGetScheduleRequestsList = (config) => {
  const { params = {}, filter } = config;
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (!isEmpty(filterPhrases)) {
    [filterKey, term] = filterPhrases;
    params[filterKey] = term;
  }

  const query = useQuery({
    queryKey: [
      GET_SCHEDULE_REQUESTS_LIST,
      filter,
      params?.job,
      params?.level,
      params?.limit,
      params?.offset,
      params?.search,
    ],
    queryFn: async () => {
      const response = await jobService.fetchScheduleRequests(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const useSendOfferRejection = (config) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details = await jobService.rejectCandidateOffer(params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const useJobFields = () => {
  const query = useQuery({
    queryKey: ['jobFields'],
    queryFn: async () => {
      const oFieldsDetails = await jobService.getJobField();
      return oFieldsDetails?.data?.data;
    },
    refetchOnWindowFocus: false,
  });
  return query;
};

const useGetOfferedOrRejectList = ({ paramsData }) => {
  const { status } = paramsData;
  console.log('useGetOfferedOrRejectList_paramsData', paramsData);
  const filedData = paramsData?.searchInputFinal;
  const query = useQuery({
    queryKey: ['OfferedOrReject', paramsData],
    queryFn: async () => {
      const params = {
        limit: paramsData?.pageSize,
        offset: paramsData?.offset,
        page: paramsData?.page,
        status: paramsData?.status,
        job_id: paramsData?.nJobId,
        search: paramsData?.searchInput,
      };
      const response = await jobService.getOfferedOrRejectList(
        params,
        filedData,
      );
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  return query;
};

/* 
  Add Job / Edit Job > creates Job Activity log for each job phase.
  Refer to JOB_ACTIVITY_LOGGER_TYPES for applicable phases.
*/
const useCreateActivityLoggerId = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await jobService.createJobActivityLogId(body);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

export const JOB_CREATION_COMMON_DATA = 'JOB_CREATION_COMMON_DATA';
const useGetJobCreationCommonData = (config) => {
  const query = useQuery({
    queryKey: [JOB_CREATION_COMMON_DATA],
    queryFn: async () => {
      const response = await jobService.getReferences();
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};
const useGetActivityData = ({ paramsData }) => {
  const query = useQuery({
    queryKey: ['activity'],
    queryFn: async () => {
      const params = {
        candidateId: paramsData?.nCandidateId,
        job_id: paramsData?.nJobId,
      };
      const response = await jobService.getActivityData(params);
      return response?.data?.data;
    },
  });
  return query;
};

const useJobEditData = (jobId) => {
  const query = useQuery({
    queryKey: ['jobEdit'],
    queryFn: async () => {
      const oFieldsDetails = await jobService.getEditJob(jobId);
      return oFieldsDetails?.data?.data;
    },
  });
  return query;
};

const useGetParams = ({ paramsData, ...rest }) => {
  const query = useQuery({
    queryKey: ['jobParam'],
    queryFn: async () => {
      const params = {
        job_id: paramsData?.nJobId,
      };
      const response = await jobService.getJobParam(params);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetStatusReasonTypes = (config) => {
  const query = useQuery({
    queryKey: ['statusUpdate'],
    queryFn: async () => {
      const response = await jobService.getStatusReasonTypes();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const useSendUpdatedScore = (config) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details = await jobService.updateParamScore(params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const useSendStatusUpdate = (config) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details = await jobService.sendStatusUpadte(params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

const useSendNoShowStatusUpdate = (config) => {
  const mutation = useMutation({
    mutationFn: async (params) => {
      const details = await jobService.sendNoShowStatusUpadte(params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};
const useGetJobActivity = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['activitys', paramsData?.jobId],
    queryFn: async () => {
      const details = await jobService.getJobActivityDetails(
        paramsData?.jobId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

export const useUpdateJobGridColumn = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ data }) => {
      const details = await jobService.updateJobGridColumn(data);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};
// const useGetJobActivity = (config) => {
//   const {
//     paramsData = {},
//     ...queryProps
//   } = config;
//   const query = useQuery({
//     queryKey: ['activitys', paramsData?.companyId],
//     queryFn: async () => {
//       const params = {
//         job_id: paramsData?.nJobId,
//       };
//       const response = await jobService.getJobActivityDetails();
//       return response?.data?.data;
//     },
//     retry: false,
//     refetchOnWindowFocus: false,
//     ...config,
//   });

//   return query;
// };
export {
  useGetInterviewLevels,
  useGetJobScheduleList,
  useScheduleJobInterview,
  useGetJobCandidatesList,
  useJobDetails,
  useJobSearch,
  useGetJobListing,
  useGetJobPublishingStatus,
  useGetJobActivities,
  useInterviewLevelScoreCardListing,
  usePreScreenScoreCardListing,
  useGetPreScreenAnswers,
  useUpdateConsultancyInterviewSchedule,
  useBuildResumeForCandidate,
  useGetWebSocketInformationForInterviewScheduling,
  useGetOfferLetterTemplates,
  useSendCandidateOfferLetter,
  useGetRejectReasonTypes,
  useGetHiringTeamDetails,
  useGetHiringStagesDetails,
  useSendOfferRejection,
  useCreateActivityLoggerId,
  useGetScheduleRequestsList,
  useJobFields,
  useGetJobCreationCommonData,
  useJobEditData,
  useGetClients,
  useGetConsultancies,
  useGetOfferedOrRejectList,
  useGetActivityData,
  useGetParams,
  useGetStatusReasonTypes,
  useSendUpdatedScore,
  useSendStatusUpdate,
  useGetJobActivity,
  useGetCheckInterviewLevels,
  useSendNoShowStatusUpdate,
};
