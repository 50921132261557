import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { checkPermissions } from 'Utilities/utils';
import { filterMenusWithPermissions } from 'Utilities/componentSpecificUtilities';
import { FULL_MENU_LIST } from 'Utilities/constants';
import authService from 'Services/authService';

const menus = [
  FULL_MENU_LIST.dashboard,
  FULL_MENU_LIST.jobs,
  FULL_MENU_LIST.candidates,
  FULL_MENU_LIST.interviews,
  FULL_MENU_LIST.questionBank,
  FULL_MENU_LIST.report,
  FULL_MENU_LIST.communication,
  FULL_MENU_LIST.settings,
];

const adminMenus = [
  FULL_MENU_LIST.dashboard,
  FULL_MENU_LIST.jobs,
  FULL_MENU_LIST.candidates,
  FULL_MENU_LIST.interviews,
  FULL_MENU_LIST.questionBank,
  FULL_MENU_LIST.communication,
  FULL_MENU_LIST.settings,
  FULL_MENU_LIST.report,
  FULL_MENU_LIST.registration,
  FULL_MENU_LIST.license,
  FULL_MENU_LIST.enquires,
  FULL_MENU_LIST.questions,
];

const useAuthentication = () => {
  const [menuItems, setMenuItems] = useState(menus);
  const [adminMenuItems, setAdminMenuItems] = useState(adminMenus);
  const userDetails = useMemo(() => {
    const useDetailsString = localStorage.getItem('user');
    return JSON.parse(useDetailsString);
  }, []);
  const fullPermissionInfo = useMemo(
    () => userDetails?.permissions || {},
    [userDetails?.permissions],
  );
  useEffect(() => {
    if (isEmpty(fullPermissionInfo)) return;
    setMenuItems((prevState) => {
      const menus = filterMenusWithPermissions(
        fullPermissionInfo,
        prevState,
        FULL_MENU_LIST,
      );
      return menus;
    });
    setAdminMenuItems((prevState) => {
      const menus = filterMenusWithPermissions(
        fullPermissionInfo,
        prevState,
        FULL_MENU_LIST,
      );
      return menus;
    });
  }, [fullPermissionInfo]);

  const firstPermittedTab = useMemo(() => {
    const currentMenu =
      userDetails?.is_superadmin === true ? adminMenuItems : menuItems;
    return currentMenu[0].menuLink;
  }, [menuItems, adminMenuItems, userDetails]);

  const isConsultantUser = useMemo(() => {
    return Boolean(userDetails?.is_consultant_user);
  }, [userDetails]);

  return {
    userDetails,
    menuItems,
    adminMenuItems,
    checkPermissions,
    isConsultantUser,
    firstPermittedTab,
    fullPermissionInfo,
  };
};

const useBlacklistJwtToken = (config) => {
  const { onError } = config;
  const { userDetails } = useAuthentication();

  const token = {
    refresh: userDetails.refresh,
  };
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await authService.logout(token);
      return response;
    },
    onError,
    refetchOnWindowFocus: false,
  });
  return mutation;
};

/* 
  Login > Forgot Password + OTP Login. 
  Fetches presentational company details 
  for public-facing pages. 
*/
export const FETCH_NON_LOGIN_COMPANY_DETAILS =
  'FETCH_NON_LOGIN_COMPANY_DETAILS';
const useGetCompanyDetailsWithoutLogin = (config) => {
  const query = useQuery({
    queryKey: [FETCH_NON_LOGIN_COMPANY_DETAILS],
    queryFn: async () => {
      const response = await authService.fetchCompanyDetailsWithoutLogin();
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

export const FETCH_ALL_COMPANY_NAMES = 'FETCH_ALL_COMPANY_NAMES';
const useGetCompanyList = (config) => {
  const { params = {}, ...rest } = config;

  const query = useQuery({
    queryKey: [FETCH_ALL_COMPANY_NAMES, params?.name__istartswith],
    queryFn: async () => {
      const oResponse = await authService.getCompanyList(params);
      return oResponse?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useSetTablePreferences = (config) => {
  const { onError, ...rest } = config;

  const mutation = useMutation({
    mutationFn: async (userData) => {
      const response = await authService.updateUserPreference(userData);
      return response;
    },
    onError,
    refetchOnWindowFocus: false,
    ...rest,
  });
  return mutation;
};

/* 
  Login > Success. 
  Fetches user preferences settings.  
  Currently stores toggled table columns. 
*/
export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
const useGetUserPreferences = (config) => {
  const query = useQuery({
    queryKey: [GET_USER_PREFERENCES],
    queryFn: async () => {
      const response = await authService.getUserPreferenceData();
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

export {
  menus,
  adminMenus,
  useAuthentication,
  useBlacklistJwtToken,
  useGetUserPreferences,
  useSetTablePreferences,
  useGetCompanyDetailsWithoutLogin,
  useGetCompanyList,
};
