import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Tooltip, message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { useToggle } from 'react-use';
import { TbCurrencyRupee } from 'react-icons/tb';
import { BsQrCode } from 'react-icons/bs';

import iconEdit from 'Assets/icons/iconEdit.svg';
import paramIconButton from 'Assets/icons/paramIcon.svg';
import jobTitleIcon from 'Assets/icons/jobTitle..svg';
import Button from 'Components/CommonComponents/Button/Button';
import iconGraduation from 'Assets/icons/iconGraduation.svg';
import iconJobType from 'Assets/icons/iconJobType.svg';
import iconLocationn from 'Assets/icons/iconLocationn.svg';
import iconWorkBag from 'Assets/icons/iconWorkBag.svg';
import iconAccordion from 'Assets/icons/iconAccordion.svg';
import { toLowercaseAndReplaceSpaces } from 'Utilities/utils';
import daysIcon from '../../../Assets/images/days_img.svg';
import TimeIcon from '../../../Assets/images/time_img.svg';
import dateIcon from '../../../Assets/images/date_img.svg';
import 'Styles/ViewJob.scss';
import {
  DEFAULT_ERROR_MESSAGE,
  JOB_STATUS_LIST,
  PERMISSION_TYPES,
} from 'Utilities/constants';
import ViewParsedText from 'Components/CommonComponents/ViewParsedText';
import Accordion from 'Components/CommonComponents/Accordion/Accordion';
import Empty from 'Components/CommonComponents/Empty/Empty';
import Card from 'Components/CommonComponents/Card/Card';
import { useAuthentication } from 'ServiceHooks/authHooks';
import { useGetParams, useSendUpdatedScore } from 'ServiceHooks/jobHooks';
import QRLink from 'Components/Jobs/Modals/QRLink';

const { Panel } = Collapse;
const panelStyle = {
  marginBottom: 8,
  borderRadius: '6px',
  border: '1px solid #DADADA',
  boxShadow: '0px 4px 4px rgba(194, 194, 194, 0.25)',
  background: 'transparent',
};

const daysType = {
  SUN: 'Sunday',

  MON: 'Monday',

  TUE: 'Tuesday',

  WED: 'Wednesday',

  THU: 'Thursday',

  FRI: 'Friday',

  SAT: 'Saturday',
};

const ViewJob = ({
  className = 'mt-4',
  showDescription = false,
  jobId,

  status,
  error,
  jobInfoData,
}) => {
  const navigate = useNavigate();
  const { fullPermissionInfo } = useAuthentication();
  const [isParamPopup, setParamPopup] = useState(false);
  const [isQrLinkModalOpen, toggleQrLinkModal] = useToggle(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { data: paramData } = useGetParams({
    paramsData: {
      nJobId: jobId,
    },
    enabled: Boolean(jobId),
  });

  const { mutate: sendUpdatedScore, isLoading: isUpdating } =
    useSendUpdatedScore({
      onSuccess: async () => {
        await messageApi.open({
          type: 'success',
          content: 'Score Updated',
        });
        setParamPopup(!isParamPopup);
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: DEFAULT_ERROR_MESSAGE,
        });
      },
    });

  const renderJobDetails = (jobInfoData) => {
    const {
      data: {
        data: {
          title,
          experience,
          job_type,
          description,
          salary_details,
          education_details,
          locations,
          required_skill,
          preferred_skill,
          status,
          posted,
          closure_period,
          number_of_openings,
          department,
          approved_by,
          recruiter,
          interviewers,
          certification,
          notice_period,
          address,
          reference_no,
          total_applicants,
          relevant_experience,
          priority,
          gender,
          internal_hire,
          reference_type,
          visa_required,
          driving_license_required,
          ready_to_relocate,
          languages_preferred,
          nationality,
          job_qr_code,
          count_data: {
            hired_count,
            interview_count,
            onhold_count,
            prescreen_count,
            rejected_count,
            resume_count,
            offered_count,
          },
          job_days,
          job_work_end_date,
          job_work_from_date,
          job_work_timings,
        },
      },
    } = jobInfoData;

    const handelScore = (scoreValue, scoreFieldId) => {
      for (let i = 0; i < paramData.length; i++) {
        if (paramData[i].code === scoreFieldId) {
          paramData[i].score = scoreValue;
        }
      }
    };

    const onSubmitTheScore = () => {
      const params = {
        job_id: jobId,
        resume_weightage: paramData,
      };
      sendUpdatedScore(params);
    };

    const isExistingQrLink = Boolean(job_qr_code);

    return (
      <Card>
        <div className={`ViewJob ${className}`}>
          <Accordion
            className="jobDetail bg-transparent"
            showArrow={false}
            collapsible="icon"
            expandIcon={({ isActive }) => {
              return (
                <div className="customExpandIcon">
                  {isActive ? (
                    <img
                      src={iconAccordion}
                      alt="accordion"
                      className="rotate-180"
                    />
                  ) : (
                    <img src={iconAccordion} alt="accordion" />
                  )}
                </div>
              );
            }}
          >
            {() => {
              const noDataMessage = 'Not Assigned';
              return (
                <Panel
                  header={
                    <div className="jobDetailHeader flex justify-between">
                      <div className="jobDetailHeaderLeft flex items-center">
                        <div className="title">
                          {title || noDataMessage}
                        </div>
                        <div className="status" style={statusStyle}>
                          {status || noDataMessage}
                        </div>
                      </div>
                      <div className="mx-4 flex items-center space-x-2">
                        {isExistingQrLink && (
                          <Tooltip title="QR Link" placement="bottom">
                            <Button
                              type="text"
                              onClick={() => toggleQrLinkModal()}
                            >
                              <BsQrCode
                                size={'1rem'}
                                className="text-cyan-700"
                              />
                            </Button>
                          </Tooltip>
                        )}

                        {fullPermissionInfo[PERMISSION_TYPES.EDIT_JOB] && (
                          <Tooltip title="Edit Job" placement="bottom">
                            <Button
                              type="text"
                              onClick={() =>
                                navigate(`/hr/editJob/${jobId}`)
                              }
                            >
                              <img
                                alt="edit"
                                className="min-w-[1rem]"
                                src={iconEdit}
                              />
                            </Button>
                          </Tooltip>
                        )}

                        {/* job param */}
                        {/* <Button
                          type="text"
                          onClick={() => setParamPopup(!isParamPopup)}
                        >
                          <img alt="edit" src={paramIconButton} />
                        </Button> */}

                        {/* {isParamPopup && (
                          <div
                            className="absolute top-15 right-20 bg-[#fff]  w-[400px] shadow-lg pb-2"
                            style={{ zIndex: 1 }}
                          >
                            <div className="popupBg h-[74px] w-full">
                              <div className="w-full">
                                <i className="deleteIcon float-right p-2 cursor-pointer">
                                  <CloseIcon
                                    className="text-sm"
                                    onClick={() => setParamPopup(!isParamPopup)}
                                  />
                                </i>
                              </div>
                              <div className="w-full pl-8 pt-8">
                                <h2 className="contentIcon text-lg font-mono ">
                                  Parameter
                                </h2>
                                <h2 className="text-sm font-medium">
                                  Set the parameter of each fields
                                </h2>
                              </div>
                            </div>
                            <div className="space-y-2 p-8 pt-3 w-full">
                              <ul>
                                {paramData &&
                                  paramData.map((item, index) => {
                                    return (
                                      <li className="popupBorder" key={index}>
                                        <span className="pr-3 ">
                                          <img
                                            src={item.icon}
                                            alt={item.title}
                                          />
                                        </span>
                                        <span className="pr-3 ">
                                          {item.title}
                                        </span>
                                        <span>
                                          <Slider
                                            min={0}
                                            max={100}
                                            defaultValue={item.score}
                                            marks={{
                                              0: 0,
                                              100: 100,
                                            }}
                                            onChange={(e) => {
                                              handelScore(e, item.code);
                                            }}
                                            style={{
                                              marginBottom: "13px",
                                              fontSize: "10px",
                                            }}
                                            // tooltip={{
                                            //   open: true,
                                            // }}
                                          />
                                        </span>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="p-8 pt-3 w-full">
                              <Button
                                type="primary"
                                className="float-right"
                                onClick={() => onSubmitTheScore()}
                              >
                                {contextHolder}
                                Save
                              </Button>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  }
                  key="1"
                  style={panelStyle}
                  className="jobDetailContent bg-transparent"
                  // extra={genExtra()}
                >
                  <ul className="jobDetailContentSectionOne flex flex-wrap">
                    <li className="w-full lg:w-1/2 mb-4">
                      <div className="flex">
                        <span className="flex items-center mr-4">
                          <img
                            src={iconWorkBag}
                            alt="work"
                            className="mr-3 contentIcon"
                          />
                          {experience || noDataMessage}
                        </span>
                        <span className="mx-4 divider">|</span>
                        <span className="flex items-center">
                          {/* <TbCurrencyRupee className="mr-2 contentIcon" />{' '} */}
                          {salary_details || noDataMessage}
                        </span>
                      </div>
                    </li>
                    <li className="w-full lg:w-1/2 mb-4">
                      <div className="flex">
                        <span className="flex items-center mr-4">
                          <img
                            src={iconGraduation}
                            alt="graduation"
                            className="mr-3 contentIcon"
                          />{' '}
                          {education_details?.join(', ') || noDataMessage}
                        </span>
                      </div>
                    </li>
                    <li className="w-full lg:w-1/2 mb-4">
                      <div className="flex">
                        <span className="flex items-center mr-4">
                          <img
                            src={iconJobType}
                            alt="job type"
                            className="mr-3 contentIcon"
                          />
                          {job_type || noDataMessage}
                        </span>
                      </div>
                    </li>
                    <li className="w-full lg:w-1/2 mb-4">
                      <div className="flex">
                        <span className="flex items-center mr-4 text-left">
                          {/* <TfiLocationPin className="mr-3 contentIcon" />  */}
                          <img
                            src={iconLocationn}
                            alt="job type"
                            className="mr-3 contentIcon"
                          />
                          {locations?.join(', ') || noDataMessage}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div className="contentDivider" />
                  <div className="jobDetailContentSectionTwo">
                    <ul>
                      <li className="flex mb-4">
                        {!isEmpty(department) && (
                          <>
                            <p>
                              Department :{' '}
                              <span>{department || noDataMessage}</span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(approved_by) && (
                          <p>
                            Approved BY :{' '}
                            <span>
                              {approved_by?.join(', ') || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>
                      <li className="flex mb-4">
                        {!isEmpty(recruiter) && (
                          <>
                            <p>
                              Recruiter :{' '}
                              <span>
                                {' '}
                                {recruiter?.join(', ') || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}
                        {!isEmpty(interviewers) && (
                          <p>
                            Interviewers :{' '}
                            <span>
                              {interviewers?.join(', ') || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>
                    </ul>
                  </div>

                  <div className="jobDetailsContentSectionThree flex">
                    <p>Job Published : {posted || noDataMessage}</p>
                    <span className="divider mx-4"> | </span>
                    <p>
                      Job Closing Date: {closure_period || noDataMessage}
                    </p>
                    <span className="divider mx-4"> | </span>
                    <p>
                      No of Vacancies :{' '}
                      {number_of_openings || noDataMessage}
                    </p>
                  </div>

                  <div className="contentDivider" />

                  {/* other info  */}

                  <div className="jobDetailContentSectionTwo">
                    <ul>
                      <li className="flex mb-4">
                        {relevant_experience && (
                          <>
                            <p>
                              Relevant Experience :{' '}
                              <span className="font-semibold">
                                {relevant_experience || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {notice_period && (
                          <>
                            <p>
                              Notice Period :{' '}
                              <span className="font-semibold">
                                {notice_period + ' days' || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(priority) && (
                          <p>
                            Job Priority :{' '}
                            <span className="font-semibold">
                              {priority || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>

                      <li className="flex mb-4">
                        {!isEmpty(gender) && (
                          <>
                            <p>
                              Gender Preferred:{' '}
                              <span className="font-semibold">
                                {' '}
                                {gender || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(internal_hire) && (
                          <>
                            <p>
                              Internal Hire :{' '}
                              <span className="font-semibold">
                                {internal_hire || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(reference_type) && (
                          <p>
                            Reference Type :{' '}
                            <span className="font-semibold">
                              {reference_type || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>

                      <li className="flex mb-4">
                        {!isEmpty(ready_to_relocate) && (
                          <>
                            <p>
                              Visa Required:{' '}
                              <span className="font-semibold">
                                {' '}
                                {visa_required || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(ready_to_relocate) && (
                          <>
                            <p>
                              Driving License Required:{' '}
                              <span className="font-semibold">
                                {driving_license_required || noDataMessage}
                              </span>
                            </p>
                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(ready_to_relocate) && (
                          <p>
                            Ready to Relocate :{' '}
                            <span className="font-semibold">
                              {ready_to_relocate || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>

                      <li className="flex mb-4">
                        {!isEmpty(languages_preferred) && (
                          <>
                            <p>
                              Languages Preferred:{' '}
                              <span className="font-semibold">
                                {' '}
                                {languages_preferred?.join(', ') ||
                                  noDataMessage}
                              </span>
                            </p>

                            <p className="mx-4 divider">|</p>
                          </>
                        )}

                        {!isEmpty(nationality) && (
                          <p>
                            Who Can Apply:{' '}
                            <span className="font-semibold">
                              {nationality?.join(', ') || noDataMessage}
                            </span>
                          </p>
                        )}
                      </li>

                      {!isEmpty(certification) && (
                        <li className="flex mb-4">
                          <p>
                            Certification Required :{' '}
                            <span className="font-semibold">
                              {' '}
                              {certification?.join(', ') || noDataMessage}
                            </span>
                          </p>
                        </li>
                      )}

                      {!isEmpty(address) && (
                        <li className="flex mb-4">
                          <p>
                            Address:{' '}
                            <span className="font-semibold">
                              {' '}
                              {address || noDataMessage}
                            </span>
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>

                  {/* skills section */}

                  <div
                    className="mt-8"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '50% 50%',
                    }}
                  >
                    {required_skill ? (
                      <div>
                        <p className="font-semibold">Required Skill</p>
                        <div className="flex flex-wrap gap-2 mt-2">
                          {required_skill.map((skill) => {
                            return <p className="skillsText">{skill}</p>;
                          })}
                        </div>
                      </div>
                    ) : null}

                    {preferred_skill ? (
                      <div>
                        <p className="font-semibold">Preferred Skill</p>
                        <div className="flex flex-wrap gap-2 mt-2">
                          {preferred_skill.map((skill) => {
                            return <p className="skillsText">{skill}</p>;
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="jobDetailsContentSectionFour jobDetailsContentSummary">
                    <div className="jobDetailBoxes flex flex-wrap -mx-2 mb-6">
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent resume bg-[#D6F3FF] dark:bg-transparent">
                          <p className="boxHeading">
                            Total <br /> Resumes
                          </p>
                          <div className="boxNumber">{resume_count}</div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent preScreen bg-[#F8FFEC] dark:bg-transparent">
                          <p className="boxHeading">
                            Shortlisted <br /> for Prescreening
                          </p>
                          <div className="boxNumber">
                            {prescreen_count}
                          </div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent pipeLine bg-[#F3F3F3] dark:bg-transparent">
                          <p className="boxHeading">
                            Shortlisted <br /> In Pipeline
                          </p>
                          <div className="boxNumber">
                            {interview_count}
                          </div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent onHold bg-[#FFF6DE] dark:bg-transparent">
                          <p className="boxHeading">
                            Total <br /> On Hold
                          </p>
                          <div className="boxNumber">{onhold_count}</div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent rejected bg-[#FFE7E7] dark:bg-transparent">
                          <p className="boxHeading">
                            Total <br /> Rejected
                          </p>
                          <div className="boxNumber">{rejected_count}</div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                      <div className="box w-full md:w-1/6 mb-6 px-2 md:mb-0 ">
                        <div className="boxContent offered bg-[#E6FFBB] dark:bg-transparent">
                          <p className="boxHeading">
                            Total <br /> Offered
                          </p>
                          <div className="boxNumber">{offered_count}</div>
                          <div className="boxDivider" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              );
            }}
          </Accordion>
          {job_work_timings && (
            <div className="jobscheduleContentSectionThree">
              <p className="font-semibold">Job Schedule</p>
              <div className="flex flex-wrap gap-5  mt-5">
                <div className="box">
                  <div>
                    <img src={daysIcon} />
                  </div>
                  <p>Days</p>
                  <div className="mt-2 flex flex-wrap gap-2 ">
                    {job_days.map((days) => {
                      return (
                        <p className="font_value_colour">
                          {daysType[days]},
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="box">
                  <div>
                    <img src={dateIcon} />
                  </div>
                  <p>Date</p>
                  <div className="mt-2">
                    <p className="font_value_colour">
                      {' '}
                      {job_work_from_date} to {job_work_end_date}{' '}
                    </p>
                  </div>
                </div>
                <div className="box">
                  <div>
                    <img src={TimeIcon} />
                  </div>
                  <p>Time</p>
                  <div className="mt-2">
                    {job_work_timings.map((time, index) => {
                      return (
                        <p className="font_value_colour">
                          <span className="font-semibold">
                            {' '}
                            {index + 1}.{' '}
                          </span>
                          {time.work_from_time} to {time.work_end_time}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDescription && (
            <div className="jobDescription bg-secondaryBgMain">
              <ViewParsedText
                content={description}
                className=" dangerousHtml"
              />
            </div>
          )}
        </div>

        <Modal
          width={500}
          title={null}
          footer={null}
          destroyOnClose
          className="qrLinkModal"
          open={isQrLinkModalOpen}
          onCancel={toggleQrLinkModal}
        >
          <QRLink title={title} link={job_qr_code} />
        </Modal>
      </Card>
    );
  };

  const statusStyle = useMemo(() => {
    const defaultStyle = { backgroundColor: 'red' };
    if (!jobInfoData?.data?.data?.status) return defaultStyle;
    const status = jobInfoData?.data?.data?.status;
    const statusReplaced = toLowercaseAndReplaceSpaces(status);
    let style = defaultStyle;
    if (
      statusReplaced ===
      toLowercaseAndReplaceSpaces(JOB_STATUS_LIST.APPROVAL_PENDING.label)
    ) {
      style = { backgroundColor: JOB_STATUS_LIST.APPROVAL_PENDING.color };
    } else if (
      statusReplaced ===
      toLowercaseAndReplaceSpaces(JOB_STATUS_LIST.OVERDUE.label)
    ) {
      style = { backgroundColor: JOB_STATUS_LIST.OVERDUE.color };
    } else if (
      statusReplaced ===
      toLowercaseAndReplaceSpaces(JOB_STATUS_LIST.DRAFT.label)
    ) {
      style = { backgroundColor: JOB_STATUS_LIST.DRAFT.color };
    } else if (
      statusReplaced ===
      toLowercaseAndReplaceSpaces(JOB_STATUS_LIST.CLOSED.label)
    ) {
      style = { backgroundColor: JOB_STATUS_LIST.CLOSED.color };
    } else if (
      statusReplaced ===
      toLowercaseAndReplaceSpaces(JOB_STATUS_LIST.PUBLISHED_JOB.label)
    ) {
      style = { backgroundColor: JOB_STATUS_LIST.PUBLISHED_JOB.color };
    }
    return style;
  }, [jobInfoData]);

  return status === 'loading' ? (
    <span>Loading...</span>
  ) : status === 'error' ? (
    <div className="mt-4">
      <Empty
        isError
        description={
          <span>
            {' '}
            {error?.response?.data?.error_info?.detail ||
              DEFAULT_ERROR_MESSAGE}
          </span>
        }
      />
    </div>
  ) : (
    renderJobDetails(jobInfoData)
  );
};

export default ViewJob;
