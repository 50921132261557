import { useMutation, useQuery } from '@tanstack/react-query';
import companyRegistration from 'Services/companyRegistration';

const useInterviewettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['interviewSettings', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getInterviewSettings(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateInterviewSettings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, refrenceNumberFormat, comapnyId } = params;
      const details = await companyRegistration.addInterviewSettings(
        data,
        refrenceNumberFormat,
        comapnyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useJobSettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['jobSettings', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getJobSettings(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

const useCreateJobSettings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, refrenceFormat, comapnyId } = params;
      const details = await companyRegistration.addJobSettings(
        data,
        refrenceFormat,
        comapnyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useReviewSettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['reviewSettings', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getReviewSettings(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateReviewttings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, refrenceNumberFormat, companyId } = params;
      const details = await companyRegistration.addReviewSettings(
        data,
        refrenceNumberFormat,
        companyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useCreateWorkingTime = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, companyId } = params;
      const details = await companyRegistration.addCompanyWorkingTime(
        data,
        companyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useWorkingTime = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['workingTYpe', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getWorkingTime(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateSocialMediaSettings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, comapnyId } = params;
      const details = await companyRegistration.addSocialMediaSettings(
        data,
        comapnyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useGetSocialMediaSettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['mediaType', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getSocialMediaSettings(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateCompanyRegistration = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, phoneCode, companyCode } = params;
      const details = await companyRegistration.createRegistration(
        data,
        phoneCode,
        companyCode,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useCreateQuestionnireSettings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, refrenceFormat, comapnyId } = params;
      const details = await companyRegistration.addQuestionnaireSettings(
        data,
        refrenceFormat,
        comapnyId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useQuestionnaireSettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['questinnaire', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getQuestionnaireSettings(
        paramsData?.companyId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

// Public API
export const FETCH_ALL_TIMEZONES = 'FETCH_ALL_TIMEZONES';
export const useFetchAllTimezones = (config) => {
  const query = useQuery({
    queryKey: [FETCH_ALL_TIMEZONES],
    queryFn: async () => {
      const details = await companyRegistration.fetchAllTimezones();

      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

const useCreateExpirySettings = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data } = params;
      const details = await companyRegistration.addExpirySettings(
        data
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};


const useGetExpirySettings = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['expiry_settings', paramsData?.companyId],
    queryFn: async () => {
      const details = await companyRegistration.getExpirySettings(paramsData?.companyId);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};


export {
  useCreateInterviewSettings,
  useCreateJobSettings,
  useJobSettings,
  useInterviewettings,
  useCreateReviewttings,
  useReviewSettings,
  useCreateWorkingTime,
  useWorkingTime,
  useCreateSocialMediaSettings,
  useGetSocialMediaSettings,
  useCreateCompanyRegistration,
  useCreateQuestionnireSettings,
  useQuestionnaireSettings,
  useCreateExpirySettings,
  useGetExpirySettings
};
