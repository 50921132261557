export const Preference = () => {
  const arrPreferences = {
    JOBPREFERENCE: {
      ACTIVE: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,

        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      PENDING: {
        Status: true,
        ClosurePeriod: false,
        Created: true,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,

        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      QuestionnaireJob: {
        id: true,
        JobName: true,
        levels: true,
      },
      DRAFT: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,

        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      CLOSED: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,
        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      ALL: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,
        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      ONBOARDING_IN_PROGRESS: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,
        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      HOLD: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,
        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },
      OVERDUE: {
        Status: false,
        ClosurePeriod: true,
        Created: false,
        id: true,
        JobName: true,
        TotalApplication: true,
        NoOfOpenings: true,
        Certification: false,
        CreatedBy: false,
        CurrencyCode: false,
        PaymentType: false,
        Department: false,
        EducationDetails: false,
        Experience: true,
        JobType: true,
        Locations: false,
        MinimumCtc: true,
        MaximumCtc: true,
        MinimumExperience: false,
        MaximumExperience: false,
        Posted: true,
        PreferredSkill: false,
        Priority: true,
        RequiredSkill: false,
        // newe columns
        gender: false,
        notice_period: false,
        visa_required: false,
        internal_hire: false,
        reference_type: false,
        ready_to_relocate: false,
        driving_license_required: false,
        languages_preferred: false,
        nationality: false,
        recruiters: false,
        reviewers: false,
        clients: false,
        consultants: false,
        interviews: false,
      },

      FINALIZED: {
        name: true,
        firstname: true,
        Lastname: true,
        email: true,
        mobile_number: true,
        resume_score: true,
        current_level: true,
        current_level_status: true,
      },
      CONFLICTED: {
        name: true,
        firstname: true,
        Lastname: true,
        email: true,
        mobile_number: true,
        resume_score: true,
        current_level: true,
        current_level_status: true,
      },
      SCHEDULES: {
        candidate_name: true,
        candidate_email: true,
        candidate_phone: true,
        resume_score: true,
        interview_date: true,
        interview_time: true,
        interview_status: true,
      },
      SCORECARD_INTERVIEW: {
        name: true,
        email: true,
        mobile_number: true,
        interview_scheduled_date: true,
        interview_attended_date: true,
        interviewers: true,
        status: true,
      },
      SCORECARD_PRESCREEN: {
        name: true,
        email: true,
        mobile_number: true,
        scheduled_date: true,
        attended_date: true,
        status: true,
      },
      OFFERED: {
        name: true,
        email: true,
        mobile_number: true,
        status: true,
      },
      REJECTED: {
        name: true,
        email: true,
        mobile_number: true,
      },
    },
    CANDIDATEPREFERENCE: {
      FINALIZED: {
        name: true,
        firstname: true,
        Lastname: true,
        email: true,
        mobile_number: true,
        resume_score: true,
      },
      CONFLICTED: {
        name: true,
        firstname: true,
        Lastname: true,
        email: true,
        mobile_number: true,
        resume_score: true,
      },
      PIPELINE: {
        fullname: true,
        first_name: true,
        last_name: true,
        email: true,
        mobile_number: true,
        job: true,
        level: true,
        level_status: true,
      },
      OFFERED: {
        fullname: true,
        first_name: true,
        last_name: true,
        email: true,
        mobile_number: true,
        job: true,
        level: true,
        level_status: true,
      },
      REJECTED: {
        fullname: true,
        first_name: true,
        last_name: true,
        email: true,
        mobile_number: true,
        job: true,
        level: true,
        level_status: true,
      },
    },
    QUESTIONBANKPREFERENCE: {
      QUESTIONLIST: {
        job_code: true,
        job_title: true,
        interview_level: true,
        major_skill: true,
        minor_skill: true,
        alloted_questions: true,
        total_created_questions: true,
        total_essay_questions: false,
        total_single_choice_questions: false,
        total_mcq_questions: false,
        source: true,
        created_date_formatted: false,
        updated_date_formatted: false,
      },
    },
    INTERVIEWPREFERENCE: {
      UPCOMING: {
        // Name: true,
        Firstname: true,
        Lastname: true,
        Interviewer: true,
        InterviewDate: true,
        InterviewTime: true,
        InterviewType: true,
        job: true,
      },
      REVIEW_PENDING: {
        // Name: true,
        Firstname: true,
        Lastname: true,
        Interviewer: true,
        InterviewDate: true,
        InterviewTime: true,
        InterviewType: true,
        job: true,
        Status: true,
      },
      REVIEW_COMPLETED: {
        // Name: true,
        Firstname: true,
        Lastname: true,
        Interviewer: true,
        InterviewDate: true,
        InterviewTime: true,
        InterviewType: true,
        job: true,
      },
      CANCELLED: {
        // Name: true,
        Firstname: true,
        Lastname: true,
        Interviewer: true,
        InterviewDate: true,
        InterviewTime: true,
        InterviewType: true,
        job: true,
      },
      ALL_INTERVIEWS: {
        // Name: true,
        Firstname: true,
        Lastname: true,
        Interviewer: true,
        InterviewDate: true,
        InterviewTime: true,
        InterviewType: true,
        job: true,
      },
    },
  };
  return arrPreferences;
};

