import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useToggle } from 'react-use';
import { Image, Modal, message } from 'antd';
import { FiEdit, FiTrash } from 'react-icons/fi';

import ViewParsedText from 'Components/CommonComponents/ViewParsedText';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import Button from 'Components/CommonComponents/Button/Button';
import CandidateExperienceImg from 'Assets/images/candidateExperience.png';
import CandidateProjectsImg from 'Assets/images/candidateProjects.png';
import {
  useDeleteCandidateProject,
  useDeleteCandidateExperience,
} from 'ServiceHooks/candidatesHooks';
import { useCandidateInfo } from 'ServiceHooks/candidatesHooks';
import ExperienceForm from './ExperienceForm';
import ProjectForm from './ProjectForm';

export const FORM_TYPES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export default function ExperienceTab(props) {
  const { experiences, projects } = props;
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const [isAddExperienceModalOpen, toggleAddExperienceModal] =
    useToggle(false);
  const [isAddProjectModalOpen, toggleAddProjectModal] = useToggle(false);
  const [isEditExperienceModalOpen, toggleEditExperienceModal] =
    useToggle(false);
  const [isEditProjectModalOpen, toggleEditProjectModal] =
    useToggle(false);
  const [currentEditingProject, setCurrentEditingProject] = useState();
  const [currentEditingExperience, setCurrentEditingExperience] =
    useState();

  const { refetch: refetchCandidates } = useCandidateInfo({
    paramsData: {
      candidateId: params?.candidateId,
    },
    enabled: false,
  });

  const { mutate: deleteProject } = useDeleteCandidateProject({
    onSuccess: refetchCandidates,
    onError: async (error) => {
      await messageApi.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
      console.error('Project Error: ', error);
    },
  });

  const { mutate: deleteExperience } = useDeleteCandidateExperience({
    onSuccess: refetchCandidates,
    onError: async (error) => {
      await messageApi.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
      console.error('Experience Error: ', error);
    },
  });

  return (
    <>
      <div className="experienceTab flex flex-row gap-8">
        <div className="border rounded-md p-spacing3 w-1/2">
          <div className="flex flex-row flex-wrap justify-between items-center">
            <div className="text-lg font-medium">Experience</div>
            <Button isSecondary onClick={toggleAddExperienceModal}>
              Add Experience
            </Button>
          </div>
          <section className="experienceSection max-h-[30rem] overflow-x-hidden overflow-y-auto customScrollBar my-4">
            {!isEmpty(experiences) ? (
              <div className="flex flex-wrap -mx-2">
                {experiences?.map((experienceItem, index) => {
                  return (
                    <div
                      className="w-full px-2 mb-spacing4 flex flex-row justify-between"
                      key={`experience-item-${index}`}
                    >
                      <div className="flex flex-row items-baseline gap-4">
                        <div className="rounded-full bg-primaryMain h-2 w-2"></div>
                        <div>
                          <h1 className="font-medium text-lg">
                            {experienceItem?.designation}
                          </h1>
                          <p className="font-medium text-base">
                            {experienceItem?.company}
                          </p>
                          <p className="text-[#848484] text-sm">
                            {experienceItem?.worked_from} -{' '}
                            {experienceItem?.worked_till}
                          </p>
                          <p className="text=[#535353] text-sm">
                            <ViewParsedText
                              content={experienceItem?.responsibilities}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row gap-4">
                        <FiEdit
                          className="text-primaryMain cursor-pointer"
                          size={'1.25rem'}
                          onClick={() => {
                            setCurrentEditingExperience(experienceItem);
                            toggleEditExperienceModal();
                          }}
                        />
                        <FiTrash
                          className="text-primaryMain cursor-pointer"
                          size={'1.25rem'}
                          onClick={() =>
                            deleteExperience({
                              data: {
                                candidate: params?.candidateId,
                              },
                              experienceId: experienceItem.id,
                            })
                          }
                        />
                        {contextHolder}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <Image
                  height={128}
                  preview={false}
                  alt="no-experience"
                  src={CandidateExperienceImg}
                />
                <div className="text=[#656565] text-lg font-light capitalize pb-28">
                  Not any Experience Added yet!
                </div>
              </div>
            )}
          </section>
        </div>
        <div className="border rounded-md p-spacing3 w-1/2">
          <div className="flex flex-row justify-between items-center">
            <div className="text-lg font-medium">Projects</div>
            <Button isSecondary onClick={toggleAddProjectModal}>
              Add Project
            </Button>
          </div>
          <section className="projectsSection max-h-[30rem] overflow-x-hidden overflow-y-auto customScrollBar my-4">
            {!isEmpty(projects) ? (
              <div className="flex flex-wrap -mx-2">
                {projects?.map((projectItem, index) => {
                  return (
                    <div
                      className="w-full px-2 mb-spacing4 flex flex-row justify-between"
                      key={`project-${index}`}
                    >
                      <div className="flex flex-row items-baseline gap-4">
                        <div className="rounded-full bg-primaryMain h-2 w-2"></div>
                        <div>
                          <h1 className="font-medium text-lg">
                            {projectItem?.designation}
                          </h1>
                          <p className="font-medium text-base">
                            {projectItem?.company}
                          </p>
                          <p className="text-[#848484] text-sm">
                            {projectItem?.worked_from} -{' '}
                            {projectItem?.worked_till}
                          </p>
                          <p className="text=[#535353] text-sm">
                            {projectItem?.project}
                          </p>
                          <p className="text=[#535353] text-sm">
                            <ViewParsedText
                              content={projectItem?.responsibilities}
                            />
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row gap-4">
                        <FiEdit
                          className="text-primaryMain cursor-pointer"
                          size={'1.25rem'}
                          onClick={() => {
                            setCurrentEditingProject(projectItem);
                            toggleEditProjectModal();
                          }}
                        />
                        <FiTrash
                          className="text-primaryMain cursor-pointer"
                          size={'1.25rem'}
                          onClick={() =>
                            deleteProject({
                              data: {
                                candidate: params?.candidateId,
                              },
                              projectId: projectItem.id,
                            })
                          }
                        />
                        {contextHolder}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <Image
                  height={128}
                  preview={false}
                  alt="no-experience"
                  src={CandidateProjectsImg}
                />
                <div className="text=[#656565] text-lg font-light capitalize pb-28">
                  Not any project Added yet!
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
      {/* -----------------------  Modal for Add Project starts ----------------------- */}
      <Modal
        className="addProjectModal"
        width={700}
        open={isAddProjectModalOpen}
        title={'Add Project'}
        onCancel={toggleAddProjectModal}
        footer={null}
        destroyOnClose
      >
        <ProjectForm mode={FORM_TYPES.ADD} close={toggleAddProjectModal} />
      </Modal>
      {/* -----------------------  Modal for Add Project ends ----------------------- */}

      {/* -----------------------  Modal for Edit Project starts ----------------------- */}
      <Modal
        className="editProjectModal"
        width={700}
        open={isEditProjectModalOpen}
        title={'Edit Project'}
        onCancel={toggleEditProjectModal}
        footer={null}
        destroyOnClose
      >
        <ProjectForm
          mode={FORM_TYPES.EDIT}
          close={toggleEditProjectModal}
          details={currentEditingProject}
        />
      </Modal>
      {/* -----------------------  Modal for Edit Project ends ----------------------- */}

      {/* -----------------------  Modal for Add Experience starts ----------------------- */}
      <Modal
        className="addExperienceModal"
        width={700}
        open={isAddExperienceModalOpen}
        title={'Add Experience'}
        onCancel={toggleAddExperienceModal}
        footer={null}
        destroyOnClose
      >
        <ExperienceForm
          mode={FORM_TYPES.ADD}
          close={toggleAddExperienceModal}
        />
      </Modal>
      {/* -----------------------  Modal for Add Experience ends ----------------------- */}

      {/* -----------------------  Modal for Edit Experience starts ----------------------- */}
      <Modal
        className="editExperienceModal"
        width={700}
        open={isEditExperienceModalOpen}
        title={'Edit Experience'}
        onCancel={toggleEditExperienceModal}
        footer={null}
        destroyOnClose
      >
        <ExperienceForm
          mode={FORM_TYPES.EDIT}
          close={toggleEditExperienceModal}
          details={currentEditingExperience}
        />
      </Modal>
      {/* -----------------------  Modal for Edit Experience ends ----------------------- */}
    </>
  );
}
