import axios from 'axios';

import { downloadFile } from 'Utilities/utils';
import Button from 'Components/CommonComponents/Button/Button';

export default function QRLink({ title, link }) {
  return (
    <div className="mt-12 mb-20 flex flex-col justify-center items-center">
      <h1 className="mb-11 text-black text-xl font-normal max-w-xs text-center">
        {title}
      </h1>
      <div>
        <img src={link} />
      </div>

      <Button
        type={'primary'}
        className="mt-14"
        onClick={async () => await downloadFile(link, title)}
      >
        Download
      </Button>
    </div>
  );
}
