import { lazy } from 'react';

const Settings = lazy(() => import('./pages/Hr/Settings/Settings'));
const JobDetails = lazy(() => import('./pages/Hr/Jobs/JobDetails'));
const Jobs = lazy(() => import('./pages/Hr/Jobs/Jobs'));
const Candidates = lazy(
  () => import('./pages/Hr/Candidates/Candidates.js'),
);
const CreateCandidate = lazy(
  () => import('./pages/Hr/Candidates/CreateCandidate'),
);
const CandidatesInfoPage = lazy(
  () => import('./pages/Hr/Candidates/CandidatesInfoPage'),
);
const QuestionBank = lazy(
  () => import('./pages/Hr/QuestionBanks/QuestionBanks'),
);
const CreateQuestionnairePage = lazy(
  () => import('./pages/Hr/QuestionBanks/CreateQuestionnairePage'),
);
const ConfigureQuestionnairePage = lazy(
  () => import('./pages/Hr/QuestionBanks/ConfigureQuestionnairePage'),
);
const ViewQuestionnairePage = lazy(
  () => import('./pages/Hr/QuestionBanks/ViewQuestionnairePage'),
);
const ViewQuestionBankPage = lazy(
  () => import('./pages/Hr/QuestionBanks/ViewQuestionBankPage'),
);
const QuestionBanks = lazy(
  () => import('./pages/Hr/QuestionBanks/QuestionBanks'),
);
const Interviewer = lazy(
  () => import('./pages/Hr/Interviewer/Interviewer'),
);
const AiInterviewReview = lazy(
  () =>
    import('Components/Interview/AIInterviewFeedback/AiInterviewReview'),
);
const BigFiveReview = lazy(
  () => import('pages/Hr/Interviewer/BigFiveReview'),
);
const JobInterviewReview = lazy(
  () => import('./pages/Hr/Jobs/JobInterviewReview'),
);
const AiInterviewPreview = lazy(
  () => import('Components/Interview/AIInterviewFeedback/PreviewPage'),
);
const PageNotFoundInfo = lazy(
  () => import('Components/CommonComponents/PageNotFound/Status404'),
);
const InActiveLinkExpiredInfo = lazy(
  () => import('Components/CommonComponents/PageNotFound/InActiveLink'),
);
const SettingsJobFeilds = lazy(
  () => import('./Components/Settings/FormSettings.js'),
);
const SettingsDepartments = lazy(
  () => import('./Components/Settings/Departments.js'),
);
const SettingsRoles = lazy(
  () => import('./Components/Settings/ProfileandRoles.js'),
);
const SettingsTeams = lazy(
  () => import('./Components/Settings/TeamMembers.js'),
);
const SettingsLocation = lazy(
  () => import('./Components/Settings/Location.js'),
);
const SettingsSkills = lazy(
  () => import('./Components/Settings/Skills.js'),
);
const SettingsPrescreen = lazy(
  () => import('./Components/Settings/PrescreenParameter.js'),
);
const SettingsEvaluationParameter = lazy(
  () => import('./Components/Settings/EvaluationParameter.js'),
);
const SettingsEducation = lazy(
  () => import('./Components/Settings/EducationDetails.js'),
);
const SettingsQuestinnaire = lazy(
  () => import('./Components/Settings/EvaluationQuestionnaire.js'),
);
const SettingHiring = lazy(
  () => import('./Components/Settings/HiringType.js'),
);
const SettingsCertification = lazy(
  () => import('./Components/Settings/Certificate.js'),
);
const CommunicationRegistration = lazy(
  () =>
    import('./Components/RegistrationManagement/CreateCommunication.js'),
);
const Dashboard = lazy(() => import('./pages/Hr/Dashboard/Dashboard.js'));
const CandidateSlotSelection = lazy(
  () => import('Components/InterviewPipeline/CandidateSlotSelection.js'),
);
const CreateLicense = lazy(
  () => import('./pages/Hr/licensingManagement/license.js'),
);
const CreateLicenseForm = lazy(
  () => import('./Components/LicenseManagement/AddLicense.js'),
);
const PersonalityReport = lazy(
  () => import('./pages/Hr/Interviewer/PersonalityReport'),
);
const PersonalityForm = lazy(
  () => import('./Components/PersonalityTest/Personality.js'),
);
const PersonalityTest = lazy(
  () => import('./Components/PersonalityTest/PersonalityForm.js'),
);
const BigFiveFeedBackForm = lazy(
  () => import('./pages/Hr/Interviewer/Big5FeedBackForm.js'),
);
const CandidateScreeningReport = lazy(
  () => import('./pages/Hr/Candidates/Reports/ScreeningReport/index'),
);
const CandidateSourcingReport = lazy(
  () => import('./pages/Hr/Candidates/Reports/SourcingReport/index'),
);
const CommunicationReport = lazy(
  () =>
    import('./pages/Hr/Communication/Reports/CommunicationReport/index'),
);
const Consultancy = lazy(
  () => import('./Components/Settings/Consultancy.js'),
);
const ConsultancyUser = lazy(
  () => import('./Components/Settings/ConsultancyUser.js'),
);
const ConsultancyClient = lazy(
  () => import('./Components/Settings/ConsultancyClients.js'),
);
const ConsultancyClientUser = lazy(
  () => import('./Components/Settings/ConsultancyClientUser.js'),
);
const EnquiryList = lazy(
  () => import('./Components/Enquiry/EnquiryList.js'),
);
const EnquiryDetails = lazy(
  () => import('./Components/Enquiry/EnquiryDetails.js'),
);
const CreateEnquiryRegistration = lazy(
  () => import('./Components/Enquiry/CreateEnquiryRegistration.js'),
);
const QuestionView = lazy(
  () => import('./Components/QuestionBanks/Questions.js'),
);
const CreateQuestionnaireFromQuestions = lazy(
  () =>
    import(
      './Components/QuestionBanks/CreateQuestionnaireFromQuestions.js'
    ),
);
const ViewCreatedQuestionFromQuestionBank = lazy(
  () =>
    import(
      './Components/QuestionBanks/ViewCreatedQuestionQuestionBank.js'
    ),
);
const SettingsDocumentType = lazy(
  () => import('./Components/Settings/DocumentType.js'),
);
const SettingsGroup = lazy(
  () => import('./Components/ExtaSettings/ContactGroupList.js'),
);
const SettingsUserList = lazy(
  () => import('./Components/ExtaSettings/UserList.js'),
);
const AvatarSettings = lazy(
  () => import('./Components/Settings/AvatarSettings.js'),
);

const routes = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SETTINGS: '/hr/settings',
  VIEW_COMPANY_INFORMATION: '/hr/company/info',
  JOB_DETAILS: '/hr/job-details',
  JOBS: '/hr/jobs',
  CANDIDATES: '/hr/candidates',
  CREATE_NEW_CANDIDATE_FOR_JOB: '/hr/job-details/:jobId/new-candidate',
  CREATE_NEW_CANDIDATE: '/hr/candidates/new',
  CANDIDATES_INFO: '/hr/candidate-info',
  CANDIDATE_ADD_SLOT: '/interview/book-slot/:token',
  ADD_JOB: '/hr/addJob',
  QUESTION_BANK: '/hr/question-bank',
  CREATE_QUESTIONNAIRE_PAGE: '/hr/create-questionnaire',
  EDIT_QUESTIONNAIRE_PAGE: '/hr/edit-questionnaire',
  CREATE_EXCEL_QUESTIONNAIRE_PAGE: '/hr/create-questionnaire/file',
  CONFIGURE_QUESTIONNAIRE_PAGE: '/hr/configure-questionnaire',
  VIEW_QUESTIONNAIRE: '/hr/view-questionnaire',
  VIEW_QUESTION_BANK: '/hr/view-question-bank',
  INTERVIEWER: '/hr/interviewer',
  AI_INTERVIEW_REVIEW: '/hr/ai-interview-review',
  JOB_INTERVIWER_REVIEW: '/hr/job-details/review',
  AI_PREVIEW_REVIEW: '/hr/ai-interview-preview',
  BIG_FIVE_REVIEW: 'hr/interviewer/big-five-review',
  PAGE_NOT_FOUND: '/hr/link-expired',
  IN_ACTIVE_LINK: '/hr/inActive-linked',
  INTERVIEW_PIPELINE: '/hr/interview-pipeline',
  SETTINGS_JOBFEILDS: '/hr/settings-jobFields',
  SETTINGS_DEPARTMENTS: '/hr/settings-Departments',
  SETTINGS_ROLES: '/hr/settings-Roles',
  SETTINGS_TEAMS: '/hr/settings-Teams',
  SETTINGS_LOCATION: '/hr/settings-Location',
  SETTINGS_SKILLS: '/hr/settings-Skills',
  SETTINGS_PRESCREEN: '/hr/settings-Prescreen',
  SETTINGS_EVALUATION: '/hr/settings-EvaluationParameter',
  SETTINGS_EDUCATION: '/hr/settings-Education',
  SETTINGS_QUESTIONNAIRE: '/hr/settings-EvaluationQuestionnaire',
  SETTINGS_HIRING: '/hr/settings-Hiring',
  SETTINGS_CERTIFICATION: '/hr/settings-Certification',
  COMMUNICATION: '/hr/communication',
  REGISTRATION: '/hr/registration',
  REGISTRATION_COMMUNICATION: '/hr/registration-communication',
  DASHBOARD: '/hr/dashboard',
  LICENSE: '/hr/license',
  CREATE_LICENSE: '/hr/license/create',
  PERSONALITY_RESULT: '/interview/personality/result',
  PERSONALITY_TEST: '/interview/personality',
  PERSONALITY_FORM: '/interview/personality/start',
  BIG_FIVE_FEEDBACKFORM: '/hr/interviewer/big-five/feedbackForm',
  SETTINGS_CONSULTANCY: '/hr/settings-consultancy',
  SETTINGS_CONSULTANCY_USER: '/hr/settings-consultancy-user',
  SETTINGS_CONSULTANCY_CLIENT: '/hr/settings-consultancy-client',
  SETTINGS_CONSULTANCY_CLIENT_USER: '/hr/settings-consultancy-client-user',
  COMMUNICATION_REPORT: '/hr/reports/communication',
  CANDIDATE_SOURCING_REPORT: '/hr/reports/candidates/sourcing',
  CANDIDATE_SCREENING_REPORT: '/hr/reports/candidates/screening',
  JOBS_TIME_TO_HIRE_REPORT: '/hr/reports/jobs/time-to-hire/',
  ENQUIRY: '/hr/enquiry',
  ENQUIRY_DETAILS: '/hr/enquiry-details',
  ENQUIRY_REGISTRATION: '/hr/enquiry-company-registration',
  QUESTIONS: '/hr/questions',
  CREATE_QUESTIONNAIRE_FROM_QUESTIONS:
    '/hr/create-questionnaire-questions',
  VIEW_CREATED_QUESTIONS: '/hr/view-questions',
  SETTINGS_DOCUMENT: '/hr/settings-document-type',
  SETTINGS_GROUP: '/hr/settings-group',
  SETTINGS_USER_LIST: '/hr/settings-userlist',
  AVATAR_SETTINGS: '/hr/settings-avatar',
};

export {
  Settings,
  JobDetails,
  Jobs,
  Candidates,
  CreateCandidate,
  CandidatesInfoPage,
  QuestionBank,
  CreateQuestionnairePage,
  ConfigureQuestionnairePage,
  QuestionBanks,
  ViewQuestionnairePage,
  ViewQuestionBankPage,
  Interviewer,
  AiInterviewReview,
  JobInterviewReview,
  AiInterviewPreview,
  PageNotFoundInfo,
  InActiveLinkExpiredInfo,
  BigFiveReview,
  PersonalityReport,
  SettingsJobFeilds,
  SettingsDepartments,
  SettingsRoles,
  SettingsTeams,
  SettingsLocation,
  SettingsSkills,
  SettingsPrescreen,
  SettingsEvaluationParameter,
  SettingsEducation,
  SettingsQuestinnaire,
  SettingHiring,
  SettingsCertification,
  CommunicationRegistration,
  Dashboard,
  CandidateSlotSelection,
  CreateLicense,
  CreateLicenseForm,
  PersonalityForm,
  PersonalityTest,
  BigFiveFeedBackForm,
  Consultancy,
  ConsultancyUser,
  ConsultancyClient,
  CommunicationReport,
  CandidateSourcingReport,
  CandidateScreeningReport,
  ConsultancyClientUser,
  EnquiryList,
  EnquiryDetails,
  CreateEnquiryRegistration,
  QuestionView,
  CreateQuestionnaireFromQuestions,
  ViewCreatedQuestionFromQuestionBank,
  SettingsDocumentType,
  SettingsGroup,
  SettingsUserList,
  AvatarSettings,
};
export default routes;
