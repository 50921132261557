import React from "react";
import logo from "../../Assets/logo.png";
import AddEmployerForm from "./AddEmployerForm";
import { useParams } from "react-router-dom";

const EmployerRegisterComp = () => {
  let urlData = useParams();
  console.log("urlData", urlData);
  return (
    <div className="h-screen bg-gray-50 flex items-center justify-center">
      <div className="rounded-xl primary-box-shadow md:w-3/4 sm:w-full">
        <div className="p-5">
          <div className="flex flex-wrap justify-center items-center">
            <img src={logo} alt="" className="login_logo" />
          </div>
          <div>
            <AddEmployerForm data={urlData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerRegisterComp;
