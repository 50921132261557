import create from "zustand";

const setPrescreeningStore = create((set) => ({
  prescreeningResponse: "",
  setPrescreeningResponse: (response) => {
    set(() => ({
      prescreeningResponse: response,
    }));
  },
}));

export default setPrescreeningStore;
