/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import Logo from '../../Assets/images/interview/logo1.png'
import TimeDot from '../../Assets/images/interview/Time.svg'
import Danger from '../../Assets/images/interview/exclamation mark.png'
import InhouseTestService from 'Services/InhouseTestService';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Modal } from 'antd';
import { formatTime } from 'Utilities/utils';

var isGetSkipQuestion = false;
const QuestionDisplay = ({jobTitle}) => {
    const [isDetailsScreen, setDetailsScreen] = useState(false);
    const [questionsData, setQuestionData] = useState();
    const [nIndexQuestionNo, setnIndexQuestionNo] = useState(0);
    const [singleOptionId, setSingleOptionId] = useState("");
    const [MultiOptionId, setMultiOptionId] = useState([]);
    const [textEditAns, setTextEditAns] = useState("");
    const [openTabActiveModel , setTabActiveModel] = useState(false);

      // time cal state
    const [Qtime, setQTime] = useState(0);
    const [QAddTime , setQAddTime] = useState(0);
    const [totalSecondsQuestionHolds, setTotalSecondsQuestionHolds] = useState();
    const [totalInterviewTime, setTotalInterviewTime] = useState(0);
    const [caltotalInterviewTime, setCalTotalInterviewTime] = useState(0);
    const [isTimeRunning, setRunning] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getQuestions()
    }, [])

      // event listen
  
    useEffect(() => {
      // Browser Tab Change Detection
      const handleVisibilityChange = () => {
        console.log(window, window.document.visibilityState);
        if (window.document.visibilityState === "visible") {
          console.log(window.document.visibilityState);
          // Modal.warning({
          //   title:
          //     "You lack the authorization to change the tab, hence we will not continue with this question",
          //   onOk() {
          //     setMultiOptionId([])
          //     setTextEditAns("");
          //     setSingleOptionId("");
          //     getQuestions();
          //   },
          // });
          setTabActiveModel(true);
          var nQno = questionsData?.answer_id;
          if (nQno) {
            sendTabActiveState(nQno);
          }
        }
      };

      window.addEventListener("visibilitychange", handleVisibilityChange);

      const handleContextMenu = (event) => {
        event.preventDefault();
      };
      // window.addEventListener('contextmenu', handleContextMenu);
      window.addEventListener("copy", handleContextMenu);
      window.addEventListener("cut", handleContextMenu);
      window.addEventListener("paste", handleContextMenu);
      return () => {
        window.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    });

    useEffect(() => {
        const interval = setInterval(() => {
                setQTime(prevTime => prevTime - 1);
          }, 1000);

        let TotalInterval;
        if(isTimeRunning)
        {
          TotalInterval = setInterval(() => {
            setCalTotalInterviewTime(prevTime => prevTime - 1);
          }, 1000);
        }
        else {
          clearInterval(TotalInterval);
        }
        return () => 
        {
          clearInterval(interval);
          clearInterval(TotalInterval);
        }
    
    
      }, [isTimeRunning]);
    
    
      useEffect(()=>
      {
        const interval = setInterval(() => {
          setQAddTime(prevTime => prevTime + 1);
        }, 1000);
        return () => 
        {
          clearInterval(interval);
        }
      },[])
    
    
      useEffect(() => {
        if(!isEmpty(questionsData))
        {
          if (Qtime === 0 && questionsData.question.time_duration !== 0) {
            Modal.error({
              title: "Uh Oh! The allocated timer for this question is over",
              onOk() {
                finalSubmit();
              },
            });
          }
        }

        if(totalInterviewTime !== 0)
        {
          if(caltotalInterviewTime === 0 )
          {
              Modal.error({
                title:
                  "Uh Oh! The allocated Duration for this Interview is over",
                onOk() {
                  submitInterview();
                },
              });
          }
        }
      }, [Qtime, caltotalInterviewTime]);


      const sendTabActiveState = async (nQno) => {
        console.log("nQno", nQno);
        let timeTaken;
        let questionDuration = localStorage.getItem("QDuration");
        console.log(questionDuration);
        if(questionDuration != 0)
        {
          timeTaken = totalSecondsQuestionHolds - Qtime;
        }
        else{
          timeTaken = QAddTime;
        }
        var json = {
          answer_id: nQno,
          is_tab_switched: true,
          tab_switched_time: timeTaken,
        };
        var data = JSON.stringify(json);
    
        await InhouseTestService.sendTabActive(params.token, data)
          .then((oResponse) => {
            console.log(oResponse);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    

    const setLocalStorgeItem = (oResponse)=>
    {
      localStorage.setItem(
        "question_type",
        oResponse.data.data.question.question_type
      );
      localStorage.setItem("question_no", oResponse.data.data.id);
      localStorage.setItem("ans_id", oResponse.data.data.answer_id);
      localStorage.setItem(
        "isLastAns",
        oResponse.data.data.is_last_question
      );
      localStorage.setItem("QDuration", oResponse.data.data.question.time_duration);
    }

    const getQuestions = async () => {
        try {
            const responseData = await InhouseTestService.getQuestions(params.token, isGetSkipQuestion)
            console.log("getQuestions", responseData);
            if (responseData.status === 202) {
                setnIndexQuestionNo(0);
                setQuestionData(responseData.data.data);
                setDetailsScreen(true);
                //setRunning(false)
            } else if (responseData.status === 200) {
                console.log("getQuestion", responseData);
                setLocalStorgeItem(responseData)
                setQuestionData(responseData.data.data);
                setnIndexQuestionNo(nIndexQuestionNo + 1);
                if (responseData.data.data.informations.total_duration_in_minutes !== 0 && nIndexQuestionNo === 0 && isGetSkipQuestion === false) {
                    console.log("totalInterview_duration", responseData.data.data.informations.total_duration_in_minutes * 60);
                    setTotalInterviewTime(responseData.data.data.informations.total_duration_in_minutes * 60);
                    setCalTotalInterviewTime(responseData.data.data.informations.total_duration_in_minutes * 60)
                    setRunning(true)
                }
                if (responseData.data.data.question.time_duration !== 0) {
                    console.log(responseData.data.data.question.time_duration * 60);
                    setTotalSecondsQuestionHolds(responseData.data.data.question.time_duration * 60);
                    setQTime(responseData.data.data.question.time_duration * 60);
                } else {
                    setQAddTime(0);
                }
            }
        } catch (error) {
            console.log("getQuestions_error", error);
        }

    }

    const completeInterview = async () => {
        await InhouseTestService.complete(params.token)
          .then((oResponse) => {
            console.log(oResponse);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    
    const submitInterview = async () => {
      let submitedTime = totalInterviewTime -  caltotalInterviewTime;
        setRunning(false)
        completeInterview();
        navigate(`/interview/submittedInterview/${params.token}`, {
            state: {
            submitTime: submitedTime
            },
        });
    };


    const finalSubmit = async () => {
        console.log(questionsData);
        let timeTaken;
        let questionDuration = localStorage.getItem("QDuration");
        console.log(questionDuration);
        if(questionDuration != 0)
        {
          timeTaken = totalSecondsQuestionHolds - Qtime;
        }
        else{
          timeTaken = QAddTime;
        }
        console.log(timeTaken);
        var nQno = localStorage.getItem("question_no");
        var nAnsId = localStorage.getItem("ans_id");
        var questionType = localStorage.getItem("question_type");
        var isLast = localStorage.getItem("isLastAns");
        console.log("nQno", nQno);
        var optionPayload = [];
        if (questionType === "OPTIONS") {
          if (singleOptionId === "" || singleOptionId === undefined) {
            optionPayload = [];
          } else {
            optionPayload.push(singleOptionId);
          }
        } else if (questionType === "MULTI_CHOICE") {
          if (MultiOptionId.length > 0) {
            optionPayload = MultiOptionId;
          } else {
            optionPayload = [];
          }
        } else {
          optionPayload = [];
        }
        var json = {
          is_last_question: isLast,
          answer_id: nAnsId,
          multi_option_answers: optionPayload,
          time_taken: timeTaken,
          text_answer: textEditAns,
        };
        await InhouseTestService.submitAnswer(params.token, json)
          .then((oResponse) => {
            console.log("finalSubmit", oResponse);
            optionPayload = [];
            setMultiOptionId([])
            setTextEditAns("");
            setSingleOptionId("");
            getQuestions();
          })
          .catch((err) => {
            console.log(err);
          });
      };
    
      const skipQuestionToNext = () => {
        if (isGetSkipQuestion) {
          Modal.error({
            title: "",
            content:
              "You've once skipped this question; it cannot be overlooked a second time.",
          });
        } else {
          var nQType = questionsData.question.question_type;
          if (nQType === "OPTIONS") {
            if (singleOptionId === "" || singleOptionId === undefined) {
              skipQuestion();
            } else {
              Modal.warning({
                title: "",
                content:
                  "You've made a selection; skipping the question is not permissible.",
              });
            }
          } else if (nQType === "MULTI_CHOICE") {
            if (MultiOptionId.length > 0) {
              Modal.warning({
                title: "",
                content:
                  "You've made a selection; skipping the question is not permissible.",
              });
            } else {
              skipQuestion();
            }
          } else {
            skipQuestion();
          }
        }
      };
    
      const skipQuestion = async () => {
        var nQno = questionsData.id;
        var nAnsId = questionsData.answer_id;
        console.log("nQno", nQno);
        var optionPayload = [];
    
        let timeTaken;
        let questionDuration = localStorage.getItem("QDuration");
        
        if(questionDuration != 0)
        {
          timeTaken = totalSecondsQuestionHolds - Qtime;
        }
        else{
          timeTaken = QAddTime;
        }
    
        var json = {
          is_last_question: questionsData.is_last_question,
          answer_id: nAnsId,
          multi_option_answers: optionPayload,
          time_taken: timeTaken,
          text_answer: textEditAns,
        };
    
        await InhouseTestService.submitQuestion(params.token, json)
          .then((oResponse) => {
            console.log("finalSubmit", oResponse);
            optionPayload = [];
            setMultiOptionId([])
            setTextEditAns("");
            setSingleOptionId("");
            getQuestions();
          })
          .catch((err) => {
            console.log(err);
          });
      };


    const handleSubmitAns = () => {
        var nQType = questionsData?.question?.question_type;
        if (nQType === "OPTIONS") {
          if (singleOptionId === "" || singleOptionId === undefined) {
            Modal.warning({
              title: "",
              content:
                "Please select the appropriate response and click on the, Submit button.",
            });
          } else {
            finalSubmit();
          }
        } else if (nQType === "MULTI_CHOICE") {
          if (MultiOptionId.length > 0) {
            finalSubmit();
          } else {
            Modal.warning({
              title: "",
              content:
                "Please select the appropriate response and click on the, Submit button.",
            });
          }
        } else {
          finalSubmit();
        }
      };


  const singleOptionSelect = (id) => {
    console.log("singleOptionId " + id);
    setSingleOptionId(id);
  };

  const multiOptionSelect = (event) => {
    console.log(event);
    if (event.target.checked) {
      setMultiOptionId((prev) => {
        const newState = [...prev];
        newState.push(event.target.value);
        return newState;
      });
    } else {
      console.log(event.target.value);
      const objWithIdIndex = MultiOptionId.filter((nId) => nId !== event.target.value);
      setMultiOptionId(objWithIdIndex)
    }
    console.log(MultiOptionId);
  };

  const viewSkippedQuestions = () => {
    isGetSkipQuestion = true;
    setDetailsScreen(false);
    getQuestions();
  };

  const onChangeText = (e) => {
    console.log(e);
    setTextEditAns(e.target.value);
  };

  const moveToNextQuestion = ()=>
  {
    setMultiOptionId([])
    setTextEditAns("");
    setSingleOptionId("");
    getQuestions();
    setTabActiveModel(false)
  }

  const displayQuestionBasedOnType = () => {
      if (questionsData?.question?.question_type === "OPTIONS") {
          return (
              <div className="question_div" key={questionsData.id}>
                <div className='time_div'>
                    <span className='questionTime'>
                      <img src={TimeDot} alt=''/>
                      {
                        questionsData?.question?.time_duration !== 0 ? formatTime(Qtime) : formatTime(QAddTime)
                      }
                    </span>
                </div>
                  <h1>
                        {questionsData?.question?.question}
                  </h1>
                  <h2 className='hitText'>Select your answer.</h2>
                  <div className='option_div'>
                    {
                      questionsData?.question?.multi_options_answers.map((oOption) => {
                            return <label className='pText'>
                                    <input
                                        type="radio"
                                        value={oOption.id}
                                        name="optionQuestion"
                                        key={oOption.id}
                                        onChange={(e) => singleOptionSelect(e.target.value)}
                                        defaultChecked={false}
                                    />{" "}
                                <span className="option_span">{oOption.option}</span>
                            </label>
                        })
                    }
                     </div>
                </div>
            );
        } else if (questionsData?.question?.question_type === "MULTI_CHOICE") {
            return <div className="question_div" key={questionsData.id}>
                  <div className='time_div'>
                    <span className='questionTime'>
                      <img src={TimeDot} alt=''/>
                      {
                        questionsData?.question?.time_duration !== 0 ? formatTime(Qtime) : formatTime(QAddTime)
                      }
                    </span>
                </div>
                <h1>
                    {questionsData?.question?.question}
                </h1>
                <h2 className='hitText'>Select your answer.</h2>
                <div className='option_div'>
                {
                    questionsData?.question?.multi_options_answers.map((oMultiOption) => {
                        return<label className='pText'> 
                            <input
                                type="checkbox"
                                value={oMultiOption.id}
                                name="optionQuestion"
                                key={oMultiOption.id}
                                onChange={(e) => multiOptionSelect(e)}
                                defaultChecked={false}
                            />{" "}
                            <span className="option_span">{oMultiOption.option}</span>
                        </label>
                    })
                }
                </div>
            </div>
        }
        else if(questionsData?.question?.question_type === "CHAR"){ 
            return <div className="question_div" key={questionsData.id}>
                  <div className='time_div'>
                    <span className='questionTime'>
                      <img src={TimeDot} alt=''/> 
                      {
                        questionsData?.question?.time_duration !== 0 ? formatTime(Qtime) : formatTime(QAddTime)
                      }
                    </span>
                </div>
                <h1>
                    {questionsData?.question?.question}
                </h1>
                <h2 className='hitText'>
                   Type your answer in the below text box
                </h2>
                <textarea
                 //rows="10"
                 value={textEditAns}
                 onChange={onChangeText}
                />
            </div>
        }
        else{
            return null
        }

    };

    const displayQuestionDetailsOnDetailsScreen = (questionInfo)=>
    {
      return   <div className="question_details">
      <div  className="question_details_maindiv applyBorderBottom">
          <div className="avathar ">QA</div>
          <div>
              <p>Question Attended</p>
              <p className="valueText">
               {questionInfo?.submitted}/
               {questionInfo?.total_no_of_questions}
              </p>
          </div>
      </div>
      <div  className="question_details_maindiv applyBorderBottom">
          <div className="avathar ">SQ</div>
          <div>
              <p>Skipped Question</p>
              <p className="valueText">  {questionInfo?.questions_skipped}/
               {questionInfo?.total_no_of_questions}</p>
          </div>
      </div>
      <div  className="question_details_maindiv">
          <div className="avathar">TD</div>
          <div>
              <p>Total Duration</p>
              <p className="valueText"> {questionInfo?.total_duration_str}</p>
          </div>
      </div>
  </div>
    }

    const displayQuestionDetails = (questionInfo) => {
        return (
            <div className="question_details">
                <div id="qa" className="question_details_maindiv applyBorderBottom">
                    <div className="avathar ">QA</div>
                    <div>
                        <p>Question Attended</p>
                        <p className="valueText">
                         {questionInfo?.submitted}/
                         {questionInfo?.total_no_of_questions}
                        </p>
                    </div>
                </div>
                <div id="sq" className="question_details_maindiv applyBorderBottom">
                    <div className="avathar ">SQ</div>
                    <div>
                        <p>Skipped Question</p>
                        <p className="valueText">  {questionInfo?.questions_skipped}/
                         {questionInfo?.total_no_of_questions}</p>
                    </div>
                </div>
                <div id="td" className="question_details_maindiv">
                    <div className="avathar">TD</div>
                    <div>
                        <p>Total Duration</p>
                        <p className="valueText"> {questionInfo?.total_duration_str}</p>
                    </div>
                </div>
            </div>
        );
    };

    const buildDeatilsScreen = () => {
        return <div >
            <div className='detailsScreen_skipview'>
                {
                displayQuestionDetailsOnDetailsScreen(questionsData)  
                }
            </div>
            <div className="questionViewFooter">
                <div className="skipQuestion_view_btn_div">
                  {questionsData?.questions_skipped > 0 &&
                    <button 
                    className="skip_btn"
                    onClick={() => viewSkippedQuestions()}
                    disabled={questionsData?.questions_skipped === 0}
                  >View Skipped Questions</button>
                  }
                    
                    <button className="submit_btn" onClick={submitInterview}>Submit Interview</button>
                </div>
            </div>
        </div>
    }

    return (
        <div className="questionViewMainDiv">
            <div className="questionViewHeader">
                <div className="totalDuration_div">
                  {
                    questionsData?.informations?.total_duration_in_minutes !== 0 &&(
                      <>
                      Interview ends in {formatTime(caltotalInterviewTime)}
                      </>
                    )
                  }
                </div>
            </div>
            {
                isDetailsScreen ? buildDeatilsScreen() : !isEmpty(questionsData) && (
                    <>
                        <div>
                            <div className="questionNumberDiv">
                                <div>
                                    <img src={Logo} alt="" />
                                </div>
                                <div>
                                    <div className="questionNumber">Question No: {nIndexQuestionNo}/{questionsData?.informations?.total_no_of_questions}</div>
                                    <div className="questionJobTitle">
                                        <span>Job Interview for <span className="titleText">{jobTitle}</span></span>
                                        <span className="questionSectionTotalTime">
                                          {
                                            questionsData?.informations?.total_duration_in_minutes !== 0 && (
                                              <>
                                                 Total Duration: {questionsData?.informations?.total_duration_str}
                                              </>
                                            )
                                          }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="questions_maindiv">
                                {displayQuestionBasedOnType()}
                                {displayQuestionDetails(questionsData?.informations)}
                            </div>
                        </div>
                        <div className="questionViewFooter">
                            <div className="questionView_btn_div">
                                <button className="skip_btn" onClick={skipQuestionToNext}>Skip to Next</button>
                                <button className="submit_btn" onClick={handleSubmitAns}>Submit</button>
                            </div>
                        </div>
                    </>
                )
            }
            <Modal
              open={openTabActiveModel}
              title={null}
              footer={null}
              destroyOnClose
              closeIcon={null}
              width={370}
              className='tabActivePopup'
              centered
            >
               <div className='text-center'>
                    <div>
                       <img src={Danger} alt='No img' className='m-auto'/>
                    </div>
                    <h1 className='mt-2 text-[24px] text-[#262626] font-[600]'>
                      Attention
                    </h1>
                    <h2 className='mt-2 text-[16px] text-[#262626] font-[600]'>
                      You have out from the question!
                    </h2>
                    <p className='mt-2 text-[14px] text-[#262626] font-[400]'>
                    You are not permitted to use another tab or navigate away from this page during the duration of the interview.
                    </p>
                    <button className='mt-5 bg-[#11B2AB] rounded-[86px] p-4 text-[16px] text-[#FFF] font-[600] ' onClick={moveToNextQuestion}> Move to Next Question </button>
               </div>
            </Modal>
        </div>
    );
};
export default QuestionDisplay;
