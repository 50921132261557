export const tableColumn = [
  {
    fieldID: 'id',
    fieldLable: 'Job Reference Id',
    fieldShow: true,
  },
  {
    fieldID: 'JobName',
    fieldLable: 'Job Name',
    fieldShow: true,
  },
  {
    fieldID: 'NoOfOpenings',
    fieldLable: 'No Of Openings',
    fieldShow: true,
  },
  {
    fieldID: 'totalOffered',
    fieldLable: 'Total Offered',
    fieldShow: true,
  },
  {
    fieldID: 'totalHired',
    fieldLable: 'Total Hired',
    fieldShow: true,
  },
  {
    fieldID: 'totalRejected',
    fieldLable: 'Total Rejected',
    fieldShow: true,
  },
  {
    fieldID: 'TotalApplication',
    fieldLable: 'Total Application',
    fieldShow: true,
  },
  {
    fieldID: 'Status',
    fieldLable: 'Status',
    fieldShow: false,
  },
  {
    fieldID: 'Certification',
    fieldLable: 'Certification',
    fieldShow: false,
  },
  {
    fieldID: 'Created',
    fieldLable: 'Created',
    fieldShow: false,
  },
  {
    fieldID: 'ClosurePeriod',
    fieldLable: 'Closure Period',
    fieldShow: false,
  },
  {
    fieldID: 'CreatedBy',
    fieldLable: 'Created By',
    fieldShow: true,
  },
  {
    fieldID: 'CurrencyCode',
    fieldLable: 'Currency Code',
    fieldShow: false,
  },
  {
    fieldID: 'PaymentType',
    fieldLable: 'Payment Type',
    fieldShow: false,
  },
  {
    fieldID: 'Department',
    fieldLable: 'Department',
    fieldShow: false,
  },
  {
    fieldID: 'EducationDetails',
    fieldLable: 'Education Details',
    fieldShow: false,
  },
  {
    fieldID: 'Experience',
    fieldLable: 'Experience',
    fieldShow: false,
  },
  {
    fieldID: 'JobType',
    fieldLable: 'Job Type',
    fieldShow: true,
  },
  {
    fieldID: 'Locations',
    fieldLable: 'Locations',
    fieldShow: false,
  },
  {
    fieldID: 'MaximumCtc',
    fieldLable: 'Maximum ctc',
    fieldShow: false,
    type: 'number',
  },

  {
    fieldID: 'MinimumCtc',
    fieldLable: 'Minimum Ctc',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'MaximumExperience',
    fieldLable: 'Maximum Experience',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'MinimumExperience',
    fieldLable: 'Minimum Experience',
    fieldShow: false,
    type: 'number',
  },
  {
    fieldID: 'Posted',
    fieldLable: 'Posted',
    fieldShow: false,
  },
  {
    fieldID: 'RequiredSkill',
    fieldLable: 'Required Skill',
    fieldShow: false,
  },
  {
    fieldID: 'PreferredSkill',
    fieldLable: 'Preferred Skill',
    fieldShow: false,
  },
  {
    fieldID: 'Priority',
    fieldLable: 'Priority',
    fieldShow: false,
  },

  {
    fieldID: 'gender',
    fieldLable: 'Gender',
    fieldShow: false,
  },
  {
    fieldID: 'notice_period',
    fieldLable: 'Notice Period',
    fieldShow: false,
  },
  {
    fieldID: 'visa_required',
    fieldLable: 'Visa Required',
    fieldShow: false,
  },
  {
    fieldID: 'internal_hire',
    fieldLable: 'Internal Hire',
    fieldShow: false,
  },

  {
    fieldID: 'reference_type',
    fieldLable: 'Reference type',
    fieldShow: false,
  },
  {
    fieldID: 'ready_to_relocate',
    fieldLable: 'Ready to relocate',
    fieldShow: false,
  },
  {
    fieldID: 'driving_license_required',
    fieldLable: 'Driving license required',
    fieldShow: false,
  },
  {
    fieldID: 'languages_preferred',
    fieldLable: 'Languages Preferred',
    fieldShow: false,
  },
  {
    fieldID: 'nationality',
    fieldLable: 'Nationality',
    fieldShow: false,
  },
  {
    fieldID: 'recruiters',
    fieldLable: 'Recruiters',
    fieldShow: false,
  },
  {
    fieldID: 'reviewers',
    fieldLable: 'Reviewers',
    fieldShow: false,
  },
  {
    fieldID: 'clients',
    fieldLable: 'Clients',
    fieldShow: false,
  },
  {
    fieldID: 'consultants',
    fieldLable: 'Consultants',
    fieldShow: false,
  },
  {
    fieldID: 'interviews',
    fieldLable: 'Interviews',
    fieldShow: false,
  },
  {
    fieldID: 'interviewers',
    fieldLable: 'Interviewers',
    fieldShow: false,
  },
];

export const AWAITING_QUESTIONNAIRE_COLUMN = [
  {
    fieldID: 'id',
    fieldLable: 'Job Reference Id',
    fieldShow: true,
  },
  {
    fieldID: 'JobName',
    fieldLable: 'Job Name',
    fieldShow: true,
  },
  {
    fieldID: 'levels',
    fieldLable: 'Levels',
    fieldShow: true,
  },
];

export const columnFields = {
  JobName: 'title',
  Status: 'status',
  id: 'reference_no',
  Locations: 'locations__company_city__city',
  CreatedBy: 'created_by__first_name',
  Created: 'created',
  ClosurePeriod: 'closure_period',
  NoOfOpenings: 'number_of_openings',
  TotalApplication: 'total_applicants',
  Experience: 'experience',
  JobType: 'additional_info__job_type',
  Posted: 'posted',
  RequiredSkill: 'required_skill__skill',
  PreferredSkill: 'preferred_skill__skill',
  EducationDetails: 'education_details__qualification',
  Certification: 'additional_info__certification__certification',
  Department: 'department__name',
  MinimumCtc: 'minimum_ctc',
  MaximumCtc: 'maximum_ctc',
  CurrencyCode: 'ctc_currency_code',
  PaymentType: 'ctc_payment_type',
  Priority: 'priority',
  MinimumExperience: 'minimum_experience',
  MaximumExperience: 'maximum_experience',
  totalOffered: 'total_offered',
  totalRejected: 'total_rejected',
  Gender: 'gender',
  notice_period: 'notice_period',
  visa_required: 'visa_required',
  internal_hire: 'internal_hire',
  reference_type: 'reference_type',
  ready_to_relocate: 'ready_to_relocate',
  driving_license_required: 'driving_license_required',
  languages_preferred: 'languages_preferred',
  Nationality: 'nationality',
  Recruiters: 'recruiters',
  Reviewers: 'reviewers',
  Clients: 'clients',
  Consultants: 'consultants',
  Interviews: 'interviews',
  interviewers: 'interviewers',
};

export const filterOperatorValue = {
  contains: 'icontains',
  equals: 'iexact',
  startsWith: 'istartswith',
  endsWith: 'iendswith',
  '=': 'iexact',
  '>': 'gt',
  '>=': 'gte',
  '<': 'lt',
  '<=': 'lte',
};
