import { useEffect, useState, isValidElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DatePicker, TimePicker, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { pick, isEmpty } from 'lodash';
import { useLocalStorage } from 'react-use';

import Progress from 'Components/CommonComponents/Progress';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Button from 'Components/CommonComponents/Button/Button';
import Accordion from 'Components/CommonComponents/Accordion/Accordion';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import CTCField from './Fields/CTCField';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import Autocomplete from 'Components/CommonComponents/ReactHooKForm/Autocomplete/Autocomplete';
import AddMultiSearch from 'Components/CommonComponents/JobSection/AddMultiSearch/AddMultiSearch';
import DatePickerComponent from 'Components/CommonComponents/ReactHooKForm/DatePicker/DatePicker';
import MultiSearchSelect from 'Components/CommonComponents/JobSection/MultiSearch/MultiSearchSelect';
import SingleSearchSelect from 'Components/CommonComponents/JobSection/SignleSearch/SignleSearchSelect';
import Ai_Icon from '../../Assets/images/AI_Group.svg';
import jobService from 'Services/JobService';
import EditJobStore from 'Store/EditJobStore';
import EditJobPublish from 'Store/EditJobPublish';
import { dateFormatter, timeConvert } from 'Utilities/utils';
import { displayRequiredIndicator } from 'Utilities/componentSpecificUtilities';
import {
  JOB_TAB_TYPES,
  JOB_ACTIVITY_LOGGER_TYPES,
} from 'Utilities/constants';
import createActivityLoggerId from 'Utilities/Jobs/createActivityLoggerId';
import { useCreateActivityLoggerId } from 'ServiceHooks/jobHooks';

import { showJobField } from 'Utilities/Jobs/utils';
import routes from 'Routes';

const AddJobInfoNew = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    setValue,
    setError,
    getValues,
  } = useForm();

  const param = useParams();
  const navigate = useNavigate();
  const [userSettings] = useLocalStorage('user');

  const modifiedFieldKeys = Object.keys(dirtyFields);

  const [isActiveAccordian, setActiveAccordion] = useState([1]);
  const [jobDescription, setJobDescription] = useState('');
  const [singleSearchOption, setSingleSearchOption] = useState([]);
  const { setEditJobResponse } = EditJobStore();
  const [searchValueToAdd, setSearchvalue] = useState();
  const oEditJobResponse = EditJobStore((state) => state.EditJobtResponse);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [autoSuggestJob, setAutoSuggestJobList] = useState([]);
  const [isProcessing, setProcessing] = useState();
  const [autoSelectedJobId, setAutoSelectedJobId] = useState();
  const [checkJobDescriptionError, setCheckJobDescriptionError] =
    useState();
  const [scheduleTimeControllerName, setScheduleControllerName] = useState(
    [
      {
        work_from_time: '',
        work_end_time: '',
      },
    ],
  );

  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateStatus, setSelecteddateStatus] = useState('');
  const [selectedTimeStatus, setSelectedTimeStatus] = useState('');
  const [currecyCodeResponse, setCurrecyCodeResponse] = useState('');
  const [scheduledays, setScheduledays] = useState([])
  const [job_id, setJobID] = useState(null);

  const { setEditJobPublishResponse } = EditJobPublish();

  // const scheduledays = [];

  // State to manage the minimum allowed date

  useEffect(() => {
    props.process(false);
    if (!param.jobId) {
      getReferenceId();
    }
    if (param.jobId) {
      editJobBasicInfo();
    }
  }, []);

  const { mutate: sendActivityLog } = useCreateActivityLoggerId({});

  const getReferenceId = async () => {
    await jobService
      .getReferences()
      .then((oResponse) => {
        setValue('reference_no', oResponse.data.data.reference_no);
        setCurrecyCodeResponse(oResponse.data.data.currency_code);
        console.log();
        props.process(false);
      })
      .catch((err) => {
        props.process(false);
      });
  };

  const editJobBasicInfo = async () => {
    await jobService
      .getEditJob(param?.jobId)
      .then((oResponse) => {
        if (oResponse.data.data.job_publish_status_id !== false) {
          editJobPublish(oResponse.data.data.job_publish_status_id);
        } else {
          setEditJobPublishResponse('');
        }
        setEditJobResponse(oResponse.data.data);
        setJobInfoData(oResponse.data.data);
        props.process(false);
      })
      .catch((err) => {
        props.process(false);
      });
  };

  const editJobPublish = async (id) => {
    await jobService
      .getPublish(id)
      .then((oResponse) => {
        setEditJobPublishResponse(oResponse.data.data);
      })
      .catch((err) => {
        setEditJobPublishResponse('');
      });
  };

  const setJobInfoData = (oResponseData) => {
    let basic_info = props.fields?.filter(
      (item) => item?.field_heading_code === 'BASIC_INFORMATION',
    )[0];
    let other_info = props.fields?.filter(
      (item) => item?.field_heading_code === 'OTHER_INFORMATION',
    )[0];
    let schedule_info = props.fields?.filter(
      (item) => item?.field_heading_code === 'SCHEDULE',
    )[0];
    if (showJobField(basic_info?.fields, 'title')) {
      setValue('title', oResponseData.title);
    }
    if (param.jobId && showJobField(basic_info?.fields, 'reference_no')) {
      setValue('reference_no', oResponseData.reference_no);
    }
    if (showJobField(basic_info?.fields, 'currency_code')) {
      setValue('currency_code', oResponseData.currency_code);
    }
    setValue('address', oResponseData.address);

    if (showJobField(other_info?.fields, 'gender')) {
      setValue('gender', oResponseData.gender);
    }
    if (showJobField(basic_info?.fields, 'closure_period')) {
      setValue(
        'closure_period',
        oResponseData?.closure_period !== null
          ? dayjs(oResponseData?.closure_period, 'YYYY-MM-DD')
          : '',
      );
    }
    setValue('created_by', oResponseData.created_by);
    if (showJobField(basic_info?.fields, 'ctc_currency_code')) {
      const initialCurrencyCode =
        oResponseData?.ctc_currency_code ??
        userSettings?.ctc_currency_code ??
        '';
      setValue('ctc_currency_code', initialCurrencyCode);
    }
    if (showJobField(basic_info?.fields, 'ctc_payment_type')) {
      setValue('ctc_payment_type', oResponseData.ctc_payment_type);
    }
    if (showJobField(basic_info?.fields, 'location_address')) {
      setValue('location_address', oResponseData.location_address);
    }
    if (showJobField(basic_info?.fields, 'location_pincode')) {
      setValue('location_pincode', oResponseData.location_pincode);
    }
    if (showJobField(basic_info?.fields, 'salary_confidential')) {
      setValue('salary_confidential', oResponseData.salary_confidential);
    }
    if (showJobField(basic_info?.fields, 'maximum_ctc')) {
      setValue('maximum_ctc', oResponseData.maximum_ctc);
    }
    if (showJobField(basic_info?.fields, 'maximum_experience')) {
      setValue('maximum_experience', oResponseData.maximum_experience);
    }
    if (showJobField(basic_info?.fields, 'minimum_ctc')) {
      setValue('minimum_ctc', oResponseData.minimum_ctc);
    }
    if (showJobField(basic_info?.fields, 'minimum_experience')) {
      setValue('minimum_experience', oResponseData.minimum_experience);
    }
    if (showJobField(basic_info?.fields, 'number_of_openings')) {
      setValue('number_of_openings', oResponseData.number_of_openings);
    }
    if (showJobField(basic_info?.fields, 'relevant_experience')) {
      setValue('relevant_experience', oResponseData.relevant_experience);
    }

    if (showJobField(other_info?.fields, 'driving_license_required')) {
      setValue(
        'driving_license_required',
        oResponseData.driving_license_required,
      );
    }
    if (showJobField(other_info?.fields, 'internal_hire')) {
      setValue('internal_hire', oResponseData.internal_hire);
    }
    if (showJobField(other_info?.fields, 'notice_period')) {
      setValue('notice_period', oResponseData.notice_period);
    }
    if (showJobField(other_info?.fields, 'ready_to_relocate')) {
      setValue('ready_to_relocate', oResponseData.ready_to_relocate);
    }
    if (showJobField(other_info?.fields, 'reference_type')) {
      setValue('reference_type', oResponseData.reference_type);
    }
    if (showJobField(other_info?.fields, 'visa_required')) {
      setValue('visa_required', oResponseData.visa_required);
    }
    if (showJobField(basic_info?.fields, 'priority')) {
      setValue('priority', oResponseData.priority);
    }

    setValue(
      'work_end_date',
      oResponseData.seasonal_job_schedule.work_end_date,
    );

    // setValue(
    //   "work_end_time",
    //   oResponseData.seasonal_job_schedule.work_end_time
    // );
    setValue(
      'work_from_date',
      oResponseData.seasonal_job_schedule.work_from_date,
    );

    setScheduledays(oResponseData.seasonal_job_schedule.work_days)
    // setValue(
    //   "work_from_time",
    //   oResponseData.seasonal_job_schedule.work_from_time
    // );

    if (showJobField(basic_info?.fields, 'department')) {
      setValue(
        'department',
        getSingleSelectDefaultValue(
          'department',
          oResponseData.department,
        ),
      );
    }

    if (showJobField(basic_info?.fields, 'required_skill')) {
      setValue(
        'required_skill',
        getSingleSelectDefaultValue(
          'required_skill',
          oResponseData.required_skill,
        ),
      );
    }

    if (showJobField(basic_info?.fields, 'preferred_skill')) {
      setValue(
        'preferred_skill',
        getSingleSelectDefaultValue(
          'preferred_skill',
          oResponseData.preferred_skill,
        ),
      );
    }
    if (showJobField(basic_info?.fields, 'locations')) {
      setValue(
        'locations',
        getSingleSelectDefaultValue('locations', oResponseData.locations),
      );
    }
    if (showJobField(basic_info?.fields, 'education_details')) {
      setValue(
        'education_details',
        getSingleSelectDefaultValue(
          'education_details',
          oResponseData.education_details,
        ),
      );
    }

    if (showJobField(other_info?.fields, 'nationality')) {
      setValue(
        'nationality',
        getSingleSelectDefaultValue(
          'nationality',
          oResponseData.nationality,
        ),
      );
    }

    if (showJobField(other_info?.fields, 'certification')) {
      setValue(
        'certification',
        getSingleSelectDefaultValue(
          'certification',
          oResponseData.certification,
        ),
      );
    }

    if(showJobField(other_info?.fields,"job_type")){
      setValue('job_type', oResponseData.job_type);
    }

    if (showJobField(other_info?.fields, 'languages_preferred')) {
      setValue(
        'languages_preferred',
        getSingleSelectDefaultValue(
          'languages_preferred',
          oResponseData.languages_preferred,
        ),
      );
    }

    var defaultDate = {
      work_from_date: oResponseData.seasonal_job_schedule.work_from_date,
      work_end_date: oResponseData.seasonal_job_schedule.work_end_date,
    };
    setSelectedDate(defaultDate);

    setJobDescription(oResponseData.description);
    if (!param.jobId) {
      var autoTime = [];
      if (oResponseData.seasonal_job_schedule.timings.length > 0) {
        oResponseData.seasonal_job_schedule.timings.map((Time) => {
          return autoTime.push({
            work_from_time: Time.work_from_time,
            work_end_time: Time.work_end_time,
          });
        });
        setScheduleControllerName(autoTime);
      }
    } else {
      if (oResponseData.seasonal_job_schedule.timings.length > 0) {
        setScheduleControllerName(
          oResponseData.seasonal_job_schedule.timings,
        );
      }
    }
  };

  const getSingleSelectDefaultValue = (keyName, defaultValue) => {
    if (defaultValue) {
      var options = addSingleSearchOption(defaultValue, keyName);
      setSingleSearchOption({ ...singleSearchOption, [keyName]: options });
      // let value;
      // defaultValue &&
      //   defaultValue.map((defaultOption) => {
      //     value = defaultOption.option_data;
      //   });
      return options;
    }
  };

  const getSelectedIds = (selectedData) => {
    const selectedId = selectedData.map((item) => {
      return item.key;
    });
    return selectedId;
  };

  const getSubmitData = (data) => {
    if (data.required_skill) {
      data.required_skill = getSelectedIds(data.required_skill);
    }
    if (data.preferred_skill) {
      data.preferred_skill = getSelectedIds(data.preferred_skill);
    }
    if (data.education_details) {
      data.education_details = getSelectedIds(data.education_details);
    }
    if (data.languages_preferred) {
      data.languages_preferred = getSelectedIds(data.languages_preferred);
    }
    if (data.nationality) {
      data.nationality = getSelectedIds(data.nationality);
    }
    if (data.certification) {
      data.certification = getSelectedIds(data.certification);
    }
    if (data.locations) {
      data.locations = getSelectedIds(data.locations);
    }
    if (data.department) {
      data.department = data?.department?.key
        ? data?.department?.key
        : data?.department[0]?.key;
    }
    if (data.ctc_currency_code) {
      data.ctc_currency_code = data?.ctc_currency_code?.key
        ? data?.ctc_currency_code?.key
        : data?.ctc_currency_code;
    }
    var startdate = undefined;
    var endDate = undefined;
    var workTime = [];

    // if (selectedDate !== undefined) {
    //   startdate = dateFormatter(selectedDate[0], "YYYY-MM-DD");
    //   endDate = dateFormatter(selectedDate[1], "YYYY-MM-DD");
    // }
    if (selectedDate !== undefined) {
      startdate = selectedDate.work_from_date;
      endDate = selectedDate.work_end_date;
    }

    if (
      scheduleTimeControllerName[0].work_from_time !== '' ||
      scheduleTimeControllerName[0].work_end_time !== ''
    ) {
      workTime = scheduleTimeControllerName;
    }

    var oJsonData = {
      title: data.title,
      description: jobDescription,
      reference_no: data.reference_no,
      required_skill: data.required_skill,
      preferred_skill: data.preferred_skill,
      department: data.department,
      relevant_experience: data.relevant_experience,
      number_of_openings: data.number_of_openings,
      minimum_ctc: data.minimum_ctc,
      maximum_ctc: data.maximum_ctc,
      ctc_currency_code: data.ctc_currency_code,
      ctc_payment_type: data?.ctc_payment_type?.key
        ? data?.ctc_payment_type?.key
        : data?.ctc_payment_type,
      locations: data.locations,
      closure_period: data.closure_period
        ? dateFormatter(data.closure_period, 'YYYY-MM-DD')
        : null,
      education_details: data.education_details,
      minimum_experience: data.minimum_experience,
      maximum_experience: data.maximum_experience,
      location_address: data.location_address,
      location_pincode: data.location_pincode,
      priority: data?.priority?.key ? data?.priority?.key : data?.priority,
      salary_confidential: data.salary_confidential,
      additional_info: {
        nationality: data.nationality,
        certification: data.certification,
        gender: data?.gender?.key ? data?.gender?.key : data?.gender,
        job_type: data?.job_type?.key ? data?.job_type?.key : data?.job_type,
        notice_period: data?.notice_period?.key
          ? data?.notice_period?.key
          : data?.notice_period,
        visa_required: data?.visa_required?.key
          ? data?.visa_required?.key
          : data?.visa_required,
        internal_hire: data?.internal_hire?.key
          ? data?.internal_hire?.key
          : data?.internal_hire,
        reference_type: data?.reference_type?.key
          ? data?.reference_type?.key
          : data?.reference_type,
        ready_to_relocate: data?.ready_to_relocate?.key
          ? data?.ready_to_relocate?.key
          : data?.ready_to_relocate,
        driving_license_required: data?.driving_license_required?.key
          ? data?.driving_license_required?.key
          : data?.driving_license_required,
        languages_preferred: data.languages_preferred,
      },
      seasonal_job_schedule: {
        work_days: scheduledays,
        work_from_date: startdate,
        work_end_date: endDate,
        timings: workTime,
      },
    };
    return oJsonData;
  };

  const onSubmit = (data) => {
    const today = new Date();
    var isSchedule = false;
    props.fields.map((oFieldsData, index) => {
      if (
        oFieldsData.field_heading_code === 'SCHEDULE' &&
        oFieldsData.show === true
      ) {
        isSchedule = true;
      }
    });
    const todayDate = dayjs(today).startOf('day');
    const closureDate = dayjs(data?.closure_period).startOf('day');
    if (todayDate.isAfter(closureDate)) {
      messageApi.open({
        type: 'error',
        content: 'Please Select Future Date as Closure Period',
      });
      return false;
    }
    // if(today > data?.closure_period){
    //   messageApi.open({
    //     type: 'error',
    //     content: "Please Select Future Date as Closure Period",
    //   });
    //   return false
    // }

    if (isSchedule) {
      const work_from_date = new Date(selectedDate?.work_from_date);
      const work_end_date = new Date(selectedDate?.work_end_date);
      if (today > work_from_date || today > work_end_date) {
        messageApi.open({
          type: 'error',
          content: 'Please Select Future Date as Schedule Dates',
        });
        return false;
      }
      if (work_from_date === work_end_date) {
        messageApi.open({
          type: 'error',
          content: 'From Date and End Date should not be same',
        });
        return false;
      }

      // if(scheduleTimeControllerName[0]?.work_from_time === scheduleTimeControllerName[0]?.work_end_time){
      //   messageApi.open({
      //     type: 'error',
      //     content: "Time should not be same",
      //   });
      //   return false
      // }
      if (
        selectedDate !== undefined &&
        (scheduleTimeControllerName[0].work_from_time !== '' ||
          scheduleTimeControllerName[0].work_end_time !== '')
      ) {
        jobCreateAndUpdateFunctions(data);
      } else {
        if (selectedDate === undefined) {
          setSelecteddateStatus('error');
        }
        if (
          scheduleTimeControllerName[0].work_from_time === '' ||
          scheduleTimeControllerName[0].work_end_time === ''
        ) {
          setSelectedTimeStatus('error');
        }
      }
    } else {
      jobCreateAndUpdateFunctions(data);
    }
  };

  const jobCreateAndUpdateFunctions = (oSubmitData) => {
    let payload = getSubmitData(oSubmitData);
    if (autoSelectedJobId || !param.jobId) {
      sendData(payload);
    } else if (oEditJobResponse && oEditJobResponse.job_id) {
      updateData(payload);
    } else {
      updateData(payload);
    }
  };

  const sendData = async (oJsonData) => {
    const logger_id = createActivityLoggerId();
    setProcessing(true);
    await jobService
      .createBasicInfo(oJsonData, { logger_id, job_id })
      .then(
        (oResposne) => {
          const response = oResposne.data.data;
          setEditJobResponse(response);
          const log = {
            logger_id,
            job: response?.job_id,
            activity: JOB_ACTIVITY_LOGGER_TYPES.JOB_CREATED,
          };
          sendActivityLog(log);
          props.setAccordionActive(props.currentActive + 1);
          setProcessing(false);
          setJobID(response?.job_id);
        },
        (err) => {
          const errorsObj = err.response.data.error_info;
          if (errorsObj.description) {
            setCheckJobDescriptionError(true);
          } else {
            Object.keys(errorsObj).forEach((field) => {
              const messages = errorsObj[field];
              setError(field, {
                type: 'server',
                message: messages.join('. '),
              });
              messageApi.open({
                type: 'error',
                content: messages.join('. '),
              });
            });
          }
          setProcessing(false);
        },
      )
      .catch((err) => {
        setProcessing(false);
      });
  };

  const updateData = async (oJsonData) => {
    setProcessing(true);
    const logger_id = createActivityLoggerId();

    const initialKeys = pick(oEditJobResponse, ...modifiedFieldKeys);
    const modifiedKeys = pick(getValues(), ...modifiedFieldKeys);
    const updated_fields = Object.keys(modifiedKeys)
      .map((key) => {
        // oEditJobResponse store values are varied
        // and form control values are strings only.
        if (!(initialKeys[key] == modifiedKeys[key])) {
          return key;
        } else {
          return null;
        }
      })
      .filter((value) => !!value);

    var nJobId = param.jobId
      ? param.jobId
      : oEditJobResponse && oEditJobResponse.job_id;
    await jobService
      .updateBasicInfo(oJsonData, nJobId, { logger_id })
      .then(
        (oResposne) => {
          const log = {
            logger_id,
            job: nJobId,
            activity: JOB_ACTIVITY_LOGGER_TYPES.JOB_DETAILS_UPDATED,
            ...(!isEmpty(updated_fields) && { updated_fields }),
          };
          sendActivityLog(log);
          setEditJobResponse(oResposne.data.data);
          props.setAccordionActive(props.currentActive + 1);
          setProcessing(false);
        },
        (err) => {
          const errorsObj = err.response.data.error_info;
          Object.keys(errorsObj).forEach((field) => {
            const messages = errorsObj[field];

            setError(field, {
              type: 'server',
              message: messages.join('. '),
            });

            messageApi.open({
              type: 'error',
              content: messages.join('. '),
            });
          });
          setProcessing(false);
        },
      )
      .catch((err) => {
        setProcessing(false);
      });
  };

  const onChangeDescription = (e) => {
    if (e === '<h2><br></h2>') {
      e = '';
    }
    setCheckJobDescriptionError(false);
    setJobDescription(e);
    // setValue("description", e)
  };

  const getJDSelectedSkills = (selectedData) => {
    const selectedvalue = selectedData.map((item) => {
      return item.value;
    });
    return selectedvalue;
  };

  const getAIJD = () => {
    let title = getValues('title');
    let requiredSkill = getValues('required_skill');
    let preferredSkill = getValues('preferred_skill');
    let minimumExperience = getValues('minimum_experience');
    let maximumExperience = getValues('maximum_experience');
    if (
      title &&
      requiredSkill &&
      preferredSkill &&
      minimumExperience &&
      maximumExperience
    ) {
      let jsonData = {
        jobTitle: title,
        required_skill: getJDSelectedSkills(requiredSkill),
        preferred_skill: getJDSelectedSkills(preferredSkill),
        minimunExperience: minimumExperience,
        maximumExperience: maximumExperience,
      };
      generateAIJD(jsonData);
    } else {
      if (title === undefined) {
        messageApi.open({
          type: 'error',
          content: "Can't generate the JD Without Title",
        });
      } else if (
        requiredSkill === undefined ||
        requiredSkill.length === 0
      ) {
        messageApi.open({
          type: 'error',
          content:
            "Can't generate the JD Without  Required Skill, Please add the Required Skill",
        });
      } else if (
        preferredSkill === undefined ||
        preferredSkill.length === 0
      ) {
        messageApi.open({
          type: 'error',
          content: 'Please add the Preferred Skill',
        });
      } else if (minimumExperience === undefined) {
        messageApi.open({
          type: 'error',
          content: 'Please add the Minimum Experience',
        });
      } else if (maximumExperience === undefined) {
        messageApi.open({
          type: 'error',
          content: 'Please add the Maximum Experience',
        });
      }
    }
  };

  const generateAIJD = async (jsonData) => {
    setProcessing(true);
    await jobService
      .generateAIJobDescription(jsonData)
      .then((oResponse) => {
        setProcessing(false);
        setJobDescription(oResponse.data.data.job_description);
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  const addSingleSearchOption = (responseData, keyName = '') => {
    var arr = [];
    if (keyName === 'locations') {
      responseData.map((oOption) => {
        arr.push({
          label: oOption.option_data,
          key: oOption.option_data,
          value: oOption.option_data,
        });
      });
    } else {
      responseData.map((oOption) => {
        arr.push({
          key: oOption.id,
          value: oOption.option_data,
        });
      });
    }
    return arr;
  };

  const singleSearchApi = async (searchValue, url, field_code) => {
    await jobService
      .getSearchValues(searchValue, url)
      .then((oResposne) => {
        var options = addSingleSearchOption(oResposne.data.data.results);
        setSingleSearchOption({
          ...singleSearchOption,
          [field_code]: options,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const multiSearchApi = async (searchValue, url, field_code) => {
    await jobService
      .getSearchValues(searchValue, url)
      .then((oResposne) => {
        var rdata = oResposne.data.data.results
          ? oResposne.data.data.results
          : oResposne.data.data;
        var options = addSingleSearchOption(rdata);
        if (rdata.length === 0) {
          setSearchvalue(searchValue);
        }
        setSingleSearchOption({
          ...singleSearchOption,
          [field_code]: options,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addItem = async (searchValue, fieldUrl, fieldCode) => {
    var payload = {
      value: searchValue,
    };
    await jobService
      .addNewMultiSearchValue(payload, fieldUrl)
      .then((oResposne) => {
        messageApi.open({
          type: 'success',
          content: oResposne.data.data.message,
        });
        multiSearchApi(searchValue, fieldUrl, fieldCode);
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: 'Sorry The Entered item cant be add.',
        });
      });
  };

  const getAutoJob = async (value) => {
    await jobService
      .getAutoFillJobData(value)
      .then((oResponse) => {
        var option = oResponse.data.data.results.map((item) => {
          return {
            id: item.id,
            value: item.title,
            label: item.title,
          };
        });

        setAutoSuggestJobList(option);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobInformationData = async (jobId) => {
    await jobService
      .getEditJob(jobId)
      .then((oResponse) => {
        setEditJobResponse(oResponse.data.data);
        setJobInfoData(oResponse.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const buildTitleField = (basicInfoFields) => {
    return (
      <Autocomplete
        className="charField capitalize"
        name={basicInfoFields.field_code}
        label={
          basicInfoFields.label +
          displayRequiredIndicator(basicInfoFields.is_mandatory)
        }
        control={control}
        placeholder={basicInfoFields.label}
        rules={{
          required: basicInfoFields.is_mandatory
            ? 'This field is required'
            : false,
        }}
        options={autoSuggestJob}
        onInputSearch={(e) => {
          getAutoJob(e);
        }}
        onSelectOption={(e, selectedOption) => {
          getJobInformationData(selectedOption.id);
          setAutoSelectedJobId(selectedOption.id);
        }}
      />
    );
  };

  const buildInputFieldLayout = (basicInfoFields, type = 'text') => {
    return (
      <div>
        <Input
          className="charField capitalize"
          name={basicInfoFields.field_code}
          label={
            basicInfoFields.label +
            displayRequiredIndicator(basicInfoFields.is_mandatory)
          }
          control={control}
          placeholder={basicInfoFields.label}
          rules={{
            required: basicInfoFields.is_mandatory
              ? 'This field is required'
              : false,
          }}
          readOnly={basicInfoFields.field_code === 'reference_no'}
          type={type}
        />
      </div>
    );
  };
  const buildCheckBoxFeildLayout = (basicInfoFields) => {
    return (
      <div className="mt-6">
        <Checkbox
          className="charField capitalize "
          control={control}
          name={basicInfoFields.field_code}
          label={
            basicInfoFields.label +
            displayRequiredIndicator(basicInfoFields.is_mandatory)
          }
          // rules={{
          //   required: basicInfoFields.is_mandatory
          //     ? 'This field is required'
          //     : false,
          // }}
          showUpperLabel={false}
        />
      </div>
    );
  };
  const dateFormat = 'YYYY-MM-DD';

  const buildDateInputFieldLayout = (basicInfoFields) => {
    return (
      <div>
        <DatePickerComponent
          label={
            basicInfoFields.label +
            displayRequiredIndicator(basicInfoFields.is_mandatory)
          }
          name={basicInfoFields.field_code}
          control={control}
          noTextInput
          rules={{
            required: 'This Field Is Required',
          }}
          format={dateFormat}
          disablePreviousDays
        />
      </div>
    );
  };

  const buildJDButton = (allFields) => {
    if (
      allFields[0].show &&
      allFields[0].field_code === 'title' &&
      allFields[1].show &&
      allFields[1].field_code === 'reference_no' &&
      allFields[2].show &&
      allFields[2].field_code === 'required_skill' &&
      allFields[3].show &&
      allFields[3].field_code === 'preferred_skill' &&
      allFields[4].show &&
      allFields[4].field_code === 'minimum_experience' &&
      allFields[5].show &&
      allFields[5].field_code === 'maximum_experience'
    ) {
      return (
        <button
          className="auto_generate_id"
          onClick={() => getAIJD()}
          type="button"
        >
          <img src={Ai_Icon} alt=""></img> <span>Auto generate jd</span>
        </button>
      );
    }
  };

  const buildDescriptionLayout = (allFields) => {
    return (
      <div className="basicinfo_description">
        <h5>
          <span style={{ float: 'left' }} className="capitalize">
            Job description *
          </span>

          {buildJDButton(allFields)}
        </h5>
        <TextEdit
          onChange={onChangeDescription}
          id="description"
          initialValue={jobDescription}
          style={{ border: '1px solid red' }}
        />
        {checkJobDescriptionError && (
          <p className="text-red-400 text-sm font-semibold text-start leading-3">
            *{'This field is required'}
          </p>
        )}
      </div>
    );
  };

  const buildMultiSearchLayout = (basicInfoFields) => {
    return (
      <MultiSearchSelect
        label={
          basicInfoFields.label +
          displayRequiredIndicator(basicInfoFields.is_mandatory)
        }
        placeholder={basicInfoFields.label}
        options={singleSearchOption[basicInfoFields.field_code]}
        name={basicInfoFields.field_code}
        control={control}
        showArrow={false}
        onSearch={(value, url, fieldId) => {
          multiSearchApi(value, url, fieldId);
        }}
        searchURL={basicInfoFields.data_url}
        showSearch
        allowClear
        onClear={() => {
          setValue(basicInfoFields.field_code, undefined);
        }}
        rules={{
          required: basicInfoFields.is_mandatory
            ? 'This field is required'
            : false,
        }}
        onSelectValue={(e) => console.log(e)}
      />
    );
  };

  const buildAddMultiSearchLayout = (basicInfoFields) => {
    return (
      <AddMultiSearch
        label={
          basicInfoFields.label +
          displayRequiredIndicator(basicInfoFields.is_mandatory)
        }
        placeholder={basicInfoFields.label}
        options={singleSearchOption[basicInfoFields.field_code]}
        name={basicInfoFields.field_code}
        control={control}
        showArrow={false}
        onSearch={(value, url, fieldId) => {
          multiSearchApi(value, url, fieldId);
        }}
        searchURL={basicInfoFields.data_url}
        showSearch
        allowClear
        onClear={() => {
          setValue(basicInfoFields.field_code, undefined);
        }}
        rules={{
          required: basicInfoFields.is_mandatory
            ? 'This field is required'
            : false,
        }}
        onSelectValue={
          (e) => console.log(e)
          //addItem(e, basicInfoFields.data_url, basicInfoFields.field_code)
        }
        sendAddedData={(one, tww, tree) => {
          addItem(one, tww, tree);
        }}
      />
    );
  };

  const buildSignleSelectSearchLayout = (basicInfoFields) => {
    return (
      <SingleSearchSelect
        label={
          basicInfoFields.label +
          displayRequiredIndicator(basicInfoFields.is_mandatory)
        }
        placeholder={basicInfoFields.label}
        options={singleSearchOption[basicInfoFields.field_code]}
        name={basicInfoFields.field_code}
        control={control}
        showArrow={false}
        onSearch={(value, url, fieldId) => {
          singleSearchApi(value, url, fieldId);
        }}
        searchURL={basicInfoFields.data_url}
        showSearch
        allowClear
        onClear={() => {
          setValue(basicInfoFields.field_code, undefined);
        }}
        rules={{
          required: basicInfoFields.is_mandatory
            ? 'This field is required'
            : false,
        }}
      />
    );
  };

  const buildSingleSelectWithOptionLayout = (basicInfoFields) => {
    return (
      <Select
        label={
          basicInfoFields.label +
          displayRequiredIndicator(basicInfoFields.is_mandatory)
        }
        placeholder={basicInfoFields.label}
        options={basicInfoFields.option_data}
        name={basicInfoFields.field_code}
        control={control}
        showArrow={true}
        rules={{
          required: basicInfoFields.is_mandatory
            ? 'This field is required'
            : false,
        }}
      />
    );
  };

  const buildBasicAndOtherInfoForm = (fields) => {
    //These fields will be replaced with specialized field
    const filteredFields = fields.filter(
      (item) =>
        !['maximum_ctc', 'minimum_ctc', 'ctc_currency_code'].includes(
          item.field_code,
        ),
    );

    const ctcFields = fields.filter((item) =>
      ['maximum_ctc', 'minimum_ctc', 'ctc_currency_code'].includes(
        item.field_code,
      ),
    );

    const isCTCInvisible = ctcFields.every((item) => !Boolean(item.show));

    if (!isCTCInvisible) {
      filteredFields.splice(
        10,
        0,
        <CTCField
          control={control}
          fields={ctcFields}
          currecyCodeResponse={currecyCodeResponse}
          setValue={setValue}
        />,
      );
    }

    return (
      <div className="basic_Info">
        <div className="basicInfo_dropdown_div">
          {filteredFields.map((basicInfoFields) => {
            if (isValidElement(basicInfoFields)) {
              return basicInfoFields;
            }
            if (
              (basicInfoFields.show === true &&
                basicInfoFields.is_mandatory === true) ||
              (basicInfoFields.show === true &&
                props.isViewMoreField === true)
            ) {
              if (basicInfoFields.field_type === 'CHAR') {
                if (basicInfoFields.field_code === 'description') {
                  return buildDescriptionLayout(fields);
                } else if (basicInfoFields.field_code === 'title') {
                  return buildTitleField(basicInfoFields);
                } else {
                  return buildInputFieldLayout(basicInfoFields);
                }
              } else if (basicInfoFields.field_type === 'INT') {
                return buildInputFieldLayout(basicInfoFields, 'number');
              } else if (basicInfoFields.field_type === 'DATE') {
                return buildDateInputFieldLayout(basicInfoFields);
              } else if (basicInfoFields.field_type === 'SINGLE_SELECT') {
                return buildSignleSelectSearchLayout(basicInfoFields);
              } else if (basicInfoFields.field_type === 'MULTI_SELECT') {
                if (basicInfoFields.field_code === 'locations') {
                  return buildMultiSearchLayout(basicInfoFields);
                } else {
                  return buildAddMultiSearchLayout(basicInfoFields);
                }
              } else if (
                basicInfoFields.field_type === 'SINGLE_SELECT_WITH_OPTION'
              ) {
                return buildSingleSelectWithOptionLayout(basicInfoFields);
              } else if (basicInfoFields.field_type === 'BOOL') {
                return buildCheckBoxFeildLayout(basicInfoFields);
              }
            }
          })}
        </div>
      </div>
    );
  };

  const handelDaysChange = (value) => {
    console.log(value,"valuevaluevalue");
    // scheduledays.push(value);
    setScheduledays((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  function handelWorkDays(value) {
    return scheduledays.includes(value)
  }

  const addMoreTime = (index) => {
    setScheduleControllerName([
      ...scheduleTimeControllerName,
      {
        work_from_time: '',
        work_end_time: '',
      },
    ]);
  };

  const removeMoreTime = (index) => {
    const removeTimes = [...scheduleTimeControllerName];
    removeTimes.splice(index, 1);
    setScheduleControllerName(removeTimes);
  };

  const handelDateChange = (e) => {
    if (e !== null) {
      var startdate = dateFormatter(e[0], 'YYYY-MM-DD');
      var endDate = dateFormatter(e[1], 'YYYY-MM-DD');

      var setDate = {
        work_from_date: startdate,
        work_end_date: endDate,
      };

      setSelectedDate(setDate);
      setSelecteddateStatus('');
    } else {
      setSelectedDate();
    }
  };

  const handelTimeChange = (e, i, startName, endName) => {
    if (e !== null) {
      const onChangevale = [...scheduleTimeControllerName];
      onChangevale[i][startName] = timeConvert(e[0]).convertedValue;
      onChangevale[i][endName] = timeConvert(e[1]).convertedValue;
      setScheduleControllerName(onChangevale);
      setSelectedTimeStatus('');
    }
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledRangeTime = (rangeTimeEvent, type) => {
    return {
      disabledHours: () => range(0, 17),
      disabledMinutes: () => range(30, 60),
    };
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const buildScheduleInfo = (fields) => {
    const days = [
      {
        value: 'SUN',
        lable: 'Sunday',
      },
      {
        value: 'MON',
        lable: 'Monday',
      },
      {
        value: 'TUE',
        lable: 'Tuesday',
      },
      {
        value: 'WED',
        lable: 'Wednesday',
      },
      {
        value: 'THU',
        lable: 'Thursday',
      },
      {
        value: 'FRI',
        lable: 'Friday',
      },
      {
        value: 'SAT',
        lable: 'Saturday',
      },
    ];

    return (
      <>
        <div className="schedule">
          <h5> Choose Your Day</h5>
          <ul>
            {days.map((oDays) => {
              return (
                <li>
                  <input
                    type="checkbox"
                    value={oDays.value}
                    // {...register("work_days")}
                    onChange={(e) => handelDaysChange(e.target.value)}
                    defaultChecked={handelWorkDays(oDays.value)}
                  />
                  <span style={{ paddingLeft: '4px' }}>{oDays.lable}</span>
                </li>
              );
            })}
          </ul>

          {/* date */}
          <div className="basicInfo_dropdown_div">
            <div style={{ width: '100%' }}>
              <h5> Select Date</h5>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                onChange={(e) => {
                  handelDateChange(e);
                }}
                status={selectedDateStatus}
                defaultValue={
                  oEditJobResponse
                    ? oEditJobResponse?.seasonal_job_schedule
                        ?.work_from_date !== null
                      ? [
                          dayjs(
                            oEditJobResponse?.seasonal_job_schedule
                              ?.work_from_date,
                            'YYYY-MM-DD',
                          ),
                          dayjs(
                            oEditJobResponse?.seasonal_job_schedule
                              ?.work_end_date,
                            'YYYY-MM-DD',
                          ),
                        ]
                      : ''
                    : ''
                }
                format={'YYYY-MM-DD'}
                disabledDate={disabledDate}
              />
              {selectedDateStatus && selectedDateStatus && (
                <p className="text-red-400 text-sm font-semibold text-start mt-2">
                  *{'This field is required'}
                </p>
              )}
            </div>
            {/* time */}
            <div>
              {scheduleTimeControllerName?.map((oTimeField, index) => {
                return (
                  <div style={{ width: '100%' }} key={index}>
                    <h5> Select Time</h5>
                    <div className="flex w-full">
                      <TimePicker.RangePicker
                        className="mr-3"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          handelTimeChange(
                            e,
                            index,
                            'work_from_time',
                            'work_end_time',
                          );
                        }}
                        format={'hh:mm A'}
                        use12Hours={true}
                        status={index === 0 ? selectedTimeStatus : ''}
                        defaultValue={
                          oTimeField?.work_from_time !== ''
                            ? [
                                dayjs(oTimeField?.work_from_time, 'hh:mm'),
                                dayjs(oTimeField?.work_end_time, 'hh:mm'),
                              ]
                            : ''
                        }
                        //disabledTime={disabledRangeTime}
                      />
                      <div>
                        {index > 0 ? (
                          <Button
                            type="primary"
                            onClick={() => removeMoreTime(index)}
                          >
                            <MinusOutlined />
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => addMoreTime(index)}
                          >
                            <PlusOutlined />
                          </Button>
                        )}
                      </div>
                    </div>
                    {index === 0
                      ? selectedTimeStatus &&
                        selectedTimeStatus && (
                          <p className="text-red-400 text-sm font-semibold text-start mt-2">
                            *{'This field is required'}
                          </p>
                        )
                      : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const buildAccordionLayout = (oFieldsData, index) => {
    if (oFieldsData.field_heading_code === 'BASIC_INFORMATION') {
      return (
        <Accordion
          bordered
          className="border border-gray-300 rounded-md mt-2 m-3  justify-center flex-col bg-[#D2EFEF] capitalize"
          showArrow={true}
          useDefaultTheme
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_sub_header">{`${oFieldsData.field_heading}`}</p>
              ),
              children: buildBasicAndOtherInfoForm(oFieldsData.fields),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    } else if (oFieldsData.field_heading_code === 'SCHEDULE') {
      return (
        <Accordion
          bordered
          className="border border-gray-300 rounded-md mt-2 m-3  justify-center flex-col bg-[#D2EFEF] capitalize"
          showArrow={true}
          useDefaultTheme
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_sub_header">{`${oFieldsData.field_heading}`}</p>
              ),
              children: buildScheduleInfo(),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    } else if (oFieldsData.field_heading_code === 'OTHER_INFORMATION') {
      return (
        <Accordion
          bordered
          className="border border-gray-300 rounded-md mt-2 m-3  justify-center flex-col bg-[#D2EFEF] capitalize"
          showArrow={true}
          useDefaultTheme
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_sub_header">{`${oFieldsData.field_heading}`}</p>
              ),
              children: buildBasicAndOtherInfoForm(oFieldsData.fields),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {messageContextHolder}
      {props.fields.map((oFieldsData, index) => {
        if (oFieldsData.show === true) {
          return buildAccordionLayout(oFieldsData, index);
        }
      })}

      <div style={{ textAlign: 'end', marginTop: '4px' }}>
        <Button
          className="cancel mr-3"
          onClick={() =>
            navigate(`${routes.JOBS}/${JOB_TAB_TYPES.ACTIVE}`)
          }
        >
          Cancel
        </Button>
        <button
          className="save"
          type="submit"
          style={{ padding: '7px' }}
          id="submit_button"
        >
          {!param.jobId ? 'Save and Next' : 'Update and Next'}
        </button>
      </div>
      {isProcessing && <Progress />}
    </form>
  );
};
export default AddJobInfoNew;
