import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  PlusOutlined,
  EditTwoTone,
  EditOutlined,
  DeleteOutlined,
  DeleteTwoTone,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import AddEvaluationForm from './AddEvaluationForm';
import settingService from '../../Services/settingsService';
import { AiFillYahoo } from 'react-icons/ai';
import evaluationParameter from '../../Store/evaluationParameterStore';
import toast from 'react-hot-toast';
import AddPrescreenForm from './AddPrescreenParameter';
import DataGridLayout, {
  useDataGridLayout,
} from 'Components/CommonComponents/DataGridLayout';
import { PERMISSION_TYPES } from 'Utilities/constants';
import WithAuthentication from 'Components/HOC/WithAuthentication';
import Button from 'Components/CommonComponents/Button/Button';
import { debounce, isEmpty } from 'lodash';

const tableColumn = [
  {
    fieldID: 'Name',
    fieldLable: 'Name',
    fieldShow: false,
  },
  {
    fieldID: 'CreatedDate',
    fieldLable: 'Created Date',
    fieldShow: true,
  },
];
var columnFields = {
  Name: 'name',
  CreatedDate: 'created',
};
const PrescreenParameter = () => {
  const navigate = useNavigate();
  //const [listView, setListView] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [parameterId, setParameterId] = useState();
  const listView = localStorage.getItem('listView');
  const [isListView, setListView] = useState('true');
  const [parameterDta, setParameterData] = useState();
  const {
    apiRef: apiRef2,
    paginationModel,
    setPaginationModel,
    handlePaginationModalChange,
    handleRowSelectionModelChange,
    rowSelectionModel,
    calculatePaginationData,
  } = useDataGridLayout();
  const { setEvaluationParameter } = evaluationParameter();
  const evaluationData = evaluationParameter(
    (state) => state.evaluationParameterResponse,
  );

  // Pagination
  const [companyListResponse, setCompanyListResponse] = useState();

  useEffect(() => {
    // setListView(listView == true ? true : false);
    // if (isListView === "true") {
    //   document.getElementById("list_view").style.backgroundColor = "#0c2556";
    //   document.getElementById("list_view").style.color = "#fff";
    // } else {
    //   document.getElementById("card_view").style.backgroundColor = "#0c2556";
    //   document.getElementById("card_view").style.color = "#fff";
    // }
    // getJobStatus();
    // getJobList("ACTIVE");
  }, []);

  const onListViewClick = () => {
    // console.log("listview");
    setListView('true');
    localStorage.setItem('listView', 'true');
    document.getElementById('card_view').style.backgroundColor = '';
    document.getElementById('card_view').style.color = '#0c2556';
    document.getElementById('list_view').style.backgroundColor = '#0c2556';
    document.getElementById('list_view').style.color = '#fff';
  };

  const onCardViewClick = () => {
    // console.log("cardView");
    setListView('false');
    localStorage.setItem('listView', 'false');
    document.getElementById('list_view').style.backgroundColor = '';
    document.getElementById('list_view').style.color = '#0c2556';
    document.getElementById('card_view').style.backgroundColor = '#0c2556';
    document.getElementById('card_view').style.color = '#fff';
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (props) => {
        return (
          <div>
            {/* <Link to="/hr/addJob"> */}
            <EditTwoTone
              className="text-lg cursor-pointer"
              title="Edit"
              onClick={() => handleEdit(props)}
            />
            {/* </Link> */}
            <DeleteTwoTone
              className="text-lg pl-3 cursor-pointer"
              onClick={() => handleDelete(props)}
              title="delete"
            />
          </div>
        );
      },
    },
  ];

  const data = [
    {
      id: 1,
      name: 'Candidate was prepared for the interview',
      description: 'Leading Software Firm',
      date: '20-03-2023',
      parameter: 'Sentence',
    },
    {
      id: 2,
      name: 'Their experience and skill sets match the requirement',
      description: 'Leading Software Firm',
      date: '20-03-2023',
      parameter: 'Options',
    },
    {
      id: 3,
      name: 'Rate their communication skills',
      description: 'Leading Software Firm',
      date: '20-03-2023',
      parameter: 'Sentence',
    },
    {
      id: 4,
      name: 'Status of the candidate',
      description: 'Leading Software Firm',
      date: '20-03-2023',
      parameter: 'Options',
    },
  ];
  const getRowdata = (arrRowData) => {
    console.log('vce', arrRowData);
    var row = [];
    arrRowData?.map((oData) => {
      return row.push({
        id: oData.id,
        Name: oData.name,
        is_common: oData.is_common,
        CreatedDate: oData.created_date,
      });
    });
    return row;
  };
  const getColumn = () => {
    var setTableColumns = [];
    tableColumn?.map((oData) => {
      setTableColumns.push({
        field: oData.fieldID,
        // headerAlign: "left",
        textAlign: 'left',
        paddingLeft: '25px',
        marginLeft: '25px',

        renderHeader: (params) => <strong>{oData.fieldLable}</strong>,
        renderCell: (params) => {
          return (
            <div
              style={{
                fontSize: 14,
                fontFamily: 'sans-serif',
                fontWeight: 'normal',
                width: '100%',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {params.value}
            </div>
          );
        },
      });
    });
    return setTableColumns;
  };
  const tableList = (arrRowData) => {
    console.log('lilsettings', arrRowData);
    var arrColumn = getColumn();
    arrColumn.push({
      field: 'action',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <strong className="flex items-center text-sm font-semibold text-black">
          Action
        </strong>
      ),
      renderCell: (params) => {
        console.log('paramas', params);
        return (
          <div
            style={{
              fontSize: 13,
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {/* {params.value} */}
            {params.row.is_common == false ? (
              <div>
                <EditTwoTone
                  className="text-lg cursor-pointer"
                  title="Edit"
                  onClick={() => handleEdit(params.row.id)}
                />
                <DeleteOutlined
                  style={{ color: '#3498db' }}
                  className="text-lg pl-3 cursor-pointer"
                  onClick={() => handleDelete(params.row.id)}
                  title="Delete"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        );
      },
    });
    var arrRow = getRowdata(arrRowData?.data?.results);

    return (
      <div className="scheduleList w-full max-h-[calc(90vh-8rem)] overflow-y-auto scrollbar-none">
        <DataGridLayout
          column={arrColumn}
          row={arrRow}
          apiRef={apiRef2}
          showCustomPagination
          paginationModel={paginationModel}
          rowSelectionModel={rowSelectionModel}
          onPaginationModelChange={(model, details) =>
            handlePaginationModalChange(model, details)
          }
          onRowSelectionModelChange={(newRowSelectionModel) => {
            handleRowSelectionModelChange(
              newRowSelectionModel,
              arrRowData?.data,
              newRowSelectionModel,
            );
          }}
          getRowClassName={(params) =>
            getCandidatesTableRowClassName(params)
          }
          // loading={getSettingsDepartmentsData()}
          results={arrRowData?.data}
          useNewPagination
          onSearchDataGrid={
            //   (value) => {
            //   console.log("onSearchDataGrid", value);
            //   getJobListSearch(
            //     value
            //   );
            // }
            debounce((value) => {
              console.log('onSearchDataGrid', value);
              getEvalutionInfo(value);
            }, 500)
          }
          onFilterModelChange={(e, type) => {
            console.log('e', e, 'type', type);
            if (e.items.length > 0) {
              var { field, value, operator } = e?.items[0];
              if (value !== undefined) {
                if (operator === 'equals') {
                  console.log(field, value, operator);
                  operator = 'exact';
                  var param = columnFields[field];

                  var finalvalue = `${param}__i${operator}=${value}`;
                  console.log('finalvalue', finalvalue);
                  getEvalutionColumnInfo(finalvalue);
                } else if (
                  operator === 'startsWith' ||
                  operator === 'endsWith'
                ) {
                  operator = operator.toLowerCase();
                  console.log('operator', operator);
                  var param = columnFields[field];

                  var finalvalue = `${param}__i${operator}=${value}`;
                  console.log('finalvalue', finalvalue);
                  getEvalutionColumnInfo(finalvalue);
                } else {
                  var params = columnFields[field];

                  var finalvalues = `${params}__i${operator}=${value}`;
                  console.log('finalvalue', finalvalue);
                  getEvalutionColumnInfo(finalvalues);
                }
              }
            } else if (e?.quickFilterValues?.length === 0) {
              getEvalutionInfo('');
            } else if (type.reason === 'deleteFilterItem') {
              getEvalutionColumnInfo('');
            }
          }}
          showQuickFilterOption={true}
          disableColumnSelectorMenu
        />
      </div>
    );
  };
  const truncateStrHandler = (str) => {
    return str.length > 35 ? str.substring(0, 28) + '...' : str;
  };
  const getEvalutionInfo = async (value) => {
    await settingService
      .getPrescreenParameter(
        paginationModel.pageSize,
        paginationModel.offset,
        value,
      )
      .then((response) => {
        setParameterData(response.data);
        setEvaluationParameter(response.data.data);
        setCompanyListResponse(response?.data?.data);
        // console.log("ev", evaluationData?.results);
      });
  };
  const getEvalutionColumnInfo = async (value) => {
    await settingService
      .getPrescreenParameterColumn(
        paginationModel.pageSize,
        paginationModel.offset,
        value,
      )
      .then((response) => {
        setParameterData(response.data);
        setEvaluationParameter(response.data.data);
        setCompanyListResponse(response?.data?.data);
        // console.log("eve", evaluationDetails?.results);
      });
  };

  const handleEdit = (id) => {
    setIsAdd(true);
    setParameterId(id);
  };
  const getCandidatesTableRowClassName = (params) => {
    if (params) {
      return params.indexRelativeToCurrentPage % 2 === 0
        ? 'Mui-even-row'
        : 'Mui-odd-row';
    }
  };
  const handelBack = () => {
    navigate('/hr/settings');
  };
  const handleAdd = () => {
    setIsAdd(true);
    setParameterId('');
  };
  useEffect(() => {
    getEvalutionInfo();
  }, [isAdd, isEdit, paginationModel]);

  const handleDelete = async (id) => {
    await settingService
      .deletePrescreenParameter(id)
      .then((res) => {
        console.log(res);
        //   toast.success("Success");
        getEvalutionInfo();
        toast.success('Successfully deleted item..!!');
        // props.close(false);
      })
      .catch((error) => {
        getEvalutionInfo();
        toast.error('Something went wrong..!!');
      });
  };

  const registeredCompanyCardView = (data) => {
    return (
      <div>
        <div className="p-4 m-3 w-36 lg:w-72 overflow-hidden primary-box-shadow rounded-2xl justify-center flex-col md:flex-row lg:flex-row cursor-pointer transition ease-in-out delay-90 hover:-translate-y-1 hover:scale-80 hover:bg-orange-200 duration-300">
          <div className="flex flex-row flex-wrap justify-center pl-2 border-b-gray-200 border-b">
            <div>
              <p className="text-xs sm:text-sm font-semibold mb-1">
                {truncateStrHandler(data.name)}
              </p>
            </div>
          </div>
          <div class="px-6 pt-4 pb-2 text-center">
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Edit"
              // onClick={() => handleEdit(data.id)}
            >
              <EditOutlined onClick={() => handleEdit(data.id)} />
            </span>
            <span
              class="inline-block border-solid border-2 border-sky-500 rounded-full px-3 py-1 text-sm font-semibold mr-3 mb-2 transition ease-in-out delay-90 hover:text-black hover:bg-slate-200 hover:-translate-y-1 hover:scale-80 duration-300"
              title="Delete"
              onClick={() => handleDelete(data.id)}
            >
              <DeleteOutlined
              // onClick={() => handleDelete(data.id)}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#F0F6F6]">
        <div
          className="flex items-center cursor-pointer"
          type="text"
          onClick={handelBack}
        >
          {!isAdd && (
            <div>
              <ArrowBackIcon />
              <span className="ml-4 text-base font-medium">Settings</span>
            </div>
          )}
        </div>
        {!isAdd && (
          <div className="flex flex-wrap items-center mb-4 w-full h-fit">
            <div className="w-1/2">
              <h3 className="text-base font-medium mt-4">
                Prescreen Parameters
              </h3>
            </div>
            <div className="w-1/2 flex flex-wrap justify-end items-center">
              <span className="text-end">
                <Button type={'primary'} onClick={() => handleAdd()}>
                  Add
                </Button>
              </span>
            </div>
          </div>
        )}

        <div className="flex flex-wrap">
          {isEdit === true || isAdd === true ? (
            <AddPrescreenForm
              close={setIsAdd}
              editClose={setIsEdit}
              parameterId={parameterId}
              title={'Edit prescreen Parameter'}
            />
          ) : isListView === 'true' ? (
            <>{parameterDta && tableList(parameterDta)}</>
          ) : (
            companyListResponse &&
            companyListResponse?.results.map((items) => {
              return registeredCompanyCardView(items);
            })
          )}
        </div>
      </div>
    </>
  );
};

export default WithAuthentication(
  PERMISSION_TYPES.VIEW_PRESCREEN_PARAMETER_TAB,
)(PrescreenParameter);
