import axios from 'axios';
import Config from './Config';
import authHeader from './authHeader';

//!---------------JobFormFieldAPIs--------------------!----->>
const getFieldData = (data) => {
  const payload = { codename: 'JOB_CREATION_COMPLETE_DATA' };
  return axios.post(
    Config.API_URL + 'api/v1/settings/job-creation-fields-to-show-or-not/',
    payload,
    {
      headers: authHeader(),
    },
  );
};
const formFiledEdit = (data) => {
  // console.log("Depart", data);
  // let payload = [
  //   {
  //     name: data,
  //   },
  // ];
  return axios.patch(
    Config.API_URL + 'api/v1/settings/job-creation-fields-to-show-or-not/',
    data,
    {
      headers: authHeader(),
    },
  );
};
//!---------------DepartmentsAPIs--------------------!----->>
const getDepartmentsData = (serachData, limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-departments/?limit=${limit}&offset=${offset}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getDepartmentsColumn = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-departments/?limit=${limit}&offset=${offset}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const addNewDepartments = (data, isCheck) => {
  console.log('Depart', data);
  let payload = [
    {
      name: data.name,
      is_active: data.is_active,
    },
  ];
  return axios.post(
    Config.API_URL + 'api/v1/settings/company-departments/',
    payload,
    {
      headers: authHeader(),
    },
  );
};
const deleteDepartment = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/settings/company-departments/${id}/`,
    {
      headers: authHeader(),
    },
  );
};

const getDepartmentById = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/settings/company-departments/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const editDepartments = (data, id) => {
  console.log('Depart', data);
  let payload = {
    name: data.name,
    is_active: data.is_active,
  };
  return axios.patch(
    `${Config.API_URL}api/v1/settings/company-departments/${id}/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};
//!---------------ProfileAndRolesAPIs--------------------!----->>
const getProfileAndRolePermissionData = (params) => {
  return axios.get(
    Config.API_URL + 'api/v1/accounts/available_permissions/',
    {
      headers: authHeader(),
      params,
    },
  );
};
const getProfileAndRolePermissionDataById = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/accounts/available_permissions/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const addNewRolesAndPermissions = (body, urlParams) => {
  return axios.post(
    Config.API_URL + 'api/v1/accounts/user_roles_and_permissions/',
    body,
    {
      urlParams,
      headers: authHeader(),
    },
  );
};
const getProfileAndRoleById = (urlParams, urlParamId) => {
  return axios.get(
    `${Config.API_URL}api/v1/accounts/user_roles_and_permissions/${urlParamId}/`,
    {
      urlParams,
      headers: authHeader(),
    },
  );
};
const editProfileAndRole = (body, urlParams, urlParamId) => {
  return axios.patch(
    `${Config.API_URL}api/v1/accounts/user_roles_and_permissions/${urlParamId}/`,
    body,
    {
      urlParams,
      headers: authHeader(),
    },
  );
};
const deleteProfileAndRole = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/accounts/user_roles_and_permissions/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
//!---------------EducationalAPIs--------------------!----->>
const getEducationalData = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-jobs-qualifications/?limit=${limit}&offset=${offset}&search=${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getEducationalColumnData = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-jobs-qualifications/?limit=${limit}&offset=${offset}&${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};

//!---------------TeamMembersAPIs--------------------!----->>
const getTeamMembersData = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub_users/?limit=${limit}&offset=${offset}&search=${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getTeamMembersColumnData = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub_users/?limit=${limit}&offset=${offset}&${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const resendsubUserInvitation = (id) => {
  return axios.post(
    Config.API_URL + `api/v1/accounts/reinvite-subusers/?user_id=${id}`,
    {},
    {
      headers: authHeader(),
    },
  );
};
const addNewTeamMembers = (data) => {
  return axios.post(
    Config.API_URL + 'api/v1/accounts/sub-user-invitation/',
    data,
    {
      headers: authHeader(),
    },
  );
};
const addNewMembers = (data) => {
  let payload = {
    application_username: data.application_username,
    first_name: data.first_name,
    last_name: data.last_name,
    mobile_number: data.mobile_number,
    // role_and_permissions: data.role_and_permissions,
    // department_id: data.department_id,
    password: data.password,
    confirm_password: data.confirm_password,
    view_all: true,
  };
  return axios.post(
    `${Config.API_URL}api/v1/accounts/sub-user-creation/${data.user}/${data.link_expiry}/`,
    payload,
    // {
    //   headers: authHeader(),
    // }
  );
};
const editTeamMembers = (data) => {
  // let payload = {
  //   application_username: data.application_username,
  //   first_name: data.first_name,
  //   last_name: data.last_name,
  //   mobile_number: data.mobile_number,
  //   email: data.email,
  //   // password: data.password,
  //   // confirm_password: data.confirm_password,
  //   role_and_permissions: data.role_and_permissions,
  //   department: selectedDepartment,: data.department_id,
  //   view_all: true,
  // };

  return axios.patch(
    `${Config.API_URL}api/v1/accounts/sub_users/${data.id}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getTeamMembersById = (id) => {
  return axios.get(`${Config.API_URL}api/v1/accounts/sub_users/${id}`, {
    headers: authHeader(),
  });
};
const deleteTeamMembers = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/accounts/sub_users/${id}/`,
    {
      headers: authHeader(),
    },
  );
};

const getProfileAndRolesData = (serachData, isBoolean) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/user_roles_and_permissions/?is_consultant_roles_only=${isBoolean}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getProfileAndRolesColumn = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/user_roles_and_permissions/?limit=${limit ? limit : ''}&offset=${offset ? offset : ''}&${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getAllProfileAndRolesData = (data) => {
  return axios.get(
    Config.API_URL + 'api/v1/accounts/user_roles_and_permissions/',
    {
      headers: authHeader(),
    },
  );
};
const addSkill = (data, isCheck) => {
  const formData = new FormData();
  {
    formData.append('skill', data.skill ? data.skill : '');
    formData.append('is_active', data.is_active);
  }
  return axios.post(
    Config.API_URL + 'api/v1/settings/company-skills/',
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getSkillData = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-skills/?limit=${limit}&offset=${offest}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getSkillColumnData = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-skills/?limit=${limit}&offset=${offest}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteSkill = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-skills/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getskillInfo = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-skills/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const editSkill = (data, id) => {
  const formData = new FormData();
  {
    formData.append('skill', data.skill ? data.skill : '');
    formData.append('is_active', data.is_active);
  }
  return axios.patch(
    Config.API_URL + `api/v1/settings/company-skills/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const telePort = (serachValue) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/locations/?search= + ${serachValue}, `,
  );
};
const addLocation = (data, city, state, country) => {
  console.log('test', data);
  return axios.post(
    Config.API_URL + `api/v1/settings/company-locations/`,
    {
      primary_address: data.primary_address,
      secondary_address: data.secondary_address,
      company_city: city,
      company_state: state,
      company_country: country,
      zipcode: data.zipcode,
    },
    {
      headers: authHeader(),
    },
  );
};
const getLocation = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-locations/?limit=${limit}&offset=${offset}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getLocationColumnData = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-locations/?limit=${limit}&offset=${offset}&${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteLocation = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-locations/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getLocationById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-locations/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const editLocation = (data, id) => {
  const selecteditems =
    data.option_data.label !== undefined
      ? data.option_data.label
      : data.option_data;
  const string = selecteditems?.split(',');
  return axios.put(
    Config.API_URL + `api/v1/settings/company-locations/${id}/`,
    {
      primary_address: data.primary_address,
      secondary_address: data.secondary_address,
      company_city: string[0],
      company_state: string[1],
      company_country: string[2],
      zipcode: data.zipcode,
    },
    {
      headers: authHeader(),
    },
  );
};
const addHiringType = (data) => {
  var formData = data;
  return axios.post(
    Config.API_URL + `api/v1/settings/company-hiring-types/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getHiringType = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-hiring-types/?limit=${limit}&offset=${offset}&search=${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getHiringColumnType = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-hiring-types/?limit=${limit}&offset=${offset}&${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteHiringType = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-hiring-types/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getHiringTypeById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-hiring-types/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const editHiringType = (data, id) => {
  return axios.patch(
    Config.API_URL + `api/v1/settings/company-hiring-types/${id}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const addEvaluationParameter = (data) => {
  let payload = {
    question: data.question,
    answer_type: data.answer_type,
    is_vertical: data.is_vertical,
    is_mandatory: data.is_mandatory,
    multi_options_answers: data.optionData,
  };
  console.log('payloadpayload', payload);
  return axios.post(
    Config.API_URL + `api/v1/interview/evaluation-questions/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationParameter = (limit, offest, id, serachData) => {
  return axios.get(
    // return axios.get(Config.API_URL + "api/v1/jobs/job-hiring-stages/?job=" + nId, {
    Config.API_URL +
      `api/v1/interview/evaluation-questions/?limit=${limit}&offset=${offest}&questionair=${id ? id : ''}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationParameterColumn = (limit, offest, serachData) => {
  return axios.get(
    // return axios.get(Config.API_URL + "api/v1/jobs/job-hiring-stages/?job=" + nId, {
    Config.API_URL +
      `api/v1/interview/evaluation-questions/?limit=${limit}&offset=${offest}&${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationQustionsById = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-questions/?questionair=${id ? id : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getParameteById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/interview/evaluation-questions/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const editParameter = (id, data) => {
  let payload = {
    question: data.question,
    answer_type: data.answer_type,
    is_vertical: data.is_vertical,
    is_mandatory: data.is_mandatory,
    multi_options_answers: data.optionData,
  };
  return axios.put(
    Config.API_URL + `api/v1/interview/evaluation-questions/${id}/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};
const deleteEvaluationParameter = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/interview/evaluation-questions/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const addCertification = (data) => {
  const formData = new FormData();
  {
    formData.append(
      'certification',
      data.certification ? data.certification : '',
    );
    formData.append('code', data.code ? data.code : '');
    formData.append(
      'description',
      data.description ? data.description : '',
    );
    formData.append(
      'certificate_logo',
      data.certificate_logo && data.certificate_logo[0]
        ? data.certificate_logo[0]
        : '',
    );
  }
  return axios.post(
    Config.API_URL + `api/v1/settings/company-certificates/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const deleteCertification = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-certificates/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getCertificatesData = (limit, offset, serchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-certificates/?limit=${limit}&offset=${offset}&search=${serchData ? serchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getCertificatesColumnData = (limit, offset, serchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/settings/company-certificates/?limit=${limit}&offset=${offset}&${serchData ? serchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getCertificationById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-certificates/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const editCertification = (
  data,
  id,
  certificationName,
  certificationCode,
  description,
) => {
  const formData = new FormData();
  {
    formData.append(
      'certification',
      data.certification ? data.certification : '',
    );
    formData.append('code', data.code ? data.code : '');
    formData.append(
      'description',
      data.description ? data.description : '',
    );
    formData.append(
      'certificate_logo',
      data.certificate_logo && data.certificate_logo[0]
        ? data.certificate_logo[0]
        : '',
    );
  }
  return axios.patch(
    Config.API_URL + `api/v1/settings/company-certificates/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const addQualification = (data) => {
  var formData = [
    {
      qualification: data.qualification,
      description: data.description,
      code: data.code,
      alternative_names: [],
    },
  ];

  return axios.post(
    Config.API_URL + `api/v1/settings/company-jobs-qualifications/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getQualificationById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/settings/company-jobs-qualifications/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const deleteQualification = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/settings/company-jobs-qualifications/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const editQualification = (data, id, qualification, code, description) => {
  var formData = {
    qualification: data.qualification,
    description: data.description,
    code: data.code,
    alternative_names: [],
  };
  return axios.patch(
    Config.API_URL + `api/v1/settings/company-jobs-qualifications/${id}/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const addEvaluationQuestionaire = (data, selectedQuestions) => {
  return axios.post(
    Config.API_URL + `api/v1/interview/evaluation-question-naire/`,
    {
      title: data.title,
      questions: selectedQuestions,
    },
    {
      headers: authHeader(),
    },
  );
};
const getQuestionnaireLabels = () => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire-sections-labels`,
    {
      headers: authHeader(),
    },
  );
};
const addLabels = (data) => {
  var formData = data;
  return axios.post(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire-sections-labels/`,
    formData,
    {
      headers: authHeader(),
    },
  );
};
const editLabels = (data, id) => {
  return axios.patch(
    Config.API_URL + `api/v1/settings/company-jobs-qualifications/${id}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getQuestionnaireLabelsById = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire-sections-labels/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationQuestionaire = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire/?limit=${limit}&offset=${offset}&search=${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationColumnQuestionaire = (limit, offset, searchData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire/?limit=${limit}&offset=${offset}&${searchData ? searchData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteEvaluationQuestionaire = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/interview/evaluation-question-naire/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getEvaluationById = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/interview/evaluation-question-naire/${id}/?edit=true`,
    {
      headers: authHeader(),
    },
  );
};
const editEvaluationQuestionaire = (id, data, selectedQuestions) => {
  return axios.put(
    Config.API_URL + `api/v1/interview/evaluation-question-naire/${id}/`,
    {
      title: data.title,
      questions: selectedQuestions,
    },
    {
      headers: authHeader(),
    },
  );
};
const addPrescreenParameter = (data) => {
  let payload = {
    name: data.name,
    field_type: data.field_type,
    is_mandatory_field: data.is_mandatory_field,
    multi_options: data.optionData,
  };
  return axios.post(
    Config.API_URL + `api/v1/jobs/prescreen-fields/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};
const getPrescreenParameter = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/prescreen-fields/?limit=${limit}&offset=${offset}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getPrescreenParameterColumn = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/jobs/prescreen-fields/?limit=${limit}&offset=${offset}&${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deletePrescreenParameter = (id) => {
  return axios.delete(
    Config.API_URL + `api/v1/jobs/prescreen-fields/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const getPrescreenById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/jobs/prescreen-fields/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const editPrescreenParameter = (id, data) => {
  let payload = {
    name: data.name,
    field_type: data.field_type,
    is_mandatory_field: data.is_mandatory_field,
    multi_options: data.optionData,
    // field_type:
    //   data.field_type.value !== undefined
    //     ? data.field_type.value
    //     : data.field_type,
  };
  return axios.put(
    Config.API_URL + `api/v1/jobs/prescreen-fields/${id}/`,
    payload,
    {
      headers: authHeader(),
    },
  );
};

const getEvents = (params) => {
  return axios.get(Config.API_URL + `api/v1/interview/user-calendar/`, {
    headers: authHeader(),
    params,
  });
};
const getConsultancyData = (serachData, limit, offest) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/clients/?is_consultant_or_client=CONSULTANT&search=${serachData ? serachData : ''}&limit=${limit ? limit : ''}&offset=${offest ? offest : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteConsultancy = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/company/clients/${id}/?is_consultant_or_client=CONSULTANT`,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyColumnData = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/clients/?limit=${limit}&offset=${offest}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyById = (id) => {
  return axios.get(
    `${Config.API_URL}api/v1/company/clients/${id}/?is_consultant_or_client=CONSULTANT`,
    {
      headers: authHeader(),
    },
  );
};
const addConsultancy = (data) => {
  return axios.post(Config.API_URL + 'api/v1/company/clients/', data, {
    headers: authHeader(),
  });
};
const editConsultancy = (data, id) => {
  return axios.put(
    `${Config.API_URL}api/v1/company/clients/${id}/?is_consultant_or_client=CONSULTANT`,
    {
      client: data?.client,
      primary_email: data.primary_email,
      address: data?.address,
      city: data?.city,
      primary_contact_person: data?.primary_contact_person,
      primary_contact_mobile: data?.primary_contact_mobile,
      website: data?.website,
      is_consultant_or_client: 'CONSULTANT',
      employee_identification_number: data.employee_identification_number,
    },
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyUser = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub_users/?is_consultant_or_client=CONSULTANT&limit=${limit}&offset=${offest}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteConsultancyUser = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/accounts/sub_users/${id}/?is_consultant_or_client=CONSULTANT`,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyColumnUser = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub-user-invitation/?is_consultant_or_client=CONSULTANT&limit=${limit}&offset=${offest}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyUserById = (id) => {
  return axios.get(`${Config.API_URL}api/v1/accounts/sub_users/${id}`, {
    headers: authHeader(),
  });
};
const addConsultancyUser = (data) => {
  return axios.post(
    Config.API_URL + 'api/v1/accounts/sub-user-invitation/',
    {
      email: data.email,
      associated_clients: [data.associated_clients.value],
      department: data.department.value,
      is_consultant_or_client: 'CONSULTANT',
      role_and_permissions: data.role_and_permissions.value,
      view_all: data.view_all,
      employee_identification_number: data.employee_identification_number,
    },
    {
      headers: authHeader(),
    },
  );
};
const editConsultancyUser = (data, id) => {
  return axios.put(
    `${Config.API_URL}api/v1/accounts/sub_users/${id}/?is_consultant_or_client=CONSULTANT`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyClient = (serachData, limit, offest) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/clients/?is_consultant_or_client=CLIENT&search=${serachData ? serachData : ''}&limit=${limit ? limit : ''}&offset=${offest ? offest : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteConsultancyClient = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/company/clients/${id}/?is_consultant_or_client=CLIENT`,
    {
      headers: authHeader(),
    },
  );
};
const getConsultancyClientColumnData = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/clients/?limit=${limit}&offset=${offest}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const addConsultancyClient = (data) => {
  return axios.post(Config.API_URL + 'api/v1/company/clients/', data, {
    headers: authHeader(),
  });
};
const getConsultancyClientById = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/clients/${id}/?is_consultant_or_client=CLIENT`,
    {
      headers: authHeader(),
    },
  );
};
const editConsultancyClient = (data, id) => {
  return axios.put(
    `${Config.API_URL}api/v1/company/clients/${id}/?is_consultant_or_client=CLIENT`,
    {
      client: data?.client,
      primary_email: data.primary_email,
      address: data?.address,
      city: data?.city,
      primary_contact_person: data?.primary_contact_person,
      primary_contact_mobile: data?.primary_contact_mobile,
      website: data?.website,
      is_consultant_or_client: 'CLIENT',
    },
    {
      headers: authHeader(),
    },
  );
};
const getClientUser = (serachData, limit, offest) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub_users/?is_consultant_or_client=CLIENT&limit=${limit}&offset=${offest}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteClientUser = (id) => {
  return axios.delete(
    Config.API_URL +
      `api/v1/accounts/sub_users/${id}/?is_consultant_or_client=CLIENT`,
    {
      headers: authHeader(),
    },
  );
};
const getClientColumnUser = (limit, offest, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/accounts/sub-user-invitation/?is_consultant_or_client=CLIENT&limit=${limit}&offset=${offest}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const getClientUserById = (id) => {
  return axios.get(`${Config.API_URL}api/v1/accounts/sub_users/${id}`, {
    headers: authHeader(),
  });
};
const addClientUser = (data) => {
  return axios.post(
    Config.API_URL +
      'api/v1/accounts/sub-user-invitation/?is_consultant_or_client=CLIENT',
    {
      email: data.email,
      associated_clients: [data.associated_clients],
      department: data.department,
      is_consultant_or_client: 'CLIENT',
      role_and_permissions: data.role_and_permissions,
      view_all: data.view_all,
    },
    {
      headers: authHeader(),
    },
  );
};
const editClientUser = (data, id) => {
  return axios.patch(
    `${Config.API_URL}api/v1/accounts/sub_users/${id}/?is_consultant_or_client=CLIENT`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getDocumentTypes = (serachData, limit, offset) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/company-documents-types/?limit=${limit}&offset=${offset}&search=${serachData ? serachData : ''}`,
    {
      headers: authHeader(),
    },
  );
};
const getDocumentColumn = (limit, offset, serachData) => {
  return axios.get(
    Config.API_URL +
      `api/v1/company/company-documents-types/?limit=${limit}&offset=${offset}&${serachData}`,
    {
      headers: authHeader(),
    },
  );
};
const getDocuemntType = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/company/company-documents-types/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const addDocument = (data) => {
  return axios.post(
    Config.API_URL + 'api/v1/company/company-documents-types/',
    {
      document_name: data.document_name,
    },
    {
      headers: authHeader(),
    },
  );
};
const editDocument = (data, id) => {
  return axios.patch(
    Config.API_URL + `api/v1/company/company-documents-types/${id}/`,
    {
      document_name: data.document_name,
    },
    {
      headers: authHeader(),
    },
  );
};
const deleteDocument = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/company/company-documents-types/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const addGroup = (data) => {
  return axios.post(
    Config.API_URL + 'api/v1/communications/group-communications/',
    {
      name: data.name,
    },
    {
      headers: authHeader(),
    },
  );
};
const getGroup = () => {
  return axios.get(
    Config.API_URL + 'api/v1/communications/group-communications/',
    {
      headers: authHeader(),
    },
  );
};
const getGroupById = (id) => {
  return axios.get(
    Config.API_URL + `api/v1/communications/group-communications/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const updateGroup = (data, id) => {
  return axios.put(
    Config.API_URL + `api/v1/communications/group-communications/${id}/`,
    {
      name: data.name,
    },
    {
      headers: authHeader(),
    },
    {
      headers: authHeader(),
    },
  );
};
const deleteGroup = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/communications/group-communications/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const addContact = (data, groupId) => {
  return axios.post(
    Config.API_URL + 'api/v1/communications/group-contact-communications/',

    {
      name: data.name,
      email: data.email,
      phone: data.phone,
      group: [groupId],
    },
    {
      headers: authHeader(),
    },
  );
};
const updateContact = (data, id) => {
  return axios.patch(
    Config.API_URL +
      `api/v1/communications/group-contact-communications/${id}/`,
    data,
    {
      headers: authHeader(),
    },
  );
};
const getContact = () => {
  return axios.get(
    Config.API_URL + 'api/v1/communications/group-contact-communications/',
    {
      headers: authHeader(),
    },
  );
};
const getContactById = (id) => {
  return axios.get(
    Config.API_URL +
      `api/v1/communications/group-contact-communications/${id}`,
    {
      headers: authHeader(),
    },
  );
};
const deleteContact = (id) => {
  return axios.delete(
    `${Config.API_URL}api/v1/communications/group-contact-communications/${id}/`,
    {
      headers: authHeader(),
    },
  );
};
const contactExcelUpload = (formData) => {
  return axios.post(
    Config.API_URL + 'api/v1/communications/contact-group-excel-upload/',
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getSampelContactFile = (formData) => {
  return axios.get(
    Config.API_URL + 'api/v1/settings/sample-contact-excel-upload/',
    {
      headers: authHeader(),
    },
  );
};
const groupExcelUpload = (formData) => {
  return axios.post(
    Config.API_URL + 'api/v1/communications/contact-group-excel-upload/',
    formData,
    {
      headers: authHeader(),
    },
  );
};
const getSampelGroupFile = (formData) => {
  return axios.get(
    Config.API_URL + 'api/v1/settings/sample-contact-excel-upload/',
    {
      headers: authHeader(),
    },
  );
};

const createAvatarSetting = (formData) => {
  return axios.post(
    Config.API_URL + 'api/v1/settings/company-avatar-settings/',
    formData,
    {
      headers: authHeader(),
    },
  );
};
const settingService = {
  getFieldData,
  getDepartmentsData,
  getDepartmentsColumn,
  addNewDepartments,
  getEducationalData,
  getEducationalColumnData,
  getTeamMembersData,
  getTeamMembersColumnData,
  getProfileAndRolesData,
  formFiledEdit,
  addNewTeamMembers,
  deleteDepartment,
  getDepartmentById,
  editDepartments,
  getProfileAndRolePermissionData,
  getProfileAndRolesColumn,
  addNewRolesAndPermissions,
  getProfileAndRoleById,
  deleteProfileAndRole,
  deleteTeamMembers,
  getProfileAndRolePermissionDataById,
  getTeamMembersById,
  addNewMembers,
  editTeamMembers,
  editProfileAndRole,
  addSkill,
  getSkillData,
  getSkillColumnData,
  deleteSkill,
  getskillInfo,
  editSkill,
  telePort,
  addLocation,
  getLocation,
  getLocationColumnData,
  deleteLocation,
  getLocationById,
  editLocation,
  addHiringType,
  getHiringType,
  getHiringColumnType,
  deleteHiringType,
  editHiringType,
  getHiringTypeById,
  addEvaluationParameter,
  getEvaluationParameter,
  getEvaluationParameterColumn,
  deleteEvaluationParameter,
  getParameteById,
  editParameter,
  addCertification,
  deleteCertification,
  getCertificatesData,
  getCertificatesColumnData,
  getCertificationById,
  editCertification,
  addQualification,
  getQualificationById,
  deleteQualification,
  editQualification,
  addEvaluationQuestionaire,
  getEvaluationQuestionaire,
  getEvaluationColumnQuestionaire,
  deleteEvaluationQuestionaire,
  getEvaluationById,
  editEvaluationQuestionaire,
  addPrescreenParameter,
  getPrescreenParameter,
  getPrescreenParameterColumn,
  deletePrescreenParameter,
  getPrescreenById,
  editPrescreenParameter,
  getQuestionnaireLabels,
  addLabels,
  getEvaluationQustionsById,
  resendsubUserInvitation,
  getQuestionnaireLabelsById,
  editLabels,
  getEvents,
  getConsultancyData,
  getConsultancyColumnData,
  getConsultancyById,
  addConsultancy,
  editConsultancy,
  getConsultancyUser,
  getConsultancyColumnUser,
  addConsultancyUser,
  editConsultancyUser,
  getConsultancyUserById,
  getConsultancyClient,
  getConsultancyClientColumnData,
  addConsultancyClient,
  editConsultancyClient,
  getClientUser,
  getClientColumnUser,
  getClientUserById,
  addClientUser,
  editClientUser,
  deleteConsultancy,
  deleteConsultancyUser,
  deleteConsultancyClient,
  deleteClientUser,
  getConsultancyClientById,
  getDocumentTypes,
  getDocumentColumn,
  getDocuemntType,
  addDocument,
  editDocument,
  deleteDocument,
  addGroup,
  getGroup,
  getGroupById,
  updateGroup,
  deleteGroup,
  addContact,
  updateContact,
  getContact,
  getContactById,
  deleteContact,
  contactExcelUpload,
  getSampelContactFile,
  groupExcelUpload,
  getSampelGroupFile,
  createAvatarSetting,
};

export default settingService;
