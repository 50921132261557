import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { BiArrowBack } from 'react-icons/bi';

import { useJobFields } from 'ServiceHooks/jobHooks';
import style from '../../../Styles/addJob.module.scss';
import Accordion from 'Components/CommonComponents/Accordion/Accordion';
import HiringTeamNew from 'Components/Jobs/HiringTeamNew';
import HiringStageNew from 'Components/Jobs/HiringStageNew';
import Progress from 'Components/CommonComponents/Progress';
import Button from 'Components/CommonComponents/Button/Button';
import viewOption_Icon from '../../../Assets/images/vewMoreOption.svg';
import AddJobInfoNew from 'Components/Jobs/AddJobInfoNew';
import Publish from 'Components/Jobs/Publish';
import routes from 'Routes';
import { JOB_TAB_TYPES } from 'Utilities/constants';

const AddJobNew = () => {
  const [isEditJobprocessing, setEditJobProcessing] = useState(true);
  const [isViewMoreField, setViewMoreFields] = useState(false);
  const [isActiveAccordian, setActiveAccordion] = useState([1]);

  const param = useParams();
  const navigate = useNavigate();

  const {
    data: jobFields,
    isError: isJobFieldError,
    isSuccess: isJobSucess,
    isInitialLoading: isJobFieldLoading,
  } = useJobFields({
    enabled: true,
  });

  const AddMoreField = () => {
    setViewMoreFields(true);
  };

  const activeSelectedAccordian = (index) => {
    setActiveAccordion([index]);
  };

  const buildAccordion = (allFields, index) => {
    if (allFields.codename === 'JOB_CREATION_INITIAL') {
      return (
        <Accordion
          bordered
          className="rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
          showArrow={true}
          useDefaultTheme
          ghost
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_header">{`${index + 1}. ${
                  allFields.label
                }`}</p>
              ),
              children: (
                <AddJobInfoNew
                  process={setEditJobProcessing}
                  fields={allFields.field_data}
                  index={index}
                  isViewMoreField={isViewMoreField}
                  setAccordionActive={activeSelectedAccordian}
                  currentActive={index + 1}
                />
              ),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    } else if (allFields.codename === 'JOB_HIRING_TEAM') {
      return (
        <Accordion
          bordered
          className="rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
          showArrow={true}
          useDefaultTheme
          ghost
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_header">{`${index + 1}. ${
                  allFields.label
                }`}</p>
              ),
              children: (
                <HiringTeamNew
                  fields={allFields.field_data}
                  index={index}
                  isViewMoreField={isViewMoreField}
                  process={setEditJobProcessing}
                  setAccordionActive={activeSelectedAccordian}
                  currentActive={index + 1}
                />
              ),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    } else if (allFields.codename === 'JOB_HIRING_STAGES') {
      return (
        <Accordion
          bordered
          className="border border-gray-300 rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
          showArrow={true}
          useDefaultTheme
          ghost
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_header">{`${index + 1}. ${
                  allFields.label
                }`}</p>
              ),
              children: (
                <HiringStageNew
                  fields={allFields.field_data}
                  index={index}
                  isViewMoreField={isViewMoreField}
                  process={setEditJobProcessing}
                  setAccordionActive={activeSelectedAccordian}
                  currentActive={index + 1}
                />
              ),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    } else if (allFields.codename === 'JOB_PUBLISH') {
      return (
        <Accordion
          bordered
          className="border border-gray-300 rounded-md mt-2 m-3 bg-transparent justify-center flex-col bg-white capitalize"
          showArrow={true}
          useDefaultTheme
          ghost
          items={[
            {
              key: index + 1,
              label: (
                <p className="jobs_header">{`${index + 1}. ${
                  allFields.label
                }`}</p>
              ),
              children: (
                <Publish
                  fields={allFields.field_data}
                  isViewMoreField={isViewMoreField}
                  process={setEditJobProcessing}
                />
              ),
            },
          ]}
          onChange={() => {
            setActiveAccordion([index + 1]);
          }}
          activeKey={isActiveAccordian}
        />
      );
    }
  };

  return (
    <div className={`${style.addJobMainDiv} scrollbar-hide`}>
      <div className="flex items-center justify-between pt-4 pb-2 px-4">
        <Button
          className="flex items-center cursor-pointer"
          type="ghost"
          onClick={() => {
            let route;
            if (!Boolean(param.jobId)) {
              route = `${routes.JOBS}/${JOB_TAB_TYPES.ACTIVE}`;
            } else {
              route = `${routes.JOB_DETAILS}/${param.jobId}`;
            }
            navigate(route);
          }}
        >
          <BiArrowBack />
          <span className="ml-4 font-bold">
            {!param.jobId ? 'Post a New Job' : 'Edit Job'}
          </span>
        </Button>
        <button className="addField " onClick={() => AddMoreField()}>
          <img src={viewOption_Icon} alt=""></img>
          <span style={{ paddingLeft: '3px' }}>View Optional Info</span>
        </button>
      </div>
      <div className="stepper_accordion_div scrollbar-hide">
        {!isEmpty(jobFields) ? (
          jobFields.map((oJobFields, index) => {
            if (oJobFields.show) {
              return buildAccordion(oJobFields, index);
            }
          })
        ) : (
          <div className="flex justify-center items-center py-5">
            <p>Job Fields Loading!...</p>
          </div>
        )}
      </div>

      {isEditJobprocessing && <Progress />}
    </div>
  );
};
export default AddJobNew;
