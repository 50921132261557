import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Input from '../ReactHooKForm/Input/Input';
import { positiveIntegerValidation } from 'Utilities/utils';

const CountryCodeInput = ({
  name = '',
  value = '',
  setValue,
  required = false,
  control,
  placeholder = 'Phone Number',
  label = '',
  rules = {},
  country_code_name = '',
  country_code_value = '91',
}) => {
  return (
    // <div>
    //     {label &&
    //      <p className='mb-4'>
    //         {label} {required &&<span className="text-errorMain">*</span>}
    //     </p>
    //     }
    //     <PhoneInput
    //         country={'us'}
    //         control
    //         value={value}
    //         onChange={phone => setValue(name, "+" + phone)}
    //         placeholder={placeholder}
    //         inputProps={{
    //             name,
    //             required,
    //             autoFocus,
    //         }}
    //         inputClass="full_width"
    //         disableCountryCode={true}
    //     />
    // </div>
    <div className="">
      {label && (
        <p className="mb-4">
          {label} {required && <span className="text-errorMain">*</span>}
        </p>
      )}
      <div className="flex items-start gap-2 w-full phone-coutrycode">
        <div className="w-[6%]">
          <PhoneInput
            country="In"
            value={country_code_value}
            inputStyle={{
              width: '2%',
              height: '2.25rem',
              paddingLeft: '0px',
            }}
            className="custom-countrycode"
            onChange={(text) => setValue(country_code_name, `${text}`)}
          />
        </div>
        <div className="grow ml-6">
          <Input
            className="w-full"
            name={name}
            control={control}
            placeholder={placeholder}
            rules={rules}
          />
        </div>
      </div>
    </div>
  );
};

export default CountryCodeInput;
