import { useEffect, useMemo } from 'react';
import { Space } from 'antd';
import { isEmpty } from 'lodash';

import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import { displayRequiredIndicator } from 'Utilities/componentSpecificUtilities';

const { Compact } = Space;

export default function CTCField({
  fields,
  control,
  currecyCodeResponse,
  setValue,
}) {
  const currencyCodeField = fields?.find(
    (item) => item.field_code === 'ctc_currency_code',
  );
  console.log('logtest', currencyCodeField);
  const minimumCtcField = fields?.find(
    (item) => item.field_code === 'minimum_ctc',
  );

  const maximumCtcField = fields?.find(
    (item) => item.field_code === 'maximum_ctc',
  );

  const currencyCodeOptions = useMemo(() => {
    if (isEmpty(currencyCodeField)) return [];
    const fields = currencyCodeField?.option_data?.map(
      ({ key, value }) => ({
        label: value,
        value: key,
      }),
    );

    return fields;
  }, [fields]);
  useEffect(() => {
    setValue(currencyCodeField?.field_code, currecyCodeResponse);
  }, [currecyCodeResponse]);

  const isHiddenInputs = !minimumCtcField.show && !maximumCtcField.show;

  if (isHiddenInputs) {
    return (
      <div className={currencyCodeField?.show ? '' : 'hidden'}>
        <Select
          allowClear
          showArrow={true}
          control={control}
          options={currencyCodeOptions}
          label={
            <p className="mr-4">
              {currencyCodeField?.label}
              {currencyCodeField?.is_mandatory ? ' *' : ''}
            </p>
          }
          name={currencyCodeField?.field_code}
          rules={{
            required:
              currencyCodeField?.show && currencyCodeField?.is_mandatory
                ? 'This field is required'
                : false,
          }}
        />
      </div>
    );
  }

  return (
    <Compact>
      <div className={currencyCodeField?.show ? 'w-1/2' : 'hidden w-1/2'}>
        <Select
          control={control}
          options={currencyCodeOptions}
          label={
            <p className="mr-4">
              Code
              {currencyCodeField?.is_mandatory ? '*' : ''}
            </p>
          }
          name={currencyCodeField?.field_code}
          rules={{
            required:
              currencyCodeField?.show && currencyCodeField?.is_mandatory
                ? 'Required'
                : false,
          }}
        />
      </div>
      <div className={minimumCtcField?.show ? '' : 'hidden'}>
        <Input
          type={'number'}
          control={control}
          name={minimumCtcField?.field_code}
          rules={{
            required:
              minimumCtcField?.show && minimumCtcField?.is_mandatory
                ? 'This field is required'
                : false,
          }}
          label={
            minimumCtcField?.label +
            displayRequiredIndicator(minimumCtcField?.is_mandatory)
          }
          placeholder={
            minimumCtcField?.place_holder ?? minimumCtcField?.label
          }
        />
      </div>
      <div className={maximumCtcField?.show ? '' : 'hidden'}>
        <Input
          type={'number'}
          control={control}
          name={maximumCtcField?.field_code}
          placeholder={
            maximumCtcField?.place_holder ?? maximumCtcField?.label
          }
          rules={{
            required:
              maximumCtcField?.show && maximumCtcField?.is_mandatory
                ? 'This field is required'
                : false,
          }}
          label={
            maximumCtcField?.label +
            displayRequiredIndicator(maximumCtcField?.is_mandatory)
          }
        />
      </div>
    </Compact>
  );
}
