import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ReadyState } from 'react-use-websocket';
import { Spin, message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import 'Styles/commonStyles.scss';
import { usePersistedStore } from 'Store/persistedStore';
import { useLayoutStore } from 'Store/LayoutStore';
import QuestionBanksService from 'Services/questionBanksService';
import {
  CANDIDATE_TAB_CONSTANTS,
  CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES,
  DEFAULT_ERROR_MESSAGE,
  JOB_DETAILS_TAB_KEYS,
} from 'Utilities/constants';
import { FETCH_JOB_DETAILS_CANDIDATES_LIST } from 'ServiceHooks/jobHooks';
import { fullCandidatesListQueryName } from 'ServiceHooks/candidatesHooks';
import routes from 'Routes';
import Empty from 'Components/CommonComponents/Empty/Empty';

const NOTE_TYPES = {
  CREATED_QUESTIONS: 'is_question_bank',
  EXTRACTED_CANDIDATES: 'is_candidate_import',
};

const NOTE_ACTIONS = Object.values(NOTE_TYPES);

const READ_DOT_COLOR = 'bg-[#A9A9A9BA]';
const UNREAD_DOT_COLOR = 'bg-[#FFB800]';

const STATUSES = {
  complete: {
    label: 'Completed',
    color: 'text-[#00C17C]',
  },
  progress: {
    label: 'In Progress',
    color: 'text-[#05BBD3]',
  },
  pending: {
    label: 'Pending',
    color: 'text-[#E48C08]',
  },
};

const Dot = ({ color }) => (
  <div className={`w-2 h-2 rounded-full ${color}`}></div>
);

const NotificationScreen = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    notifications,
    markAllAsRead,
    markNoteAsRead,
    connectionState,
    clearNotifications,
  } = useLayoutStore((state) => ({
    notifications: state.notifications,
    markAllAsRead: state.markAllAsRead,
    markNoteAsRead: state.markNoteAsRead,
    connectionState: state.connectionState,
    clearNotifications: state.clearNotifications,
  }));

  const { setCreatedQuestions, setCurrentQuestionnaireFormState } =
    usePersistedStore((state) => ({
      setCreatedQuestions: state.setCreatedQuestions,
      setCurrentQuestionnaireFormState:
        state.setCurrentQuestionnaireFormState,
    }));

  const fetchQuestion = async (payload) => {
    try {
      let response = await QuestionBanksService.fetchGeneratedQuestions({
        job: payload?.job_id,
        interview_level: payload?.interview_level,
      });
      const questions = response?.data?.data;
      setCreatedQuestions(questions ?? []);
      setCurrentQuestionnaireFormState(
        CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES.SHOW_CREATED_QUESTIONS,
      );
      await navigate(routes.VIEW_QUESTIONNAIRE);
    } catch (error) {
      await message.open({
        type: 'error',
        content: DEFAULT_ERROR_MESSAGE,
      });
      console.error('Fetching error', error);
    }
  };

  const redirectToCandidatePage = (payload) => {
    const { job_id } = payload;
    if (isEmpty(job_id)) {
      queryClient.refetchQueries({
        exact: false,
        queryKey: [fullCandidatesListQueryName],
      });
      navigate(
        `${routes.CANDIDATES}/${CANDIDATE_TAB_CONSTANTS.FINALIZED}`,
      );
    } else {
      queryClient.refetchQueries({
        exact: false,
        queryKey: [FETCH_JOB_DETAILS_CANDIDATES_LIST],
      });
      //TODO: replace state with a url.
      navigate(
        `${routes.JOB_DETAILS}/${job_id}/${JOB_DETAILS_TAB_KEYS.CANDIDATES}/type/${CANDIDATE_TAB_CONSTANTS.FINALIZED}`,
      );
    }
  };

  const actionReducer = (type, payload) => {
    switch (type) {
      case NOTE_TYPES.CREATED_QUESTIONS:
        fetchQuestion(payload);
        break;

      case NOTE_TYPES.EXTRACTED_CANDIDATES:
        redirectToCandidatePage(payload);
        break;

      default:
        console.warn('Default');
        break;
    }
  };
  const noteCount = notifications.length;
  const scrollable =
    noteCount > 4 ? 'max-h-[29rem] overflow-y-auto customScrollBar' : '';

  const socketType = {
    [ReadyState.UNINSTANTIATED]: <Empty description={'None Right Now'} />,
    [ReadyState.CONNECTING]: (
      <Spin
        className="h-28 flex justify-center items-center"
        size="large"
      />
    ),
    [ReadyState.OPEN]: <Empty description={'None Right Now'} />,
    [ReadyState.CLOSING]: (
      <Empty isError description={'Reconnecting. Please Wait.'} />
    ),
    [ReadyState.CLOSED]: (
      <Empty isError description={'Reconnecting. Please Wait.'} />
    ),
  };

  const onNoteClick = async (item, index) => {
    if (!item.read) {
      markNoteAsRead(index);
    }
    const action = Object?.entries(item)
      ?.filter(
        ([type, payload]) =>
          NOTE_ACTIONS.includes(type) && !isEmpty(payload),
      )
      ?.pop();

    await actionReducer(action[0], action[1]);
  };

  return (
    <>
      <div className="bg-[#FFF]">
        <div className="px-6 pt-4 pb-3 flex justify-between border-b border-[#E6E6E6] font-medium text-sm">
          <h5 className="text-[#656565]">Notifications</h5>
          <p className="text-primaryMain">
            {!isEmpty(notifications) && `Notification Count: ${noteCount}`}
          </p>
        </div>
        <div className={`w-80 ${scrollable}`}>
          {!isEmpty(notifications)
            ? notifications.map((item, index) => {
                const statusColor = STATUSES[item?.status]?.color;
                return (
                  <button
                    key={`notification-item-${index}`}
                    onClick={() => onNoteClick(item, index)}
                    className="p-2 border-b border-[#E6E6E6]"
                  >
                    <div className="flex flex-row items-baseline gap-2">
                      <Dot
                        color={
                          item?.read ? READ_DOT_COLOR : UNREAD_DOT_COLOR
                        }
                      />
                      <h5 className="max-w-[16rem] font-medium text-left">
                        {item?.message}
                      </h5>
                    </div>
                    {item?.date && (
                      <div className="flex justify-between px-4 text-sm">
                        <p className="text-[#656565] font-normal">
                          Created: {item?.date}
                        </p>
                        <p className={`${statusColor} font-medium`}>
                          {STATUSES[item?.status]?.label}
                        </p>
                      </div>
                    )}
                    {item?.content && (
                      <p className="mt-1 px-4 text-sm font-normal text-[#656565]">
                        {item.content}
                      </p>
                    )}
                    {/* TODO: connect when the API support arrives */}
                    {/* <p className="mt-1 px-4 text-xs font-normal text-[#848484]">{item?.receivedDate}</p> */}
                  </button>
                );
              })
            : socketType[connectionState]}
        </div>
        {!isEmpty(notifications) && (
          <div className="px-6 pt-4 pb-3 flex justify-between border-t border-[#E6E6E6] font-normal text-xs">
            <button
              className="text-primaryMain"
              onClick={() => markAllAsRead()}
            >
              Mark all as read
            </button>
            <button
              onClick={() => clearNotifications()}
              className="text-errorMain"
            >
              Clear All
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationScreen;
